import React from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'
import { colors } from '../constants'
import { breakpointUp, breakpointDown, breakpoints } from '../helpers/breakpoints'
import { propBetween } from '../helpers/between'

interface IProps {
  id?: string
  children?: any
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => any
  ariaLabel?: string
  visible?: boolean
}

export const FooterButton = ({ id, children, disabled, loading, onClick, ariaLabel, visible }: IProps) => {
  const aria = ariaLabel ? ariaLabel : typeof children === 'string' ? children : undefined

  if (!visible) {
    return null
  } else {
    return (
      <Root id={id} onClick={onClick} aria-label={aria} $loading={loading} disabled={disabled}>
        <Loader $loading={loading}>
          <Spinner size={26} color={colors.white} width={2} visible />
        </Loader>
        <Text tabIndex={-1} $loading={loading}>
          {children}
        </Text>
      </Root>
    )
  }
}

const Root = styled.button<{ disabled?: boolean; $loading?: boolean }>`
  position: relative;

  padding: 0;
  margin: 0;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  transition: background-color, box-shadow 0.3s ease-out;
  ${(props) =>
    props.disabled &&
    `pointer-events: none; 
    box-shadow: 0 0.875rem 1.5rem -0.75rem rgba(44, 124, 76, 0);`}

  color: ${colors.white};
  background-color: ${(props) => (props.disabled ? colors.gray : props.theme.primaryColor)};
  border: none;
  border-radius: 0;

  ${propBetween('font-size', 1.25, 1.6875, breakpoints.tablet / 16)};
  ${breakpointDown('tablet')} {
    ${propBetween('font-size', 1.125, 1.25, breakpoints.phone / 16, breakpoints.tablet / 16)}
  }
  font-weight: 900;
  line-height: 1.3;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${(props) => props.theme.secondaryColor};
  }

  &:hover {
    /* "Undo" the default hover state */
    ${(props) => (props.disabled || props.$loading) && 'background-position: right bottom;'}
    ${(props) => props.$loading && 'cursor: default;'}
  }

  ${breakpointUp('phone')} {
    width: 18.625rem;
  }

  ${breakpointDown('phone')} {
    height: 4rem;
    width: 100%;
  }
`

const Loader = styled.span<{ $loading?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.$loading ? '1' : '0')};
  pointer-events: none;
  transform: ${(props) => (props.$loading ? 'scale(1)' : 'scale(0.8)')};
  transition-duration: 0.2s;
  transition-delay: 0.1s;
`

const Text = styled.span<{ $loading?: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 2rem 2.5rem;

  text-align: center;
  transition: all 0.3s;

  color: ${colors.white};

  line-height: 1.5;

  ${(props) =>
    props.$loading &&
    `opacity: 0;
    pointer-events: none;`}

  &:focus {
    outline: none;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    opacity: 0;
    box-shadow: inset 0 0 0 0.25rem ${(props) => props.theme.primaryColor};
    transition: opacity 0.3s ease;
  }

  ${Root}:focus > & {
    background: ${colors.white};
    color: ${(props) => props.theme.primaryColor};
    transition: all 0.3s ease;
  }

  ${Root}:focus > &::after {
    opacity: 1;
  }

  ${breakpointDown('phone')} {
    padding: 1.3125rem 2.5rem;
  }
`
