import React from 'react'

export const ToyotaLogo = () => {
  return (
    <svg viewBox="0 0 226 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M161.974 20.9798L162.786 20.8485L164.363 26.0629C164.389 26.1398 164.419 26.2151 164.453 26.2882C164.463 26.3079 164.479 26.3242 164.498 26.3352C164.517 26.3462 164.538 26.3513 164.56 26.35C164.583 26.3534 164.607 26.3493 164.628 26.3382C164.649 26.327 164.665 26.3095 164.675 26.2882C164.692 26.2471 164.719 26.1716 164.757 26.0629L166.301 20.8485L166.987 20.9886L165.448 26.1285C165.388 26.3649 165.276 26.5849 165.12 26.7723C165.046 26.8495 164.956 26.9097 164.857 26.9488C164.758 26.9878 164.651 27.0049 164.545 26.9987C164.422 27.0006 164.299 26.9881 164.179 26.9615C164.08 26.9379 163.988 26.8931 163.908 26.8302C163.822 26.7586 163.752 26.669 163.704 26.5675C163.635 26.4264 163.578 26.2795 163.535 26.1285L161.974 20.9798Z"
        fill="black"
      />
      <path
        d="M168.662 21.7031C168.726 21.4706 168.833 21.2522 168.978 21.0592C169.046 20.9814 169.132 20.9205 169.228 20.8812C169.324 20.8419 169.428 20.8254 169.531 20.8329C169.655 20.8309 169.779 20.8421 169.901 20.8662C169.996 20.8864 170.084 20.9317 170.156 20.9975C170.234 21.0742 170.298 21.1645 170.344 21.2641C170.411 21.4065 170.468 21.5532 170.516 21.7031L172.124 26.8518L171.34 26.9831L170.683 24.9467H168.367L167.743 26.9831L167.077 26.8439L168.662 21.7031ZM170.518 24.3392L169.742 21.7697C169.721 21.6899 169.688 21.6137 169.644 21.5433C169.631 21.524 169.613 21.5082 169.592 21.4974C169.571 21.4867 169.548 21.4812 169.525 21.4816C169.5 21.4792 169.475 21.4837 169.452 21.4946C169.43 21.5055 169.411 21.5224 169.397 21.5433C169.361 21.6156 169.332 21.6915 169.311 21.7697L168.534 24.3382L170.518 24.3392ZM170.202 18.9494L170.753 19.4394L169.389 20.581L169.061 20.287L170.202 18.9494Z"
        fill="black"
      />
      <path d="M173.729 21.5385H172.045V20.9309H176.225V21.5385H174.533V26.8508H173.729V21.5385Z" fill="black" />
      <path
        d="M177.224 20.931H178.94C179.2 20.9296 179.46 20.9542 179.716 21.0045C179.944 21.0477 180.16 21.1357 180.353 21.2632C180.537 21.3913 180.684 21.5657 180.78 21.7688C180.892 22.0244 180.945 22.3021 180.935 22.5812C180.938 22.7823 180.902 22.9819 180.829 23.1692C180.761 23.3421 180.664 23.5019 180.541 23.6415C180.307 23.9094 179.994 24.0963 179.647 24.1756C179.743 24.2372 179.829 24.3132 179.901 24.401C179.984 24.4973 180.058 24.6002 180.123 24.7087L181.379 26.7989L180.541 26.9714L179.3 24.8861C179.243 24.7897 179.18 24.6976 179.109 24.6107C179.054 24.5399 178.985 24.48 178.908 24.4343C178.83 24.3878 178.744 24.3547 178.656 24.3363C178.541 24.3157 178.426 24.3062 178.31 24.3079H178.028V26.845H177.224V20.931ZM178.833 23.6984C179.225 23.6984 179.539 23.6134 179.774 23.4436C180.009 23.2737 180.128 22.9974 180.131 22.6145C180.138 22.4222 180.104 22.2306 180.033 22.052C179.976 21.9175 179.882 21.8019 179.761 21.7188C179.636 21.6382 179.494 21.5851 179.347 21.563C179.174 21.5347 179 21.521 178.825 21.5219H178.028V23.6984H178.833Z"
        fill="black"
      />
      <path
        d="M183.432 24.5597C183.372 24.5308 183.318 24.492 183.271 24.445C183.224 24.3971 183.183 24.3434 183.149 24.2853C183.107 24.2149 183.069 24.1423 183.034 24.0678C182.997 23.9854 182.954 23.8924 182.905 23.7885L181.643 21.0044L182.398 20.8564L183.679 23.7228C183.724 23.8208 183.756 23.8933 183.777 23.9404C183.785 23.9603 183.798 23.9775 183.816 23.9899C183.833 24.0023 183.854 24.0093 183.875 24.0099C183.896 24.0098 183.917 24.0035 183.934 23.9919C183.952 23.9802 183.965 23.9636 183.973 23.9443C183.998 23.9002 184.038 23.8267 184.093 23.7228L185.406 20.8731L186.072 21.0289L184.772 23.7885C184.678 23.9688 184.592 24.1246 184.515 24.2559C184.448 24.3743 184.354 24.475 184.241 24.5499V26.8489H183.432V24.5597Z"
        fill="black"
      />
      <path
        d="M190.293 24.3872H189.086V23.761H191.057V26.4206C190.907 26.5147 190.752 26.5998 190.592 26.6754C190.443 26.7463 190.29 26.8066 190.132 26.8557C189.973 26.9063 189.809 26.9421 189.642 26.9625C189.463 26.9855 189.282 26.9966 189.1 26.9958C188.688 27.0083 188.277 26.9311 187.897 26.7694C187.585 26.6285 187.315 26.4094 187.113 26.1334C186.904 25.8398 186.759 25.5061 186.685 25.1535C186.597 24.7407 186.554 24.3194 186.558 23.8972C186.555 23.482 186.605 23.068 186.706 22.6654C186.794 22.311 186.952 21.9777 187.17 21.6854C187.384 21.4061 187.663 21.1834 187.983 21.0367C188.362 20.871 188.773 20.791 189.187 20.8025C189.493 20.8 189.799 20.8486 190.089 20.9465C190.329 21.0231 190.559 21.1263 190.775 21.2542L190.487 21.7883C190.301 21.6728 190.101 21.5804 189.892 21.5129C189.665 21.4419 189.428 21.4072 189.191 21.41C188.964 21.4097 188.74 21.4458 188.525 21.5169C188.307 21.5916 188.112 21.7236 187.962 21.899C187.779 22.1214 187.645 22.3803 187.57 22.6585C187.461 23.0624 187.411 23.48 187.422 23.8982C187.422 24.7135 187.558 25.3279 187.829 25.7415C188.1 26.155 188.534 26.3618 189.131 26.3618C189.537 26.3681 189.938 26.2721 190.297 26.0825L190.293 24.3872Z"
        fill="black"
      />
      <path
        d="M195.858 24.3872H194.65V23.761H196.621V26.4206C196.472 26.5147 196.316 26.5998 196.157 26.6754C196.008 26.7462 195.854 26.8064 195.697 26.8557C195.537 26.9063 195.373 26.9421 195.207 26.9625C195.027 26.9855 194.846 26.9966 194.665 26.9958C194.252 27.0083 193.841 26.9311 193.461 26.7694C193.149 26.6285 192.879 26.4094 192.677 26.1334C192.469 25.8398 192.323 25.5061 192.25 25.1535C192.161 24.7407 192.118 24.3194 192.122 23.8972C192.119 23.4819 192.169 23.068 192.27 22.6654C192.358 22.311 192.516 21.9777 192.735 21.6854C192.949 21.4061 193.228 21.1834 193.547 21.0367C193.927 20.871 194.338 20.791 194.751 20.8025C195.058 20.8 195.363 20.8486 195.654 20.9465C195.893 21.0231 196.123 21.1263 196.34 21.2542L196.052 21.7883C195.865 21.6728 195.665 21.5804 195.457 21.5129C195.23 21.4419 194.993 21.4072 194.755 21.41C194.529 21.4097 194.304 21.4458 194.089 21.5169C193.871 21.5916 193.677 21.7236 193.527 21.899C193.344 22.1214 193.21 22.3803 193.135 22.6585C193.026 23.0624 192.976 23.48 192.987 23.8982C192.987 24.7135 193.122 25.3279 193.394 25.7415C193.665 26.155 194.099 26.3618 194.695 26.3618C195.101 26.3681 195.502 26.2721 195.862 26.0825L195.858 24.3872Z"
        fill="black"
      />
      <path d="M199.076 21.5385H197.393V20.9309H201.573V21.5385H199.881V26.8508H199.076V21.5385Z" fill="black" />
      <path
        d="M205.067 21.703C205.132 21.4707 205.239 21.2523 205.383 21.0592C205.452 20.9813 205.538 20.9203 205.634 20.881C205.73 20.8418 205.834 20.8253 205.938 20.8328C206.061 20.8309 206.185 20.8421 206.306 20.8662C206.401 20.8861 206.489 20.9315 206.561 20.9975C206.64 21.0739 206.703 21.1643 206.749 21.264C206.816 21.4065 206.874 21.5531 206.922 21.703L208.529 26.8517L207.745 26.983L207.088 24.9467H204.772L204.148 26.983L203.482 26.8439L205.067 21.703ZM206.93 24.3391L206.152 21.7697C206.132 21.6896 206.099 21.6132 206.054 21.5433C206.041 21.5239 206.023 21.5081 206.002 21.4973C205.981 21.4865 205.958 21.4811 205.935 21.4816C205.91 21.4793 205.885 21.4838 205.863 21.4947C205.841 21.5056 205.822 21.5224 205.808 21.5433C205.771 21.6152 205.742 21.6911 205.722 21.7697L204.945 24.3391H206.93Z"
        fill="black"
      />
      <path
        d="M209.489 20.9309H213.103V21.5551H210.294V23.4514H212.413V24.0756H210.294V26.8508H209.489V20.9309Z"
        fill="black"
      />
      <path d="M216.873 21.5385H215.188V20.9309H219.369V21.5385H217.678V26.8508H216.873V21.5385Z" fill="black" />
      <path
        d="M220.481 20.9309H221.302L222.69 23.9854C222.716 24.0501 222.749 24.1118 222.788 24.1697C222.802 24.1852 222.819 24.1974 222.838 24.2052C222.857 24.213 222.878 24.2163 222.899 24.2147C222.918 24.2145 222.937 24.2096 222.954 24.2004C222.971 24.1912 222.986 24.1779 222.997 24.1618C223.033 24.1049 223.065 24.046 223.095 23.9854L224.597 20.9309H225.369L225.673 26.8508H224.889L224.676 22.3675L223.707 24.3216C223.657 24.4202 223.609 24.5094 223.563 24.5891C223.52 24.6637 223.467 24.7325 223.406 24.7939C223.347 24.8531 223.275 24.8991 223.197 24.9291C223.096 24.9655 222.99 24.9824 222.884 24.9791C222.774 24.9819 222.664 24.9667 222.558 24.934C222.478 24.9065 222.404 24.8632 222.341 24.8066C222.277 24.7476 222.224 24.678 222.184 24.6008C222.141 24.5185 222.094 24.4235 222.045 24.3137L221.136 22.3352L220.956 26.8508H220.184L220.481 20.9309Z"
        fill="black"
      />
      <path d="M4.39785 1.7835H0V0.253784H10.7325V1.7835H6.27187V14.033H4.39785V1.7835Z" fill="#CF142B" />
      <path
        d="M14.3795 12.2024C13.8583 11.5181 13.4751 10.7389 13.2514 9.90836C13.0067 9.02439 12.8844 8.11107 12.8878 7.19388C12.8843 6.27541 13.013 5.36122 13.27 4.47941C13.5076 3.64654 13.9037 2.8673 14.4364 2.18435C14.9756 1.50885 15.6627 0.966071 16.4447 0.5978C17.2601 0.205818 18.2288 0.00982666 19.3508 0.00982666C20.4982 0.00982666 21.4829 0.205818 22.3049 0.5978C23.0888 0.962936 23.7766 1.50624 24.3132 2.18435C24.8392 2.86746 25.2258 3.64725 25.4511 4.47941C25.6954 5.36343 25.8174 6.27675 25.8138 7.19388C25.8173 8.11236 25.6886 9.02657 25.4315 9.90836C25.1941 10.741 24.798 11.52 24.2651 12.2024C23.7267 12.8785 23.0399 13.4217 22.2578 13.79C21.4417 14.182 20.4727 14.3779 19.3508 14.3779C18.204 14.3779 17.2193 14.182 16.3966 13.79C15.6106 13.4237 14.9202 12.8803 14.3795 12.2024ZM17.3817 12.3935C17.9974 12.6829 18.6694 12.8329 19.3498 12.8329C20.0301 12.8329 20.7021 12.6829 21.3179 12.3935C21.8691 12.1084 22.3469 11.6996 22.7136 11.199C23.0997 10.6602 23.3816 10.054 23.5447 9.41153C23.9146 7.95622 23.9146 6.43155 23.5447 4.97624C23.3816 4.33381 23.0997 3.72757 22.7136 3.1888C22.3466 2.68816 21.869 2.27901 21.3179 1.99326C20.7016 1.70414 20.0291 1.55426 19.3483 1.55426C18.6675 1.55426 17.9951 1.70414 17.3787 1.99326C16.8274 2.27862 16.3496 2.68784 15.983 3.1888C15.5961 3.72724 15.3138 4.33357 15.1509 4.97624C14.7811 6.43155 14.7811 7.95622 15.1509 9.41153C15.3138 10.0542 15.5961 10.6605 15.983 11.199C16.3501 11.7004 16.829 12.1094 17.3817 12.3935Z"
        fill="#CF142B"
      />
      <path
        d="M32.9464 9.23904C32.6785 9.17377 32.4234 9.06413 32.1917 8.91468C31.9734 8.76742 31.7796 8.58688 31.6173 8.37962C31.4303 8.14046 31.2662 7.88421 31.1272 7.61428C30.9743 7.32094 30.8087 6.98319 30.6303 6.601L27.8379 0.770259L29.5041 0.0842896L32.4916 6.39129C32.6023 6.64174 32.7301 6.88435 32.8738 7.11744C32.9672 7.27084 33.0832 7.40927 33.2179 7.52804C33.3174 7.61579 33.4348 7.68098 33.5619 7.71913C33.6868 7.75258 33.8158 7.76874 33.9451 7.76715C34.0803 7.76949 34.2152 7.75333 34.346 7.71913C34.4733 7.6808 34.591 7.61564 34.691 7.52804C34.8286 7.40453 34.9448 7.25918 35.035 7.09784C35.1497 6.90675 35.283 6.65882 35.4359 6.35209L38.4194 0.100949L40.0857 0.770259L37.3638 6.31779C37.1724 6.70128 36.9907 7.05505 36.8189 7.37909C36.6617 7.68069 36.4794 7.96851 36.2739 8.23949C36.0949 8.47621 35.8822 8.68552 35.6427 8.86078C35.4009 9.03226 35.1284 9.15569 34.84 9.22435V14.0418H32.9464V9.23904Z"
        fill="#CF142B"
      />
      <path
        d="M43.5592 12.2024C43.0376 11.5183 42.6544 10.7391 42.4311 9.90836C42.1859 9.0245 42.0635 8.11111 42.0675 7.19388C42.064 6.27541 42.1927 5.36122 42.4497 4.47941C42.6873 3.64654 43.0834 2.8673 43.6161 2.18435C44.1556 1.50873 44.843 0.965944 45.6254 0.5978C46.4408 0.205818 47.4095 0.00982666 48.5315 0.00982666C49.6795 0.00982666 50.6642 0.205818 51.4856 0.5978C52.2695 0.962936 52.9573 1.50624 53.4939 2.18435C54.0195 2.86768 54.4061 3.6474 54.6318 4.47941C54.8756 5.36353 54.9976 6.27678 54.9945 7.19388C54.998 8.11236 54.8693 9.02657 54.6122 9.90836C54.3748 10.741 53.9787 11.52 53.4458 12.2024C52.907 12.8786 52.2199 13.4217 51.4375 13.79C50.6221 14.182 49.6534 14.3779 48.5315 14.3779C47.3834 14.3779 46.3987 14.182 45.5773 13.79C44.7909 13.4239 44.1002 12.8805 43.5592 12.2024ZM46.5614 12.3935C47.1774 12.6833 47.8497 12.8335 48.5305 12.8335C49.2112 12.8335 49.8836 12.6833 50.4996 12.3935C51.0519 12.1088 51.5306 11.6999 51.8982 11.199C52.2843 10.6602 52.5662 10.054 52.7294 9.41153C53.0992 7.95622 53.0992 6.43155 52.7294 4.97624C52.5662 4.33381 52.2843 3.72757 51.8982 3.1888C51.5311 2.68792 51.0531 2.27874 50.5015 1.99326C49.8854 1.70416 49.2131 1.55428 48.5324 1.55428C47.8518 1.55428 47.1795 1.70416 46.5633 1.99326C46.0116 2.2785 45.5336 2.68773 45.1667 3.1888C44.7805 3.72757 44.4986 4.33381 44.3355 4.97624C43.9657 6.43155 43.9657 7.95622 44.3355 9.41153C44.4986 10.054 44.7805 10.6602 45.1667 11.199C45.5327 11.6998 46.0102 12.1087 46.5614 12.3935Z"
        fill="#CF142B"
      />
      <path d="M61.5707 1.7835H57.1729V0.253784H67.9004V1.7835H63.4447V14.033H61.5707V1.7835Z" fill="#CF142B" />
      <path
        d="M72.2787 2.41461C72.3813 2.07117 72.5145 1.73763 72.6767 1.41799C72.8249 1.13233 73.0242 0.876251 73.2648 0.662447C73.5104 0.445867 73.7961 0.279417 74.1057 0.172468C74.873 -0.0574895 75.6908 -0.0574895 76.458 0.172468C77.0794 0.379195 77.5944 0.822009 77.892 1.40525C78.0583 1.72318 78.1958 2.05542 78.3027 2.39795L81.8596 13.8115L80.062 14.2319L78.5918 9.60553H71.8926L70.439 14.2319L68.6807 13.8115L72.2787 2.41461ZM78.1115 8.03466L76.4453 2.72036C76.3048 2.2748 76.1457 1.972 75.968 1.81194C75.7705 1.64627 75.5178 1.56091 75.2603 1.57283C74.9988 1.55967 74.7416 1.6442 74.5389 1.80998C74.3605 1.96938 74.2014 2.27219 74.0616 2.7184L72.3787 8.0327L78.1115 8.03466Z"
        fill="#CF142B"
      />
      <path d="M93.7729 1.7835H89.375V0.253784H100.102V1.7835H95.6469V14.033H93.7729V1.7835Z" fill="black" />
      <path
        d="M103.372 0.25379H108.306C108.912 0.250229 109.515 0.320989 110.104 0.464482C110.634 0.591111 111.133 0.821601 111.574 1.14261C112.005 1.46858 112.349 1.89522 112.577 2.3852C112.826 2.90131 112.951 3.53566 112.951 4.28827C112.955 4.77201 112.854 5.25091 112.657 5.69255C112.465 6.12411 112.203 6.52133 111.883 6.86849C111.569 7.20859 111.206 7.49874 110.804 7.7289C110.422 7.95103 110.005 8.10614 109.57 8.18751C109.84 8.35488 110.093 8.54714 110.326 8.76177C110.592 9.00897 110.845 9.27069 111.082 9.54574L114.218 13.2157L112.899 14.3436L109.334 10.2376C109.016 9.88088 108.742 9.59081 108.512 9.36738C108.318 9.17292 108.103 8.99979 107.872 8.85095C107.692 8.73726 107.492 8.65662 107.284 8.61282C107.066 8.57267 106.845 8.5533 106.624 8.555H105.252V14.0428H103.378L103.372 0.25379ZM107.881 7.04097C108.288 7.0429 108.693 6.98815 109.085 6.87829C109.449 6.77922 109.79 6.61048 110.089 6.38146C110.394 6.14709 110.638 5.84375 110.803 5.49638C110.968 5.14901 111.047 4.76753 111.036 4.38333C111.036 3.83586 110.959 3.38965 110.806 3.04471C110.669 2.72115 110.444 2.44291 110.156 2.24212C109.852 2.0421 109.51 1.90851 109.152 1.85014C108.726 1.77729 108.294 1.7422 107.861 1.74528H105.241V7.03705L107.881 7.04097Z"
        fill="black"
      />
      <path
        d="M120.773 9.23904C120.243 9.11581 119.773 8.8119 119.443 8.37962C119.256 8.14069 119.092 7.88441 118.953 7.61428C118.8 7.32094 118.635 6.98319 118.456 6.601L115.665 0.770259L117.331 0.0842896L120.311 6.39129C120.422 6.64178 120.55 6.8844 120.694 7.11744C120.787 7.27084 120.903 7.40927 121.038 7.52804C121.138 7.61579 121.255 7.68098 121.382 7.71913C121.507 7.75258 121.635 7.76874 121.764 7.76715C121.9 7.76948 122.035 7.75333 122.166 7.71913C122.293 7.68098 122.411 7.61579 122.51 7.52804C122.649 7.4061 122.767 7.26207 122.859 7.10176C122.974 6.91067 123.108 6.66274 123.261 6.35601L126.244 0.104869L127.91 0.774178L125.189 6.31779C124.998 6.70128 124.816 7.05505 124.644 7.37909C124.487 7.6809 124.305 7.96875 124.099 8.23949C123.92 8.47643 123.708 8.68578 123.468 8.86078C123.226 9.03226 122.954 9.15569 122.665 9.22435V14.0418H120.773V9.23904Z"
        fill="black"
      />
      <path
        d="M136.318 6.71563H142.036V12.9099C141.711 13.145 141.361 13.3438 140.993 13.5028C140.569 13.6892 140.131 13.8425 139.684 13.9614C139.2 14.0914 138.709 14.1935 138.213 14.2672C137.701 14.3438 137.183 14.3821 136.665 14.3818C135.402 14.3818 134.338 14.2002 133.47 13.837C132.661 13.515 131.942 12.9999 131.377 12.3367C130.833 11.6691 130.444 10.8888 130.239 10.0524C130.003 9.11142 129.887 8.14431 129.895 7.17425C129.885 6.20416 130.034 5.239 130.335 4.3167C130.609 3.47579 131.055 2.70096 131.644 2.04124C132.243 1.38523 132.982 0.872438 133.806 0.540926C134.666 0.184222 135.67 0.00554323 136.818 0.00488993C137.685 -0.00844204 138.552 0.081697 139.398 0.273398C140.062 0.431717 140.714 0.636041 141.349 0.88489L140.796 2.33817C140.387 2.18431 140.012 2.0579 139.677 1.955C139.359 1.85822 139.037 1.77841 138.711 1.71589C138.405 1.65906 138.103 1.6179 137.803 1.59242C137.509 1.56694 137.194 1.5542 136.875 1.5542C135.256 1.5542 134.02 2.04418 133.166 3.02414C132.312 4.00409 131.885 5.38681 131.885 7.17229C131.885 9.0721 132.293 10.4901 133.109 11.4263C133.925 12.3625 135.111 12.8309 136.666 12.8315C137.365 12.8442 138.062 12.7637 138.74 12.5924C139.231 12.4665 139.709 12.2905 140.165 12.0672V8.26396H136.321L136.318 6.71563Z"
        fill="black"
      />
      <path
        d="M151.998 6.71564H157.716V12.9099C157.391 13.1452 157.042 13.3441 156.674 13.5028C156.25 13.689 155.812 13.8424 155.364 13.9614C154.88 14.0913 154.39 14.1934 153.894 14.2672C153.381 14.3438 152.864 14.3822 152.345 14.3818C151.084 14.3818 150.02 14.2002 149.152 13.837C148.342 13.5155 147.623 13.0004 147.059 12.3367C146.514 11.6693 146.125 10.889 145.921 10.0524C145.685 9.11143 145.569 8.14433 145.577 7.17426C145.567 6.20413 145.715 5.2389 146.017 4.31671C146.291 3.4758 146.737 2.70097 147.326 2.04125C147.923 1.38597 148.66 0.873237 149.482 0.540936C150.343 0.184231 151.347 0.00555229 152.494 0.00489899C153.363 -0.00848299 154.229 0.0816562 155.076 0.273408C155.74 0.43187 156.391 0.636191 157.027 0.8849L156.472 2.33818C156.064 2.18432 155.688 2.05791 155.353 1.95501C155.036 1.85835 154.714 1.77853 154.388 1.7159C154.081 1.65907 153.778 1.61791 153.479 1.59243C153.181 1.56695 152.872 1.55421 152.552 1.55421C150.933 1.55421 149.696 2.04419 148.842 3.02415C147.988 4.0041 147.561 5.38747 147.559 7.17426C147.559 9.07407 147.967 10.4921 148.783 11.4283C149.598 12.3644 150.784 12.8329 152.34 12.8335C153.039 12.8461 153.736 12.7657 154.414 12.5944C154.906 12.4686 155.383 12.2926 155.839 12.0691V8.26397H151.996L151.998 6.71564Z"
        fill="black"
      />
      <path d="M161.979 0.253784H163.854V14.0378H161.979V0.253784Z" fill="black" />
      <path
        d="M168.328 0.253784H170.396L177.299 11.9153C177.363 12.0132 177.417 12.1034 177.462 12.173C177.48 12.2052 177.506 12.232 177.538 12.2505C177.57 12.2689 177.606 12.2784 177.643 12.2778C177.686 12.2826 177.729 12.2718 177.764 12.2472C177.799 12.2227 177.824 12.1862 177.834 12.1446C177.865 12 177.878 11.8522 177.873 11.7046V0.253784H179.67V11.6869C179.681 12.0938 179.629 12.4999 179.517 12.8913C179.435 13.1722 179.295 13.4327 179.106 13.6557C178.946 13.8386 178.744 13.9798 178.518 14.0672C178.3 14.1484 178.07 14.1902 177.838 14.1907C177.582 14.1964 177.326 14.1576 177.083 14.0761C176.874 14.0001 176.682 13.8832 176.519 13.7321C176.342 13.5662 176.185 13.38 176.051 13.1774C175.904 12.9612 175.747 12.7191 175.582 12.4513L170.151 3.23971V14.0378H168.328V0.253784Z"
        fill="black"
      />
      <path
        d="M189.993 6.71564H195.71V12.9099C195.385 13.1452 195.036 13.3441 194.668 13.5028C194.243 13.6892 193.805 13.8425 193.357 13.9614C192.874 14.0914 192.383 14.1935 191.887 14.2672C191.375 14.3438 190.857 14.3822 190.339 14.3818C189.076 14.3818 188.012 14.2002 187.145 13.837C186.335 13.5155 185.616 13.0004 185.052 12.3367C184.507 11.6693 184.118 10.889 183.914 10.0524C183.678 9.11143 183.562 8.14433 183.57 7.17426C183.56 6.20422 183.708 5.23907 184.009 4.31671C184.284 3.47574 184.73 2.70092 185.319 2.04125C185.916 1.38574 186.653 0.872971 187.476 0.540936C188.336 0.184231 189.34 0.00555229 190.488 0.00489899C191.356 -0.00848299 192.222 0.0816562 193.069 0.273408C193.733 0.43187 194.384 0.636191 195.02 0.8849L194.465 2.33818C194.057 2.18432 193.681 2.05791 193.347 1.95501C193.029 1.85835 192.707 1.77853 192.381 1.7159C192.074 1.65907 191.771 1.61791 191.473 1.59243C191.174 1.56695 190.865 1.55421 190.545 1.55421C188.926 1.55421 187.69 2.04419 186.836 3.02415C185.982 4.0041 185.555 5.38682 185.555 7.1723C185.555 9.07211 185.962 10.4901 186.778 11.4263C187.593 12.3625 188.779 12.8309 190.335 12.8316C191.034 12.8442 191.732 12.7638 192.41 12.5924C192.901 12.4665 193.378 12.2905 193.834 12.0672V8.26397H189.991L189.993 6.71564Z"
        fill="black"
      />
      <path
        d="M202.383 2.4146C202.487 2.07174 202.621 1.73885 202.784 1.41995C202.933 1.13454 203.132 0.87852 203.372 0.6644C203.618 0.447406 203.903 0.280906 204.213 0.174422C204.98 -0.0555364 205.798 -0.0555364 206.565 0.174422C207.186 0.381894 207.701 0.824492 207.999 1.40721C208.165 1.72282 208.302 2.05237 208.41 2.39206L211.967 13.8056L210.169 14.226L208.699 9.59964H202.007L200.553 14.226L198.789 13.8056L202.383 2.4146ZM208.215 8.03465L206.549 2.72035C206.409 2.27479 206.249 1.97199 206.07 1.81193C205.873 1.64617 205.62 1.56081 205.363 1.57282C205.099 1.55878 204.84 1.64411 204.637 1.81193C204.458 1.97134 204.299 2.27414 204.158 2.72035L202.475 8.03465H208.215Z"
        fill="black"
      />
      <path
        d="M215.157 0.253798H220.089C220.695 0.250128 221.299 0.320889 221.887 0.464489C222.417 0.591438 222.916 0.8219 223.357 1.14262C223.788 1.46859 224.133 1.89523 224.361 2.3852C224.609 2.90131 224.733 3.53567 224.733 4.28828C224.736 4.77197 224.636 5.25077 224.439 5.69255C224.247 6.12412 223.985 6.52134 223.665 6.8685C223.351 7.20853 222.988 7.49868 222.587 7.7289C222.205 7.95102 221.788 8.10613 221.354 8.18752C221.623 8.35513 221.876 8.54738 222.109 8.76178C222.375 9.00926 222.627 9.27096 222.864 9.54574L226 13.2157L224.681 14.3436L221.123 10.2376C220.805 9.88089 220.531 9.59082 220.301 9.36739C220.107 9.17267 219.892 8.99953 219.66 8.85095C219.48 8.73717 219.281 8.65653 219.072 8.61282C218.854 8.57267 218.633 8.55331 218.412 8.555H217.04V14.0428H215.166L215.157 0.253798ZM219.666 7.04097C220.073 7.04297 220.478 6.98822 220.87 6.8783C221.234 6.77943 221.576 6.61068 221.875 6.38146C222.18 6.14721 222.425 5.84387 222.589 5.49645C222.753 5.14903 222.833 4.7675 222.821 4.38333C222.821 3.83586 222.744 3.38966 222.592 3.04471C222.455 2.72111 222.23 2.44281 221.942 2.24213C221.638 2.04226 221.296 1.90869 220.938 1.85015C220.512 1.77729 220.08 1.7422 219.647 1.74529H217.031V7.03705L219.666 7.04097Z"
        fill="black"
      />
    </svg>
  )
}
