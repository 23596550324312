import React, { useEffect } from 'react'
import Button from '../../components/Button'
import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import { Spacer } from '../../components/Spacer'
import { Select } from '../../components/Select'
import { EnteredNumberProperty } from '../../api/types'
import { formatKronur } from '../../helpers/formatKronur'
import { useNavigate, useParams } from 'react-router'
import { CoverageIds, PropertyIds } from '../../constants'
import { updatePolicy, useAppState } from '../../Store'
import { api } from '../../api/api.tm'
import { Loading } from '../../components/Loading'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  kaskoCoverageDetails: {
    coverageAmountOptions: Array<number>
    coverageAmount: number
  }
  handleCoverageAmountChange: (value: string) => Promise<void>
  goToNextPage(): void
}

const optionToSelect = (value: number) => ({
  value,
  title: `${formatKronur(value)} kr.`,
})

const CarKaskoAmountComponent: React.FC<Props> = ({
  kaskoCoverageDetails,
  handleCoverageAmountChange,
  goToNextPage,
}: Props) => {
  return (
    <>
      <ChatSection>
        <Select
          label="Stilla eigin áhættu"
          options={kaskoCoverageDetails.coverageAmountOptions.map((i) => {
            return optionToSelect(i)
          })}
          onChange={handleCoverageAmountChange}
          selected={optionToSelect(kaskoCoverageDetails.coverageAmount)}
        />
        <Spacer size="2.75rem" />
        <ChatSectionTitle text="Hér velur þú upphæðina sem kaskótryggingin bætir. Best er að miða við markaðsvirði bílsins, sem verður alltaf hámark bótanna." />
        <Spacer size="2.75rem" />
        <Button onClick={goToNextPage}>Áfram</Button>
        <LogOutButton invisible />
      </ChatSection>

      <PriceFooter />
    </>
  )
}
export const CarKaskoAmount = () => {
  const { state, dispatch } = useAppState()
  const navigate = useNavigate()
  const { quoteId, policyId } = useParams()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Upphæð')
  }, [setTitle])

  if (state.quote && state.quote.policies.length > 0) {
    const policy = state.quote.policies.find((p) => p.number === policyId)
    const kasko = policy && policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.KaskoCoverage)

    if (kasko == null) {
      throw new Error('Something really wrong')
    }

    const coverageAmountProp = kasko.properties.find(
      (a): a is EnteredNumberProperty => a.id === PropertyIds.CoverageAmount
    )

    if (coverageAmountProp == null) {
      throw new Error('something really wrong')
    }

    const props: Props = {
      kaskoCoverageDetails: {
        coverageAmount: coverageAmountProp.value || 0,
        coverageAmountOptions: coverageAmountProp.valueOptions,
      },
      handleCoverageAmountChange: async (value: string) => {
        const coverage = [
          {
            typeId: CoverageIds.KaskoCoverage,
            properties: [{ id: PropertyIds.CoverageAmount, value }],
          },
        ]
        const updatedPolicy = await api.updatePolicy(policyId || '', {
          coverage,
        })
        dispatch(updatePolicy(updatedPolicy))
      },
      goToNextPage() {
        if (quoteId) {
          navigate(`/${quoteId}/kasko-eiginahaetta/${policyId}`)
          window.scrollTo(0, 0)
        }
      },
    }

    return CarKaskoAmountComponent({ ...props })
  } else {
    return <Loading />
  }
}
