import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import Content from '../components/Content'
import { colors } from '../constants'
import { propBetween } from '../helpers/between'
import { breakpointUp } from '../helpers/breakpoints'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useTitle } from '../hooks/useTitle'

const Terms = () => {
  const { routeTo } = useRouteTransition()
  const { quoteId } = useParams()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Notkunarskilmálar')
  }, [setTitle])
  const disclaimers = [
    'Við notkun á vefsölu TM er notanda skylt að auðkenna sig með rafrænum skilríkjum.',
    'Notandi vefsölunnar ber fulla og ótakmarkaða ábyrgð á öllum aðgerðum sem framkvæmdar eru eftir að auðkenning með rafrænum skilríkjum hefur farið fram.',
    'Notandi ábyrgist að þær upplýsingar sem hann veitir félaginu séu réttar. Verði notandi þess var að leiðrétta þurfi rangfærslur af hans hálfu skal hann tafarlaust hafa samband við félagið sé notanda ekki kleift að lagfæra sjálfur rangfærslurnar í ferlinu.',
    'Öll notkun á vefsölunni takmarkast við aðgerðir sem eru eðlilegar og löglegar og í samræmi við þá þjónustu sem TM býður viðskiptavinum sínum á hverjum tíma.',
    'Notandi skal hlíta öryggiskröfum félagsins á hverjum tíma. TM er heimilt að loka aðgangi notanda að vefsölunni sem og öðrum rafrænum miðlum félagsins ef félagið metur notkun hans ekki í samræmi við skilmála þessa, vátryggingaskilmála eða lög.',
    'Notanda er eftir atvikum skylt að undirrita uppsögn með rafrænni undirskrift sem send er á hlutaðeigandi vátryggingarfélag til að ljúka kaupferlinu.',
  ]

  return (
    <Content marginTop={1}>
      <TermsHeading>Notkunarskilmálar</TermsHeading>
      <TermsList>
        {disclaimers.map((disclaimer: string, index: number) => {
          return (
            <TermsListItem key={index}>
              <Text>{disclaimer}</Text>
            </TermsListItem>
          )
        })}
      </TermsList>

      <CloseButton onClick={() => routeTo(`/samthykki/${quoteId || ''}`)}>
        <img src={require('../assets/icons/X.svg')} alt="loka skilmálum og fara til baka" />
      </CloseButton>
    </Content>
  )
}

const TermsHeading = styled.h1`
  ${propBetween('font-size', 1.25, 1.6875, 0, 500)};
  line-height: 1.4;
  font-weight: 700;
  color: ${colors.black};
  margin: 2rem 0 2rem;
`

const TermsList = styled.ol`
  padding: 0;
  list-style: none;
  counter-reset: terms-counter;
  margin-bottom: 6.5rem;

  ${breakpointUp('phone')} {
    padding: 0 1.5rem;
  }
`

const TermsListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0 1rem;
  counter-increment: terms-counter;

  &::before {
    ${propBetween('font-size', 1, 1.125, 0, 500)};
    line-height: 1.5;
    font-weight: 500;
    color: ${colors.black};
    margin-right: 2rem;
    content: counter(terms-counter) '. ';
  }
`

const Text = styled.p`
  margin: 0;

  ${propBetween('font-size', 1, 1.125, 0, 500)};
  line-height: 1.6875rem;
  font-weight: 400;
  color: ${colors.black};
`

const CloseButton = styled.button`
  position: fixed;
  left: 50%;
  margin-left: -1.75rem;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 1.75rem;
  background-color: ${colors.toyotaRed};
  box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  border: 0;
  cursor: pointer;

  &:hover {
    background-color: ${colors.redAccent};
  }
`

export default Terms
