import { Paragraph } from './components'
import React, { useState, useEffect } from 'react'
import { Input } from '../../components/Input'
import { Checkbox } from '../../components/Checkbox'
import { api } from '../../api/api.tm'
import styled from 'styled-components'
import { useExceptionHandler } from '../../hooks/useExceptionHandler'
import { useAppState } from '../../Store'

interface Props {
  email: string
  error?: string
  setError: (error: string | undefined) => void
  hasEmail?: boolean
  setEmail: (email: string) => void
  paperLess: boolean
  existingPaperLess: boolean | undefined
  setPaperLess: (paperLess: boolean) => void
  wantsMarketing: boolean
  existingWantsMarketing: boolean | undefined
  setWantsMarketing: (paperLess: boolean) => void
  gender: 'Male' | 'Female'
  multiplePolicies: boolean
}

export const isEmail = (email: string) =>
  // HTML Email Regex from W3C
  // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email
  )

export const ContactInfo = ({
  email,
  error,
  setError,
  setEmail,
  paperLess,
  existingPaperLess,
  setPaperLess,
  wantsMarketing,
  existingWantsMarketing,
  setWantsMarketing,
  gender,
  multiplePolicies,
}: Props) => {
  const onInputChange = (value: string) => {
    setEmail(value)
    setError(undefined)
  }

  const onInputBlur = () => {
    if (!isEmail(email)) {
      setError('Ógilt netfang')
    }
  }
  return (
    <>
      <Paragraph>
        Eftir kaupin færðu tölvupóst með yfirliti yfir{' '}
        {multiplePolicies ? 'nýju tryggingarnar þínar.' : 'nýju trygginguna þína.'}
      </Paragraph>
      <InputContainer>
        <Input value={email} onChange={onInputChange} label="Netfang" error={error} onBlur={onInputBlur} />
      </InputContainer>
      {!Boolean(existingPaperLess) && (
        <Checkbox
          id="paperLess"
          checked={paperLess}
          onChange={setPaperLess}
          label="Ég vil vernda umhverfið með pappírslausum viðskiptum."
        />
      )}
      {!Boolean(existingWantsMarketing) && (
        <Checkbox
          id="wantsMarketing"
          checked={wantsMarketing}
          onChange={setWantsMarketing}
          label={`Ég vil fá tölvupóst til að hjálpa mér að vera rétt tryggð${gender === 'Male' ? 'ur.' : '.'}`}
        />
      )}
    </>
  )
}

export const useContactInfoProps = (): Props => {
  const { state } = useAppState()
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [paperLess, setPaperLess] = useState<boolean>(false)
  const [wantsMarketing, setWantsMarketing] = useState<boolean>(false)
  const { handleException } = useExceptionHandler()
  const existingEmail = state.person && state.person.isCustomer && state.person.email.personal
  const existingPaperLess = state.person && state.person.isCustomer && state.person.settings.paperLess === 'Yes'
  const existingWantsMarketing =
    state.person && state.person.isCustomer && state.person.settings.wantsMarketing === 'Yes'

  useEffect(() => {
    if (existingEmail) {
      setEmail(existingEmail)
    }
    if (existingPaperLess) {
      setPaperLess(existingPaperLess)
    }
    if (existingWantsMarketing) {
      setWantsMarketing(existingWantsMarketing)
    }
  }, [existingEmail, existingPaperLess, existingWantsMarketing])

  const updatePaperLess = async (paperLess: boolean) => {
    setPaperLess(paperLess)
    try {
      await api.updateCustomerSettings({
        paperLess: boolToYesNo(paperLess),
        wantsMarketing: boolToYesNo(wantsMarketing),
      })
    } catch (error: any) {
      handleException({
        error,
        dispatchErrorNotification: false,
      })
    }
  }

  const updateWantsMarketing = async (wantsMarketing: boolean) => {
    setWantsMarketing(wantsMarketing)
    try {
      await api.updateCustomerSettings({
        paperLess: boolToYesNo(paperLess),
        wantsMarketing: boolToYesNo(wantsMarketing),
      })
    } catch (error: any) {
      handleException({
        error,
        dispatchErrorNotification: false,
      })
    }
  }

  const hasEmail = Boolean(existingEmail)
  return {
    error,
    setError,
    email,
    setEmail,
    paperLess,
    existingPaperLess: existingPaperLess,
    setPaperLess: updatePaperLess,
    wantsMarketing,
    existingWantsMarketing: existingWantsMarketing,
    setWantsMarketing: updateWantsMarketing,
    hasEmail,
    gender: (state.person && state.person.gender) || 'Female',
    multiplePolicies: (state.quote && state.quote.policies.length > 1) || false,
  }
}

const boolToYesNo = (bool: boolean) => (bool ? 'Yes' : 'No')

const InputContainer = styled.div`
  max-width: 19.875rem;
`
