import { useState, useEffect, useMemo } from 'react'

export const useMedia = (query: string) => {
  const observer = useMemo(() => window.matchMedia(query), [query])
  const [matches, setMatches] = useState(observer.matches)

  useEffect(() => {
    const changeHandler = () => {
      setMatches(observer.matches)
    }

    observer.addListener(changeHandler)

    return () => {
      observer.removeListener(changeHandler)
    }
  }, [observer])

  return matches
}
