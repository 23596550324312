import React, { useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import scrollToComponent, { ScrollOptions } from 'react-scroll-to-component'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { Coverage } from '../../../api/types'
import { colors } from '../../../constants'
import { Spacer } from '../../../components/Spacer'

interface Props {
  coverages: Array<Coverage>
}

export const CoverageSlider: React.FC<Props> = ({ coverages }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const rootRef = useRef<HTMLDivElement>(null)

  const expandContent = () => {
    if (!isExpanded) {
      const options: ScrollOptions = {
        ease: 'inOutQuad',
        duration: 500,
        align: 'top',
      }
      scrollToComponent(rootRef.current, options)
    }
    setIsExpanded(!isExpanded)
  }

  return (
    <Root ref={rootRef}>
      <CoveredButton onClick={expandContent} aria-expanded={isExpanded} aria-controls="coverages">
        <CoveredBtnText isExpanded={isExpanded} tabIndex={-1}>
          Hvað er bætt?
        </CoveredBtnText>
      </CoveredButton>

      <AnimateHeight easing="cubic-bezier(0, 0, 0, 1)" duration={1000} height={isExpanded ? 'auto' : 0}>
        <Coverages id="coverages">
          <CSSTransition in={isExpanded} classNames="coverages-slider" timeout={2000} unmountOnExit>
            <CoverageItem>
              <CoverageHeader>Bætir</CoverageHeader>

              {coverages.map((coverage) => (
                <React.Fragment key={coverage.typeId}>
                  {coverages.length > 1 && <CoveringTitle>{coverage.name}</CoveringTitle>}

                  {coverage.covered.map((text: string) => (
                    <Covering key={text}>{text}</Covering>
                  ))}
                </React.Fragment>
              ))}
            </CoverageItem>
          </CSSTransition>

          <Spacer size="9.375rem" horizontal />

          <CSSTransition in={isExpanded} classNames="coverages-slider" timeout={3000} mountOnEnter unmountOnExit>
            <CoverageItem>
              <CoverageHeader>Bætir ekki</CoverageHeader>

              {coverages.map((coverage) => (
                <React.Fragment key={coverage.typeId}>
                  {coverages.length > 1 && <CoveringTitle>{coverage.name}</CoveringTitle>}

                  {coverage.notCovered.map((text: string) => (
                    <Covering key={text}>{text}</Covering>
                  ))}
                </React.Fragment>
              ))}
            </CoverageItem>
          </CSSTransition>
        </Coverages>
      </AnimateHeight>
    </Root>
  )
}

const Root = styled.div`
  margin-top: 3.5rem;

  /* Classes provided by animations */
  .coverages-slider-enter {
    opacity: 0.01;
    transform: translateY(50%);
  }
  .coverages-slider-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.3s, opacity 0.5s ease-out 0.3s;
  }

  .coverages-slider-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .coverages-slider-exit-active {
    opacity: 0.01;
    transition: all 1s cubic-bezier(0, 0, 0, 1);
    transform: translateY(50%);
  }

  @media screen and (min-width: 700px) {
    padding-top: 6.125rem;
  }
`

const CoveredButton = styled.button`
  width: 100%;
  position: relative;
  text-align: left;
  border: none;
  cursor: pointer;
  color: ${colors.black};
  padding: 0;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 700px) {
    width: 24rem;
  }
`

const CoveredBtnText = styled.span<{ isExpanded: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${colors.paleGray};
  padding: 1.25rem 2.5rem 1.25rem 2rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  ${CoveredButton}:focus > & {
    /* The following two rules reinstate default system focus handling only on keyboard navigation for webkit and non-webkit browsers */
    outline: 0.0625rem dotted #212121;
    outline: 0.3125rem auto -webkit-focus-ring-color;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 0 0 0.25rem ${(props) => props.theme.primaryColor};
    transition: opacity 0.3s ease;

    ${CoveredButton}:focus {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1.5rem;
    display: block;
    height: 0.4375rem;
    width: 0.625rem;
    border: 0.3125rem solid transparent;
    background-color: transparent;
    border-top: ${(props) => (props.isExpanded ? 'none' : `0.4375rem solid ${colors.black}`)};
    border-bottom: ${(props) => (props.isExpanded ? `0.4375rem solid ${colors.black}` : 'none')};
    margin-top: -0.1875rem;
    transition: all 0.3s ease-out;
  }
`

const Coverages = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
`

const CoverageItem = styled.span`
  flex: 1;
  padding-top: 3rem;
`

const CoverageHeader = styled.h2`
  font-size: 2.5rem;
  opacity: 1;

  @media screen and (min-width: 700px) {
    font-size: 3.75rem;
  }
`

const CoveringTitle = styled.h3`
  font-size: 1.875rem;
  font-weight: 500;
  margin: 2rem 0 1.5rem;
`

const Covering = styled.p`
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin: 1.5rem 0;
`
