import React, { useState } from 'react'
import { Paragraph, RadioContainer } from './components'
import { formatKronur } from '../../helpers/formatKronur'
import { Radio } from '../../components/Radio'
import amortize from 'amortize'
import { useAppState } from '../../Store'

type Schedule = 'monthly' | 'yearly'

interface Props {
  schedule: Schedule
  setSchedule: (schedule: Schedule) => void
  monthlyAmount: number
  yearlyAmount: number
  yearlyAmountWithAHK: number
}

export const PaymentSchedule = ({ schedule, setSchedule, monthlyAmount, yearlyAmount, yearlyAmountWithAHK }: Props) => {
  const ahk = 100 * (yearlyAmountWithAHK / yearlyAmount - 1)
  return (
    <>
      <Paragraph>
        Greitt er fyrir eitt ár í einu. Við bjóðum einnig upp á greiðsludreifingu á 12 mánuði svo þú þurfir ekki að
        greiða alla upphæðina í einu lagi. Athugaðu að þá er árleg hlutfallstala kostnaðar{' '}
        {/* use toLocaleString('de-DE') because 'is-IS' is not bundled with chrome */}
        {ahk.toLocaleString('de-DE')}% þannig að heildarupphæðin verður {formatKronur(yearlyAmountWithAHK)} kr.
      </Paragraph>
      <RadioContainer>
        <Radio
          checked={schedule === 'monthly'}
          label="Greitt mánaðarlega"
          subLabel={formatKronur(monthlyAmount)}
          groupName="payment-schedule"
          id="payment-schedule--monthly"
          onChange={() => setSchedule('monthly')}
        />
        <Radio
          checked={schedule === 'yearly'}
          label="Greitt árlega"
          subLabel={formatKronur(yearlyAmount)}
          groupName="payment-schedule"
          id="payment-schedule--yearly"
          onChange={() => setSchedule('yearly')}
        />
      </RadioContainer>
    </>
  )
}

export const usePaymentScheduleProps = (): Props => {
  const { state } = useAppState()
  const totalAmount =
    (state.quote && state.quote.policies.reduce((sum, policy) => sum + (policy.policyPeriod.price.total || 0), 0)) || 0
  const amortization = amortize({
    amount: totalAmount,
    rate: 7,
    totalTerm: 12,
    amortizeTerm: 12,
  })

  // This is the price TM pays to Borgun for issuing
  // electronic bills
  const costPerPayment = 95
  const monthlyAmount = amortization.payment + costPerPayment
  const yearlyAmount = totalAmount
  const yearlyAmountWithAHK = monthlyAmount * 12
  const [schedule, setSchedule] = useState<Schedule>('monthly')

  return {
    yearlyAmount,
    yearlyAmountWithAHK,
    monthlyAmount,
    schedule,
    setSchedule,
  }
}
