import { breakpoints } from './breakpoints'

export default function between(
  from: number,
  to: number,
  fromWidth: number = breakpoints.phone / 16,
  toWidth: number = breakpoints.desktopL / 16,
  unit = 'vw'
) {
  const slope = (to - from) / (toWidth - fromWidth)

  const base = from - slope * fromWidth

  return `calc(${base}rem + ${100 * slope}${unit})`
}

export const propBetween = (
  prop: string,
  from: number,
  to: number,
  fromWidth: number = breakpoints.phone / 16,
  toWidth: number = breakpoints.desktopL / 16,
  unit = 'vw'
) => `
  ${prop}: ${between(from, to, fromWidth, toWidth, unit)};
  @media screen and (min-width: ${toWidth * 16}px) {
    ${prop}: ${to}rem;
  }

  @media screen and (max-width: ${fromWidth * 16}px) {
    ${prop}: ${from}rem;
  }
`
