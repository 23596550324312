import { useAppState, setError } from '../Store'
import { useCallback } from 'react'

export const useDispatchErrorNotification = () => {
  const { dispatch } = useAppState()
  return useCallback(
    (message: string) => {
      dispatch(setError({ message, visible: true }))
      setTimeout(() => {
        dispatch((state) => ({
          ...state,
          error: {
            message: state.error ? state.error.message : '',
            visible: false,
          },
        }))
      }, 3000)
    },
    [dispatch]
  )
}
