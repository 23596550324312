import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Breadcrumb } from './Breadcrumb'

export const Breadcrumbs: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const location = useLocation().pathname
  const breadcrumbs = useMemo(
    () => [
      {
        text: 'Innskrá',
        url: 'audkenna',
      },
      {
        text: 'Kaupandi',
        url: 'kaupandi',
      },
      {
        text: 'Bíll',
        url: 'okutaeki',
      },
      {
        text: 'Trygging',
        url: 'yfirlit',
      },
      { text: 'Senda slóð', url: 'senda-tilbod' },
    ],
    []
  )
  useEffect(() => {
    const index = breadcrumbs.findIndex(({ url }) => location.indexOf(url) > -1)
    // if no mathces we assume everything is completed
    setActiveIndex(index >= 0 ? index : Infinity)
  }, [location, breadcrumbs])

  return (
    <>
      {location.indexOf('admin') > -1 && (
        <StyledBreadcrumbs>
          {breadcrumbs.map((breadcrumb, index) => {
            return (
              <Breadcrumb
                key={index}
                text={breadcrumb.text}
                active={index === activeIndex}
                completed={index < activeIndex}
              />
            )
          })}
        </StyledBreadcrumbs>
      )}
    </>
  )
}

const StyledBreadcrumbs = styled.div`
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
