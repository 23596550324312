import React, { useEffect } from 'react'
import Button from '../../components/Button'
import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import { Spacer } from '../../components/Spacer'
import { Select } from '../../components/Select'
import { EnteredNumberProperty } from '../../api/types'
import { formatKronur } from '../../helpers/formatKronur'
import { useNavigate, useParams } from 'react-router'
import { CoverageIds, PropertyIds } from '../../constants'
import { updatePolicy, useAppState } from '../../Store'
import { api } from '../../api/api.tm'
import { Loading } from '../../components/Loading'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  kaskoCoverageDetails: {
    selfRiskOptions: Array<number>
    selfRiskAmount: number
  }
  handleKaskoSelfRiskChange: (value: string) => Promise<void>
  goToNextPage(): void
}

const optionToSelect = (value: number) => ({
  value,
  title: `${formatKronur(value)} kr.`,
})

const CarSelfRiskComponent: React.FC<Props> = ({
  kaskoCoverageDetails,
  handleKaskoSelfRiskChange,
  goToNextPage,
}: Props) => {
  return (
    <>
      <ChatSection>
        <Select
          label="Stilla eigin áhættu"
          options={kaskoCoverageDetails.selfRiskOptions.map((i) => {
            return optionToSelect(i)
          })}
          onChange={handleKaskoSelfRiskChange}
          selected={optionToSelect(kaskoCoverageDetails.selfRiskAmount)}
        />
        <Spacer size="2.75rem" />
        <ChatSectionTitle text="Í kaskótryggingu er eigin áhætta, sem er sá hluti sem þú þarft að greiða komi til tjóns. Flestir velja 135.000 kr." />
        <Spacer size="2.75rem" />
        <Button onClick={goToNextPage}>Áfram</Button>
        <LogOutButton invisible />
      </ChatSection>
      <PriceFooter />
    </>
  )
}
export const CarSelfRisk = () => {
  const { state, dispatch } = useAppState()
  const navigate = useNavigate()
  const { quoteId, policyId } = useParams()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Eigin áhætta')
  }, [setTitle])

  if (state.quote && state.quote.policies.length > 0) {
    const policy = state.quote.policies.find((p) => p.number === policyId)
    const kasko = policy && policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.KaskoCoverage)

    if (kasko == null) {
      throw new Error('Something really wrong')
    }

    const selfRiskProp = kasko.properties.find((a): a is EnteredNumberProperty => a.id === PropertyIds.SelfRisk)

    if (selfRiskProp == null) {
      throw new Error('something really wrong')
    }

    const props: Props = {
      kaskoCoverageDetails: {
        selfRiskAmount: selfRiskProp.value || 0,
        selfRiskOptions: selfRiskProp.valueOptions,
      },
      handleKaskoSelfRiskChange: async (value: string) => {
        const coverage = [
          {
            typeId: CoverageIds.KaskoCoverage,
            properties: [{ id: PropertyIds.SelfRisk, value }],
          },
        ]
        const selfRiskPolicy = await api.updatePolicy(policyId || '', {
          coverage,
        })
        dispatch(updatePolicy(selfRiskPolicy))
      },
      goToNextPage() {
        if (quoteId) {
          navigate(`/${quoteId}/tryggja-annan-bil`)
          window.scrollTo(0, 0)
        }
      },
    }

    return CarSelfRiskComponent({ ...props })
  } else {
    return <Loading />
  }
}
