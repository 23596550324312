// values from bootstrap
// https://getbootstrap.com/docs/4.3/layout/overview/
export const breakpoints = {
  phone: 576,
  tablet: 768,
  desktop: 992,
  desktopL: 1440,
}

export const maxWidth = (bp: keyof typeof breakpoints) => `(max-width: ${breakpoints[bp] - 0.02}px)`

export const minWidth = (bp: keyof typeof breakpoints) => `(min-width: ${breakpoints[bp]}px)`

export const breakpointUp = (bp: keyof typeof breakpoints) => `@media screen and ${minWidth(bp)}`

export const breakpointDown = (bp: keyof typeof breakpoints) => `@media screen and ${maxWidth(bp)}`
