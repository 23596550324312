import styled from 'styled-components'
import React from 'react'
import { Policy } from '../../api/types'
import { colors } from '../../constants'
import { formatKronur } from '../../helpers/formatKronur'
import Button from '../../components/Button'
import { breakpointDown } from '../../helpers/breakpoints'

interface Props {
  policies: Policy[]
  canBuy?: boolean
  loading?: boolean
  showHeading?: boolean
  errorText?: string
  onBuy?: () => void
}
export const CheckoutTable = ({
  policies,
  canBuy = false,
  loading = false,
  showHeading = true,
  errorText,
  onBuy = () => {},
}: Props) => {
  return (
    <Root>
      {showHeading && <h1>Kaupyfirlit</h1>}
      <Table>
        <thead>
          <tr>
            <th>Tryggingar</th>
            <th>Verð</th>
          </tr>
        </thead>
        <tbody>
          {policies.map((policy, index) => (
            <CheckoutTablePolicyItem policy={policy} key={index} />
          ))}
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <th>Viðbætur</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {policies
            .flatMap((x) => (x.subjects[0].coverage || []).filter((x) => x.optional && x.selected))
            .map((x, index) => (
              <ExtrasRow key={index}>
                <td>
                  <strong>{x.name}</strong>
                </td>
                <td>{formatKronur(x.entryPriceTotal.total || 0)} kr.</td>
              </ExtrasRow>
            ))}
        </tbody>
      </Table>
      <HorizontalLine />
      <TotalPriceContainer>
        <TotalPriceLabel>Samtals</TotalPriceLabel>
        <TotalPrice>
          <TotalPriceNumber>{formatKronur(totalPrice(policies))}</TotalPriceNumber>
          <TotalCurrency> kr.</TotalCurrency>
        </TotalPrice>
      </TotalPriceContainer>
      <BuyButtonContainer>
        <Button onClick={onBuy} loading={loading} disabled={!canBuy || loading}>
          GREIÐA
        </Button>
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </BuyButtonContainer>
    </Root>
  )
}

function totalPrice(policies: Policy[]) {
  return policies.reduce((sum, policy) => sum + (policy.policyPeriod.price.total || 0), 0)
}

interface ItemProps {
  policy: Policy
}

const CheckoutTablePolicyItem = ({ policy }: ItemProps) => {
  const price = policy.policyPeriod.price.total || 0
  const priceWithoutExtras =
    price -
    policy.subjects[0].coverage
      .filter((x) => x.optional && x.selected)
      .reduce((p, v) => p + (v.entryPriceTotal.total || 0), 0)
  const subject = policy.subjects[0]
  return (
    <>
      <tr>
        <td>
          <strong>{policy.product.name}</strong>
        </td>
        <td>{formatKronur(priceWithoutExtras)} kr.</td>
      </tr>
      <tr>
        <td>
          {subject.description} {subject.modelYear}
        </td>
        <td></td>
      </tr>
    </>
  )
}

const ErrorText = styled.div`
  color: ${colors.errorRed};
  margin-top: 1rem;
`

const Root = styled.div`
  border: 0.0625rem solid ${colors.silverGray};
  padding: 3rem;
  height: fit-content;

  ${breakpointDown('tablet')} {
    padding: 0;
    border: 0;
  }
`

const BuyButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding-top: 1.75rem;

  ${breakpointDown('tablet')} {
    display: none;
  }
`

const HorizontalLine = styled.hr`
  display: block;

  width: 100%;
  height: 0.0625rem;
  margin: 1.5rem 0;

  background-color: ${colors.silverGray};
  border: 0;

  ${breakpointDown('tablet')} {
    display: none;
  }
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  strong {
    font-weight: 500;
  }

  th {
    font-size: 0.75rem;
    color: ${colors.steelGray};
    padding: 1.5rem 0;
    text-transform: uppercase;
  }
  td {
    font-size: 0.875rem;
    line-height: 1.75rem;
  }
  td,
  th {
    text-align: left;
  }
  td:last-child,
  th:last-child {
    text-align: right;
    font-weight: 500;
  }
`

const ExtrasRow = styled.tr`
  > td {
    padding-bottom: 1rem;
  }
`

const TotalPriceContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  ${breakpointDown('tablet')} {
    display: none;
  }
`
const TotalPriceNumber = styled.span`
  display: inline;

  color: ${colors.black};
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 900;
`
const TotalCurrency = styled.span`
  font-size: 1.125rem;
  line-height: 1.5;

  font-weight: 500;
`

const TotalPriceLabel = styled.span`
  display: flex;

  font-size: 0.875rem;
  font-weight: 500;
`

const TotalPrice = styled.span`
  text-align: right;
  flex-grow: 1;
`
