import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react'
import { getBrandTypeFromUrl } from './helpers/getBrandTypeFromUrl'
import { Person, Policy, Quote, Brand, VehicleModel, CancellationFile } from './api/types'

interface AppState {
  person?: Person
  quote?: Quote
  phoneNumber?: string
  vehicleModels: VehicleModel[]
  brandType: Brand
  error?: {
    visible: boolean
    message: string
  }
  cancellations?: CancellationFile[]
  signatureControlCode?: string
}

const QUOTE_KEY = 'quote'
const VEHICLE_MODELS_KEY = 'vehicleModels'
const PERSON_KEY = 'person'
const PHONE_NUMBER_KEY = 'phoneNumber'
export const setPerson = setStateProperty('person')
export const setQuote = setStateProperty('quote')
export const setPhoneNumber = setStateProperty('phoneNumber')
export const setError = setStateProperty('error')
export const setCancellations = setStateProperty('cancellations')
export const addVehicleModel = (vehicleInfo: VehicleModel) => (state: AppState) => {
  const updatedVehicleModels = [...state.vehicleModels, vehicleInfo]
  window.sessionStorage.setItem(VEHICLE_MODELS_KEY, JSON.stringify(updatedVehicleModels))
  return {
    ...state,
    vehicleModels: updatedVehicleModels,
  }
}
export const updatePolicy = (policy: Policy) => (state: AppState) => {
  const updatedQuote = {
    ...state.quote!,
    policies: state.quote!.policies.map((p) => (p.number === policy.number ? policy : p)),
  }
  window.sessionStorage.setItem(QUOTE_KEY, JSON.stringify(updatedQuote))
  return {
    ...state,
    quote: updatedQuote,
  }
}
export const removePolicy = (policyNumber: string) => (state: AppState) => {
  const updatedQuote = {
    ...state.quote!,
    policies: state.quote!.policies.filter((p) => p.number !== policyNumber),
  }
  window.sessionStorage.setItem(QUOTE_KEY, JSON.stringify(updatedQuote))
  return {
    ...state,
    quote: updatedQuote,
  }
}
export const addPolicy = (policy: Policy) => (state: AppState) => {
  const updatedQuote = {
    ...state.quote!,
    policies: state.quote!.policies.concat([policy]),
  }
  window.sessionStorage.setItem(QUOTE_KEY, JSON.stringify(updatedQuote))
  return {
    ...state,
    quote: updatedQuote,
  }
}

function setStateProperty<K extends keyof AppState>(prop: K) {
  return (value: AppState[K]) => (state: AppState) => {
    window.sessionStorage.setItem(prop, JSON.stringify(value))
    return {
      ...state,
      [prop]: value,
    }
  }
}

const getFromSessionStorage = (key: string, defaultValue?: any) => {
  const data = sessionStorage.getItem(key)
  if (data) {
    return JSON.parse(data)
  }
  return defaultValue
}
const reducer = (state: AppState, action: (state: AppState) => AppState) => action(state)

export const clearState = (): AppState => {
  return initialState
}
const initialState: AppState = {
  person: undefined,
  quote: undefined,
  phoneNumber: undefined,
  vehicleModels: [],
  brandType: getBrandTypeFromUrl(),
}
interface ContextProps {
  state: AppState
  dispatch: React.Dispatch<(state: AppState) => AppState>
}
const AppContext = createContext<ContextProps>({} as ContextProps)

export const Store = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, {
    person: getFromSessionStorage(PERSON_KEY),
    quote: getFromSessionStorage(QUOTE_KEY),
    phoneNumber: getFromSessionStorage(PHONE_NUMBER_KEY),
    vehicleModels: getFromSessionStorage(VEHICLE_MODELS_KEY, []),
    brandType: getBrandTypeFromUrl(),
  })
  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}
export const useAppState = () => useContext(AppContext)
