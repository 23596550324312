import { useEffect, useCallback, createContext, SetStateAction, Dispatch, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { wait } from '../helpers/wait'

export const CurtainContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([false, () => {}])

export const useRouteTransition = () => {
  const navigate = useNavigate()
  const [curtainVisible, setCurtainVisible] = useContext(CurtainContext)
  useEffect(() => {
    if (curtainVisible) {
      setTimeout(() => {
        setCurtainVisible(false)
      }, 1000)
    }
  }, [curtainVisible, setCurtainVisible])

  const routeTo = useCallback(
    async (path: string) => {
      setCurtainVisible(true)
      // wait until curtain is up
      await wait(500)
      window.scrollTo(0, 0)
      navigate(path)
    },
    [navigate, setCurtainVisible]
  )

  return { curtainVisible, routeTo }
}
