import { usePaymentInputs } from 'react-payment-inputs'
import React, { useState } from 'react'
import images from 'react-payment-inputs/images'
import styled from 'styled-components'
import { WrappedInput } from '../../components/Input'
import { zIndex } from '../../helpers/z-index'

interface CardInfo {
  cardNumber: string
  expiryDate: string
  cvc: string
}

type Props = ReturnType<typeof useCreditCardProps>

const setProp = <K extends keyof any, TValue>(prop: K, value: TValue) => <TObj extends { [P in K]: TValue }>(
  obj: TObj
) => ({ ...obj, [prop]: value })

export const CreditCard = ({ cardInfo, setCardInfo, onCardInfoChanged, paymentInputProps }: Props) => {
  const { getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps, meta } = paymentInputProps

  return (
    <CardGrid>
      <SpanAllColumns>
        <CardImage {...getCardImageProps({ images })} />
        <WrappedInput
          {...getCardNumberProps({
            placeholder: 'Kortanúmer',
            value: cardInfo.cardNumber,
            onChange: (ev) => {
              const value = ev.currentTarget.value
              setCardInfo(setProp('cardNumber', value))
              onCardInfoChanged()
            },
          })}
          error={(meta.isTouched && meta.erroredInputs.cardNumber) || undefined}
          required
        />
      </SpanAllColumns>
      <WrappedInput
        {...getExpiryDateProps({
          placeholder: 'MM/ÁÁ',
          value: cardInfo.expiryDate,
          onChange: (ev) => {
            const value = ev.currentTarget.value
            setCardInfo(setProp('expiryDate', value))
            onCardInfoChanged()
          },
        })}
        error={(meta.isTouched && meta.erroredInputs.expiryDate) || undefined}
        required
      />
      <WrappedInput
        {...getCVCProps({
          value: cardInfo.cvc,
          onChange: (ev) => {
            const value = ev.currentTarget.value
            setCardInfo(setProp('cvc', value))
            onCardInfoChanged()
          },
        })}
        error={(meta.isTouched && meta.erroredInputs.cvc) || undefined}
        required
      />
    </CardGrid>
  )
}

export const useCreditCardProps = (onCardInfoChanged: () => void) => {
  const [cardInfo, setCardInfo] = useState<CardInfo>({
    cardNumber: '',
    expiryDate: '',
    cvc: '',
  })

  const paymentInputProps = usePaymentInputs({
    errorMessages: {
      emptyCardNumber: 'Kortanúmer vantar',
      invalidCardNumber: 'Kortanúmer er ógilt',
      emptyExpiryDate: 'Gildistíma vantar',
      monthOutOfRange: 'Mánuður er ekki til',
      yearOutOfRange: 'Ár passar ekki',
      dateOutOfRange: 'Dagsetning ekki gild',
      invalidExpiryDate: 'Dagsetning ekki gild',
      emptyCVC: 'CVC númer vantar',
      invalidCVC: 'CVC númer ógilt',
    },
  })

  return { cardInfo, setCardInfo, onCardInfoChanged, paymentInputProps }
}

const CardImage = styled.svg`
  position: absolute;
  right: 1rem;
  top: 50%;
  z-index: ${zIndex.overlay};

  /* vertically center */
  transform: translateY(-50%);

  width: 2rem;
  height: 1.33rem;
`

const SpanAllColumns = styled.div`
  /* span all columns */
  grid-column: 1 / -1;

  position: relative;
`

const CardGrid = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`
