import React from 'react'
import styled from 'styled-components'

import { SupportedModels } from '../../../api/types'
import { colors } from '../../../constants'
import { breakpointDown, breakpointUp } from '../../../helpers/breakpoints'
import { propBetween } from '../../../helpers/between'

const addedValues = {
  [SupportedModels.Toyota]: [
    'Afnot af bílaleigubíl á meðan bíllinn er óökufær.',
    'Viðgerð með upprunalegum Toyota varahlutum.',
    'Bílaþvottur að utan að lokinni viðgerð.',
  ],
  [SupportedModels.Lexus]: [
    'Afnot af bílaleigubíl á meðan bíllinn er óökufær.',
    'Viðgerð með upprunalegum Lexus varahlutum.',
    'Alþrif að lokinni viðgerð.',
    'Ef viðgerð fer fram í Kauptúni eru afnot af Lexus bílaleigubíl innifalin og bíllinn er sóttur og honum skilað sé óskað eftir því.',
  ],
}

interface Props {
  modelType: SupportedModels
}

export const AddedValues = ({ modelType }: Props) => (
  <Root>
    <DescriptionWrapper>
      <Heading>Þjónusta innifalin ef viðgerð fer fram hjá viðurkenndum þjónustuaðila {modelType}</Heading>
      <List>
        {addedValues[modelType].map((value, index) => (
          <ListItem key={index}>
            <ListItemText>{value}</ListItemText>
            <ListItemBorder />
          </ListItem>
        ))}
      </List>
    </DescriptionWrapper>
  </Root>
)

const Root = styled.article`
  position: relative;

  display: flex;
  flex-direction: column;

  padding: 5.5rem 0 0 0;

  color: ${colors.black};
  background-color: ${colors.white};

  ${breakpointUp('phone')} {
    border-bottom: 0.0625rem solid ${colors.silverGray};
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0.01em;

  ${breakpointUp('phone')} {
    padding-bottom: 6.5rem;
  }
`

const Heading = styled.h1`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 1.25, 2.5)};
  line-height: 120%;
  letter-spacing: -0.012em;
`

const List = styled.ul`
  padding-left: 1.25rem;
  margin-bottom: 0;
`

const ListItem = styled.li`
  color: ${(props) => props.theme.primaryColor};

  font-size: 1.5rem;
`

const ListItemText = styled.p`
  color: ${colors.black};

  font-size: 1.25rem;
`

const ListItemBorder = styled.span`
  ${breakpointDown('phone')} {
    display: block;

    width: calc(100vw - 3rem);
    height: 0.0625rem;

    margin-left: -1.5rem;

    background-color: ${colors.silverGray};
  }
`
