import * as React from 'react'
import styled, { keyframes } from 'styled-components'

import { colors } from '../constants'
import { Completed } from '../icons/Completed'

interface IProps {
  text: string
  active: boolean
  completed: boolean
}

export const Breadcrumb: React.FC<IProps> = ({ text, active, completed }: IProps) => (
  <StyledBreadcrumb active={active} completed={completed}>
    {completed ? (
      <StyledIconWrapper>
        <Completed />
      </StyledIconWrapper>
    ) : (
      <StyledText>{text.toUpperCase()}</StyledText>
    )}
    {active && <StyledActiveBorder />}
  </StyledBreadcrumb>
)

const activate = keyframes`
  0% {
    width: 1%;
  }
  100% {
    width: 100%;
  }
`
const StyledBreadcrumb = styled('div')<{ active: boolean; completed: boolean }>`
  color: ${(props) => (props.active || props.completed ? colors.black : colors.steelGray)};
  display: inline-block;
  ${(props) => !props.active && `border-bottom: solid 2px ${colors.paleGray};`}
`
const StyledIconWrapper = styled.div`
  min-width: 7.375rem;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledText = styled.p`
  margin-bottom: 0.8rem;
  padding: 0 1.75rem;
  font-weight: 500;
  font-size: 0.75rem;
`
const StyledActiveBorder = styled.span`
  display: block;
  height: 0.125rem;
  animation: ${activate} 0.5s forwards;
  background-color: ${(props) => props.theme.primaryColor};
`
