import React from 'react'

export const LexusLogo = () => {
  return (
    <svg width="226" height="30" viewBox="0 0 226 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 17.3518V0.279297H2.32805V15.1497H8.77802V17.3478H0V17.3518Z" fill="black" />
      <path
        d="M12.9482 17.3518V0.279297H22.4478V2.47742H15.2803V7.92668H22.2883V10.1248H15.2803V15.1537H22.8943V17.3518H12.9482Z"
        fill="black"
      />
      <path
        d="M38.6163 17.3536L34.8212 11.532C34.5422 11.1116 34.0997 10.3389 34.0997 10.3389C34.0997 10.3389 33.6572 11.0876 33.3303 11.5801L29.5592 17.3576H26.8564L32.7483 8.35295L27.5341 0.285156H30.3964L33.6572 5.33803L34.1914 6.22689C34.1914 6.20287 34.4465 5.73442 34.7495 5.28999L38.0303 0.285156H40.7091L35.5228 8.23283L41.4347 17.3536H38.6163Z"
        fill="black"
      />
      <path
        d="M51.8434 17.6321C47.9327 17.6321 45.2061 15.1056 45.2061 10.8976V0.279297H47.558V10.8976C47.558 14.1927 49.607 15.4339 51.8434 15.4339C54.0558 15.4339 56.1965 14.1727 56.1965 10.8976V0.279297H58.5006V10.8976C58.5006 14.9655 55.6384 17.6321 51.8434 17.6321Z"
        fill="black"
      />
      <path
        d="M68.0679 17.633C66.3458 17.633 64.6436 17.1886 63.085 15.9034L64.4124 14.1016C65.3213 14.9184 66.7883 15.3628 67.9523 15.3628C69.6744 15.3628 70.8624 14.5901 70.8624 12.9765C70.8624 11.5271 69.5349 10.7304 68.0201 9.96162C65.6004 8.72042 63.1328 7.64738 63.1328 4.39625C63.1328 1.86981 64.9944 0 68.044 0C69.7422 0 71.3049 0.516498 72.6323 1.4494L71.3766 3.36725C70.3282 2.59451 69.4432 2.29021 68.0241 2.29021C66.6966 2.29021 65.5804 2.96687 65.5804 4.25611C65.5804 6.10189 67.2109 6.7345 69.3037 7.78752C71.2371 8.74444 73.2861 9.98564 73.2861 12.8404C73.2821 15.9034 70.9062 17.633 68.0679 17.633Z"
        fill="black"
      />
      <path
        d="M201.392 11.4439L201.879 10.0386H208.767L205.223 0.365234H204.669L198.446 17.4417H199.212L201.137 12.1406H206.288L208.213 17.4417H208.93L207.029 12.1406H208.807L210.732 17.4417H211.45L209.289 11.4439H201.392ZM205.985 9.36592L204.059 4.09283L204.948 1.68651L207.786 9.36592H205.985ZM202.134 9.36592L203.712 5.07378L205.311 9.36592H202.134Z"
        fill="#FF0000"
      />
      <path d="M202.245 0.365234L195.971 17.4417H196.692L202.967 0.365234H202.245Z" fill="#FF0000" />
      <path
        d="M187.388 12.0441H191.889C191.606 12.7128 191.127 13.2933 190.469 13.7698C189.672 14.3103 188.715 14.5946 187.699 14.5946C186.993 14.5946 186.24 14.4224 185.562 14.1061C184.924 13.8419 184.33 13.4214 183.804 12.8689C183.266 12.2523 182.875 11.6437 182.604 11.0151C182.329 10.3024 182.19 9.56172 182.19 8.817C182.19 7.96018 182.329 7.13938 182.604 6.38265C182.895 5.67797 183.298 5.06538 183.8 4.55689C184.757 3.54792 186.112 2.99138 187.607 2.99138C188.532 2.99138 189.282 3.10749 189.967 3.36374C190.569 3.57194 191.199 3.9483 191.956 4.54888L192.1 4.66099L192.542 4.16051L192.391 4.03238C190.928 2.8112 189.497 2.29471 187.607 2.29471C185.953 2.29471 184.418 2.93532 183.286 4.09645C182.692 4.71704 182.266 5.38569 181.986 6.14642C181.68 6.99524 181.524 7.89211 181.524 8.817C181.524 9.63379 181.699 10.4986 182.01 11.2474C182.273 11.948 182.692 12.6127 183.334 13.3414C183.896 13.9299 184.562 14.4024 185.315 14.7427C186.053 15.0871 186.854 15.2632 187.703 15.2632C188.931 15.2632 190.031 14.9349 190.884 14.3183C191.693 13.7217 192.291 12.933 192.618 12.0401H194.145C193.352 14.4024 190.768 16.7647 187.631 16.7647C185.718 16.7647 183.792 15.9559 182.341 14.5505C181.6 13.8499 181.006 13.0131 180.579 12.0641C180.133 10.9631 179.906 9.86602 179.906 8.79298C179.906 7.6759 180.113 6.59486 180.531 5.5018C180.946 4.52086 181.508 3.68405 182.198 3.0114C183.693 1.58202 185.626 0.797259 187.651 0.797259C189.864 0.797259 191.546 1.42587 193.28 2.89929L193.423 3.0194L193.87 2.51492L193.722 2.38679C192.877 1.65809 191.992 1.11757 191.103 0.77724C190.087 0.348826 188.959 0.140625 187.655 0.140625C185.379 0.140625 183.29 0.981436 181.767 2.51091C180.926 3.33171 180.284 4.28062 179.918 5.24956C179.475 6.30257 179.26 7.45969 179.26 8.78497C179.26 11.1793 180.193 13.4054 181.887 15.059C183.422 16.5485 185.514 17.4053 187.631 17.4053C191.837 17.4053 194.312 14.022 194.946 11.7518L195.062 11.3394H187.388V12.0441Z"
        fill="#FF0000"
      />
      <path d="M195.069 9.26953H187.388V9.9662H195.069V9.26953Z" fill="#FF0000" />
      <path
        d="M125.475 12.0677H129.976C129.693 12.7363 129.214 13.3169 128.557 13.7933C127.759 14.3338 126.803 14.6181 125.786 14.6181C125.08 14.6181 124.327 14.4459 123.649 14.1296C123.011 13.8654 122.418 13.445 121.891 12.8925C121.357 12.2759 120.962 11.6713 120.691 11.0387C120.416 10.326 120.277 9.58526 120.277 8.84054C120.277 7.98371 120.416 7.16292 120.691 6.40619C120.982 5.70151 121.385 5.08491 121.887 4.58042C122.844 3.57145 124.199 3.01491 125.694 3.01491C126.615 3.01491 127.365 3.13102 128.058 3.38727C128.66 3.59547 129.29 3.97184 130.043 4.57242L130.187 4.68453L130.629 4.18404L130.478 4.05592C129.015 2.83474 127.588 2.31424 125.694 2.31424C124.04 2.31424 122.505 2.95485 121.373 4.11597C120.779 4.73657 120.353 5.40522 120.07 6.16596C119.763 7.01477 119.607 7.91164 119.607 8.83253C119.607 9.64932 119.783 10.5142 120.093 11.2629C120.353 11.9636 120.775 12.6282 121.417 13.3569C121.979 13.9455 122.645 14.4179 123.398 14.7583C124.132 15.1026 124.937 15.2788 125.786 15.2788C127.014 15.2788 128.114 14.9504 128.967 14.3338C129.776 13.7373 130.374 12.9485 130.701 12.0556H132.228C131.435 14.4179 128.848 16.7802 125.714 16.7802C123.801 16.7802 121.875 15.9714 120.424 14.5621C119.683 13.8614 119.089 13.0246 118.662 12.0757C118.216 10.9746 117.989 9.87354 117.989 8.8045C117.989 7.68743 118.196 6.61039 118.615 5.51333C119.029 4.53238 119.591 3.69557 120.281 3.02292C121.776 1.59354 123.709 0.808782 125.734 0.808782C127.947 0.808782 129.629 1.43739 131.363 2.91081L131.506 3.03493L131.949 2.53045L131.801 2.40232C130.956 1.67362 130.071 1.1331 129.182 0.796771C128.166 0.368357 127.038 0.160156 125.734 0.160156C123.458 0.160156 121.369 1.00097 119.846 2.53045C119.005 3.35124 118.363 4.30015 117.997 5.26909C117.554 6.32211 117.339 7.47923 117.339 8.8045C117.339 11.1988 118.272 13.429 119.966 15.0786C121.501 16.568 123.593 17.4248 125.71 17.4248C129.916 17.4248 132.391 14.0416 133.025 11.7714L133.141 11.359H125.467V12.0677H125.475Z"
        fill="#FF0000"
      />
      <path d="M133.157 9.29297H125.476V9.98964H133.157V9.29297Z" fill="#FF0000" />
      <path d="M151.418 9.29297H143.736V9.98964H151.418V9.29297Z" fill="#FF0000" />
      <path
        d="M143.737 12.0677H148.237C147.954 12.7363 147.476 13.3169 146.818 13.7933C146.021 14.3338 145.064 14.6181 144.051 14.6181C143.346 14.6181 142.588 14.4459 141.915 14.1296C141.277 13.8654 140.683 13.445 140.157 12.8925C139.623 12.2759 139.228 11.6673 138.957 11.0387C138.682 10.322 138.542 9.58526 138.542 8.84054C138.542 7.98371 138.682 7.16292 138.957 6.40619C139.248 5.70151 139.651 5.08491 140.153 4.58042C141.114 3.57145 142.465 3.01491 143.96 3.01491C144.885 3.01491 145.634 3.13102 146.324 3.38727C146.926 3.59547 147.559 3.97184 148.313 4.57242L148.456 4.68453L148.899 4.18404L148.747 4.05592C147.284 2.83474 145.857 2.31424 143.964 2.31424C142.309 2.31424 140.775 2.95485 139.643 4.11597C139.049 4.73657 138.622 5.40522 138.343 6.16596C138.036 7.01477 137.881 7.91164 137.881 8.83253C137.881 9.64932 138.056 10.5142 138.367 11.2629C138.626 11.9636 139.049 12.6282 139.69 13.3569C140.252 13.9455 140.918 14.4179 141.672 14.7583C142.405 15.1026 143.21 15.2788 144.059 15.2788C145.287 15.2788 146.387 14.9504 147.241 14.3338C148.05 13.7373 148.648 12.9485 148.975 12.0556H150.501C149.708 14.4179 147.125 16.7802 143.988 16.7802C142.074 16.7802 140.149 15.9714 138.698 14.5621C137.956 13.8614 137.362 13.0246 136.936 12.0757C136.489 10.9746 136.262 9.87354 136.262 8.8045C136.262 7.68743 136.469 6.60638 136.888 5.51333C137.303 4.53238 137.865 3.69557 138.554 3.02292C140.049 1.59354 141.983 0.808782 144.008 0.808782C146.22 0.808782 147.902 1.43739 149.636 2.91081L149.78 3.03493L150.222 2.53045L150.075 2.40232C149.23 1.67362 148.345 1.1331 147.456 0.796771C146.439 0.368357 145.311 0.160156 144.008 0.160156C141.731 0.160156 139.643 1.00097 138.12 2.53045C137.279 3.35124 136.637 4.30015 136.27 5.26909C135.828 6.32211 135.612 7.47923 135.612 8.8045C135.612 11.1988 136.545 13.429 138.239 15.0786C139.774 16.568 141.867 17.4248 143.984 17.4248C148.189 17.4248 150.665 14.0416 151.299 11.7714L151.418 11.359H143.745V12.0677H143.737Z"
        fill="#FF0000"
      />
      <path d="M157.984 0.412109H157.314V17.4405H157.984V0.412109Z" fill="#FF0000" />
      <path d="M155.748 0.412109H155.078V17.4405H155.748V0.412109Z" fill="#FF0000" />
      <path
        d="M175.982 16.051L174.44 13.8889V0.339844H173.77V12.896L164.896 0.419921L164.84 0.339844H163.987L176.09 17.3603L176.146 17.4404H176.676V0.339844H175.982V16.051Z"
        fill="#FF0000"
      />
      <path
        d="M162.178 0.339844H161.672V17.4404H162.342V1.73719L163.884 3.91529V17.4404H164.554V4.86021L173.507 17.4404H174.337L162.234 0.419921L162.178 0.339844Z"
        fill="#FF0000"
      />
      <path
        d="M99.9587 5.58657C99.9587 4.12516 99.4405 2.81189 98.5037 1.89501C98.0532 1.41454 97.4792 1.01816 96.8453 0.749899C96.1916 0.48164 95.486 0.353516 94.6807 0.353516H91.3959V8.01692H89.8333V0.35752H89.1875V8.69357H94.2542C95.3026 8.69357 96.1796 8.36926 96.7975 7.74866C97.3755 7.12005 97.6825 6.27924 97.6825 5.32632C97.6825 4.61363 97.3835 3.91696 96.8573 3.41647C96.1597 2.78787 95.3225 2.48357 94.306 2.48357H92.0497V1.05419H94.6847C95.3664 1.05419 95.9803 1.1623 96.6062 1.39852C97.1244 1.61473 97.6067 1.94705 98.0413 2.38348C98.8625 3.2323 99.3169 4.3734 99.3169 5.59057C99.3169 6.15512 99.1893 6.73168 98.9262 7.35628C98.7269 7.84875 98.408 8.35724 98.0173 8.82169C97.1762 9.66651 96.1079 10.151 95.0873 10.151H89.1915V17.438H89.8373V10.8196H91.3999V17.434H92.0457V10.8196H93.0862L97.8898 17.434H98.7229L93.8954 10.8196H95.0834C95.3704 10.8196 95.6175 10.7956 95.8368 10.7476L100.692 17.434H101.501L96.5145 10.5634C98.5236 9.8627 99.9587 7.81272 99.9587 5.58657ZM94.298 3.18025C95.1352 3.18025 95.8168 3.42048 96.3789 3.91696C96.7855 4.28131 97.0287 4.80982 97.0287 5.32632C97.0287 6.11108 96.7776 6.76771 96.2753 7.2722C95.8208 7.74866 95.0834 8.02492 94.2502 8.02492H92.0417V3.18025H94.298Z"
        fill="#FF0000"
      />
      <path
        d="M221.013 10.5633C223.026 9.8626 224.457 7.81262 224.457 5.59047C224.457 4.12906 223.939 2.8158 223.002 1.89891C222.551 1.41845 221.977 1.02206 221.343 0.753804C220.69 0.485545 219.984 0.357422 219.179 0.357422H215.898V8.02082H214.331V0.357422H213.686V8.69347H218.752C219.801 8.69347 220.678 8.36916 221.296 7.74856C221.874 7.11995 222.177 6.27914 222.177 5.32622C222.177 4.61353 221.878 3.91686 221.351 3.41637C220.654 2.78777 219.817 2.48347 218.8 2.48347H216.544V1.05409H219.179C219.86 1.05409 220.474 1.1622 221.1 1.39843C221.618 1.61464 222.101 1.94696 222.535 2.38338C223.349 3.22019 223.811 4.38931 223.811 5.59047C223.811 6.15502 223.683 6.73158 223.42 7.35618C223.221 7.84866 222.902 8.35714 222.511 8.82159C221.67 9.66641 220.602 10.1509 219.581 10.1509H213.686V17.4379H214.331V10.8195H215.898V17.4339H216.544V10.8195H217.584L222.328 17.3538L222.388 17.4339H223.221L218.393 10.8195H219.581C219.864 10.8195 220.116 10.7955 220.335 10.7475L225.13 17.3538L225.19 17.4339H225.999L221.013 10.5633ZM218.752 8.02082H216.544V3.18015H218.8C219.637 3.18015 220.319 3.42038 220.881 3.91686C221.288 4.28121 221.531 4.80972 221.531 5.32622C221.531 6.11098 221.28 6.76761 220.777 7.2721C220.323 7.74856 219.585 8.02082 218.752 8.02082Z"
        fill="#FF0000"
      />
      <path
        d="M76.6738 3.23515H79.6955V17.4409H80.3413V3.23515H81.7844V17.4409H82.4302V3.23515H85.5714V2.5625H76.6738V3.23515Z"
        fill="#FF0000"
      />
      <path d="M85.5675 0.339844H76.6738V1.01249H85.5675V0.339844Z" fill="#FF0000" />
      <path
        d="M116.709 0.339844L110.614 9.33253L110.582 9.38057V17.4404H111.276V9.6168L117.53 0.339844H116.709Z"
        fill="#FF0000"
      />
      <path
        d="M113.859 0.339844L109.586 6.73001L105.364 0.339844H104.543L109.187 7.3386L108.182 8.87609L102.51 0.339844H101.688L107.847 9.59277V17.4404H108.541V9.59277L114.676 0.339844H113.859Z"
        fill="#FF0000"
      />
      <path
        d="M166.36 28.909H165.882L163.904 23.2676H164.65L166.073 27.3395L166.121 27.5237C166.121 27.5077 166.145 27.4076 166.169 27.3395L167.6 23.2676H168.329L166.36 28.909Z"
        fill="#FF0000"
      />
      <path
        d="M172.411 28.9086L171.733 27.0829H169.939L169.262 28.9086H168.444L170.601 23.2672H171.095L173.264 28.9086H172.411ZM170.876 24.7806C170.852 24.7286 170.828 24.6485 170.828 24.6325L170.788 24.7726L170.202 26.3582H171.458L170.876 24.7806ZM171.187 22.8187H170.449L171.167 21.6816H171.905L171.187 22.8187Z"
        fill="#FF0000"
      />
      <path d="M175.469 23.9924V28.9091H174.7V23.9924H173.552V23.2637H176.661V23.9924H175.469Z" fill="#FF0000" />
      <path
        d="M180.758 28.909L178.486 26.2745V28.909H177.717V23.2676H179.535C180.583 23.2676 181.273 24.0163 181.273 24.9292C181.273 25.8341 180.511 26.5427 179.67 26.5427L181.739 28.909H180.758ZM179.399 23.9923H178.49V25.9342H179.383C180.077 25.9342 180.475 25.5017 180.475 24.8972C180.475 24.5128 180.129 23.9923 179.399 23.9923Z"
        fill="#FF0000"
      />
      <path
        d="M184.633 26.2905V28.909H183.864V26.2905L181.931 23.2676H182.824L184.179 25.3776C184.211 25.4257 184.263 25.5418 184.263 25.5418C184.263 25.5418 184.319 25.4337 184.354 25.3696L185.71 23.2676H186.587L184.633 26.2905Z"
        fill="#FF0000"
      />
      <path
        d="M189.744 29.0015C188.321 29.0015 186.897 27.8324 186.897 26.0867C186.897 24.341 188.205 23.1719 189.752 23.1719C190.661 23.1719 191.282 23.4642 191.821 23.9366L191.35 24.5092C190.864 24.1088 190.39 23.9286 189.736 23.9286C188.604 23.9286 187.695 24.7854 187.695 26.0947C187.695 27.2398 188.628 28.2448 189.764 28.2448C190.573 28.2448 191.219 27.7883 191.418 27.1077H189.724V26.383H192.311C192.311 27.9125 191.095 29.0015 189.744 29.0015Z"
        fill="#FF0000"
      />
      <path
        d="M196.098 29.0015C194.675 29.0015 193.252 27.8324 193.252 26.0867C193.252 24.341 194.56 23.1719 196.106 23.1719C197.015 23.1719 197.637 23.4642 198.175 23.9366L197.705 24.5092C197.218 24.1088 196.744 23.9286 196.09 23.9286C194.958 23.9286 194.049 24.7854 194.049 26.0947C194.049 27.2398 194.982 28.2448 196.118 28.2448C196.927 28.2448 197.573 27.7883 197.773 27.1077H196.078V26.383H198.665C198.669 27.9125 197.454 29.0015 196.098 29.0015Z"
        fill="#FF0000"
      />
      <path d="M201.245 23.9924V28.9091H200.475V23.9924H199.327V23.2637H202.436V23.9924H201.245Z" fill="#FF0000" />
      <path
        d="M208.656 28.909L207.978 27.0833H206.184L205.507 28.909H204.689L206.846 23.2676H207.34L209.509 28.909H208.656ZM207.125 24.781C207.101 24.729 207.077 24.6489 207.077 24.6329L207.037 24.773L206.451 26.3586H207.707L207.125 24.781Z"
        fill="#FF0000"
      />
      <path
        d="M211.295 23.9923V25.794H213.18V26.5187H211.295V28.909H210.525V23.2676H213.296V23.9963H211.295V23.9923Z"
        fill="#FF0000"
      />
      <path d="M218.286 24.3087V28.9091H217.055V24.3087H215.938V23.2637H219.375V24.3087H218.286Z" fill="#FF0000" />
      <path
        d="M224.769 28.909V25.6539C224.769 25.4697 224.785 25.2895 224.793 25.2655C224.785 25.2895 224.737 25.3976 224.693 25.4817L223.437 27.7919H222.967L221.691 25.4817C221.643 25.4056 221.6 25.2975 221.592 25.2655C221.592 25.2895 221.616 25.4657 221.616 25.6539V28.909H220.384V23.2676H221.592L223.106 26.0182C223.158 26.1183 223.206 26.2505 223.214 26.2745C223.214 26.2585 223.258 26.1183 223.314 26.0182L224.745 23.2676H226.001V28.909H224.769Z"
        fill="#FF0000"
      />
    </svg>
  )
}
