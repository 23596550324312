import React from 'react'

import { colors } from '../constants'
export const Completed = () => (
  <svg width={16} height={15} viewBox="0 0 18 17">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={colors.black}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
    >
      <path d="M16.2381 7.80679V8.50429C16.2372 10.1216 15.7051 11.6953 14.7214 12.9907C13.7376 14.286 12.3549 15.2336 10.7793 15.6922C9.20375 16.1508 7.51982 16.0957 5.97864 15.5352C4.43747 14.9747 3.12164 13.9389 2.2274 12.5821C1.33316 11.2253 0.908412 9.62034 1.01652 8.00653C1.12462 6.39271 1.75978 4.85654 2.82726 3.6271C3.89474 2.39766 5.33736 1.54083 6.93995 1.1844C8.54254 0.827975 10.2192 0.991045 11.72 1.64929" />
      <path d="M17.0002 1.75439L8.61921 10.0044L6.3335 7.75439" />
    </g>
  </svg>
)
