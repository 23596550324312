import React from 'react'
import styled from 'styled-components'
import { colors } from '../constants'
import { breakpointDown } from '../helpers/breakpoints'

interface IProps {
  onChange: (value: any) => any
  checked?: boolean
  label: string
  subLabel?: string
  id: string
  groupName: string
}

export const Radio: React.FunctionComponent<IProps> = ({ onChange, checked, label, subLabel, groupName, id }) => (
  <Root>
    <Container htmlFor={id}>
      <RadioInput type="radio" id={id} name={groupName} value={id} checked={checked} onChange={onChange} />

      <Label>
        <LabelHeader>{label}</LabelHeader>
        {subLabel && <span>{subLabel}</span>}
      </Label>
    </Container>
  </Root>
)

const Root = styled.div`
  padding-top: 2rem;
  ${breakpointDown('phone')} {
    display: flex;
    align-items: left;
    width: 100%;
  }
`

const Container = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 1.125rem;
  line-height: 1.5;
  cursor: pointer;

  ${breakpointDown('phone')} {
    display: flex;
    align-items: left;
    width: 100%;
  }
`

const RadioInput = styled.input`
  position: relative;

  appearance: none;

  width: 1.5rem;
  height: 1.5rem;
  margin: 0 2rem 0 0;

  border-radius: 50%;
  box-shadow: inset 0 0 0 0.125rem ${colors.steelGray};

  cursor: pointer;

  transition: all 0.2s ease-out;

  ${breakpointDown('phone')} {
    order: 2;
    align-self: center;
    justify-self: right;

    margin-left: auto;
    margin-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: ${(props) => (props.checked ? '1' : '0')};
    box-shadow: inset 0 0 0 0.375rem ${(props) => props.theme.primaryColor};
    transition: opacity 0.2s ease-out;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 0 0 0.375rem ${(props) => props.theme.secondaryColor};
    transition: opacity 0.2s ease-out;
  }
  &:active {
    transform: scale(0.6);
  }
`

const Label = styled.span`
  display: flex;
  flex-direction: column;
`

const LabelHeader = styled.span`
  font-weight: 500;
`
