import React, { PropsWithChildren } from 'react'
import { ThemeProvider, DefaultTheme } from 'styled-components'
import { useAppState } from './Store'
import { Brand } from './api/types'
import { colors } from './constants'

const toyotaTheme: DefaultTheme = {
  type: Brand.Toyota,
  primaryColor: colors.toyotaRed,
  secondaryColor: colors.redAccent,
  buttonBorderRadius: '6.25rem',
}
const lexusTheme: DefaultTheme = {
  type: Brand.Lexus,
  primaryColor: colors.lexusBlack,
  secondaryColor: colors.blackAccent,
  buttonBorderRadius: '0',
}

export const Theme = ({ children }: PropsWithChildren<{}>) => {
  const { state } = useAppState()
  return <ThemeProvider theme={state.brandType === Brand.Lexus ? lexusTheme : toyotaTheme}>{children}</ThemeProvider>
}
