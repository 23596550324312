import React from 'react'
import styled from 'styled-components'

import { colors } from '../constants'

interface IProps {
  url: string
  text: string
}

const Link = ({ url, text }: IProps) => (
  <Root href={url} target="_blank">
    {text}
  </Root>
)

const Root = styled.a`
  font-weight: inherit;
  font-size: inherit;
  color: ${colors.black};
  transition: all 0.3s ease-out;
  background: linear-gradient(to right, ${colors.silverGray} 50%, transparent 50%);
  background-size: 200% 34%;
  background-repeat: no-repeat;
  text-decoration: none;
  background-position: right bottom;
  border-bottom: 0.125rem solid ${colors.black};

  &:hover {
    color: ${colors.black};
    background-position: left bottom;
  }

  &:focus {
    outline: none;
    background-size: 200% 100%;
    background-position: left bottom;
  }
`

export default Link
