import React from 'react'

import styled from 'styled-components'
import { SupportedModels } from '../../api/types'
import { breakpointUp, breakpointDown } from '../../helpers/breakpoints'

import toyotaImage from '../../assets/toyota-card-image.jpg'
import lexusImage from '../../assets/lexus-header-image.jpg'

interface Props {
  model: SupportedModels
  reverse?: boolean
}

const toyotaCardImage = `
  background: url(${toyotaImage});
`
const lexusCardImage = `
  background: url(${lexusImage});
`
export const CardImage: React.FC<Props> = ({ reverse, model }: Props) => (
  <Image reverse={reverse || false} model={model} role="img" aria-label={'Mynd'} />
)

const Image = styled.div<{
  reverse: boolean
  model?: SupportedModels
}>`
  ${(props) => (props.model === SupportedModels.Toyota ? toyotaCardImage : lexusCardImage)}

  background-size: cover;
  background-position: center center;
  width: 100%;
  height: calc(80vh - 6.5rem);
  transition: all 0.5s ease-out 0.2s;
  opacity: 1;

  ${breakpointDown('tablet')} {
    transform: translateY(6.25rem);
  }

  ${breakpointUp('tablet')} {
    display: block;
    background-position: inherit;
    margin-right: 0;
    ${(props) => props.reverse && 'margin-left: -4rem;'}
    max-width: 44rem;
    height: 50rem;
  }
`
