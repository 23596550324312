import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../api/api.tm'
import { AllModels, SupportedModels, OtherModels, VehicleInfo } from '../api/types'
import Button from '../components/Button'
import { InputInfoBox } from '../components/InputInfoBox'
import { PriceFooter } from '../components/PriceFooter'
import { capitalize } from '../helpers/capitalize'
import { getModelType } from '../helpers/getModelType'
import { useAppState, addPolicy, addVehicleModel } from '../Store'
import { Spacer } from '../components/Spacer'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useExceptionHandler } from '../hooks/useExceptionHandler'
import { useTitle } from '../hooks/useTitle'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'
import { LogOutButton } from '../components/LogOutButton'

interface Props {
  isAdmin: boolean
}

const LicencePlate: React.FC<Props> = ({ isAdmin }: Props) => {
  const { quoteId } = useParams()
  const [plateNumber, setPlateNumber] = useState<string>('')
  const [errorText, setErrorText] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [vehicleInfo, setVehicleInfo] = useState<(VehicleInfo & { modelType: AllModels }) | undefined>(undefined)
  const { routeTo } = useRouteTransition()
  const { dispatch } = useAppState()
  const { state } = useAppState()
  const { handleException } = useExceptionHandler()
  const { setTitle } = useTitle()

  useEffect(() => {
    let isSubscribed = true
    setVehicleInfo(undefined)

    if (plateNumber.length === 5) {
      setLoading(true)
      api
        .getVehicleInfo(plateNumber)
        .then((vehicle) => {
          if (!isSubscribed || !vehicle || !vehicle.model) {
            return
          }
          const modelType = getModelType(vehicle.model)
          if (modelType !== OtherModels.UnsupportedModels) {
            setVehicleInfo({ ...vehicle, modelType })
          } else {
            setErrorText('Ekki er hægt að tryggja þessa tegund ökutækis')
          }
        })
        .catch((error: any) => {
          handleException({
            error,
            dispatchErrorNotification: false,
          })
          console.error('Error when getting vehicle info')
        })
        .then(() => {
          if (isSubscribed) setLoading(false)
        })
    }

    return () => {
      isSubscribed = false
    }
  }, [plateNumber, handleException])

  useEffect(() => {
    if (
      vehicleInfo &&
      (vehicleInfo.modelType === SupportedModels.Toyota || vehicleInfo.modelType === SupportedModels.Lexus)
    ) {
      dispatch(
        addVehicleModel({
          model: vehicleInfo.modelType,
          licencePlate: plateNumber,
        })
      )
    }
  }, [vehicleInfo, plateNumber, dispatch])

  useEffect(() => {
    setTitle('Ökutækjaupplýsingar')
  })

  const addVehicle = async () => {
    if (quoteId && vehicleInfo && vehicleInfo.modelType !== OtherModels.UnsupportedModels) {
      setLoading(true)
      try {
        if (!state.quote) {
          throw new Error('Kennitala fannst ekki')
        }
        const policy = await api.addVehicle(quoteId, state.quote.kennitala, plateNumber, vehicleInfo.modelType)
        dispatch(addPolicy(policy))
        setLoading(false)
        isAdmin
          ? routeTo(`/admin/${quoteId}/yfirlit/${policy.number}`)
          : routeTo(`/${quoteId}/bill-kynning/${policy.number}`)
      } catch (error: any) {
        if (error.message === 'subjectNotFound') {
          setErrorText('Þetta bílnúmer fannst ekki')
        } else if (error.message === 'subjectAlreadyInPackage') {
          setErrorText('Ekki er hægt að bæta við sama bíl aftur')
        } else if (error.message === 'subjectNotSupported') {
          setErrorText(
            'Til að tryggja þessa tegund ökutækis, vinsamlegast hafðu samband í síma 515-2000 eða á tm@tm.is'
          )
        } else if (error.message === 'subjectInDebt') {
          setErrorText(
            'Ekki er hægt að birta verð fyrir þennan bíl. Vinsamlegast hafðu samband í síma 515-2000 eða á tm@tm.is'
          )
        } else if (error.message === 'subjectAlreadyInsured') {
          setErrorText('Þessi bíll er nú þegar tryggður með Toyota tryggingu')
        } else {
          setErrorText('Úps, eitthvað fór úrskeiðis')
          handleException({
            error: error,
            dispatchErrorNotification: false,
          })
        }
        setLoading(false)
      }
    }
  }

  const handleInputChange = async (value: string) => {
    const licencePlate = value.toUpperCase().replace(/\s+|-/g, '')
    setPlateNumber(licencePlate)
    setErrorText(undefined)
  }

  return (
    <>
      <ChatSection>
        <ChatSectionTitle text="Hvert er númerið á bílnum sem þú vilt tryggja?" />
        <Spacer size="2.75rem" />
        <InputInfoBox
          handleInputChange={handleInputChange}
          showInfo={Boolean(vehicleInfo)}
          infoText={
            vehicleInfo ? (
              <>
                <span style={{ fontWeight: 500 }}>{capitalize(vehicleInfo.model)}</span> árgerð {vehicleInfo.year}
              </>
            ) : (
              ''
            )
          }
          onClose={() => {
            setVehicleInfo(undefined)
          }}
          inputValue={plateNumber.toUpperCase() || ''}
          inputLabel="Bílnúmer"
          error={errorText}
          onReturn={addVehicle}
        />
        <Button onClick={addVehicle} disabled={!vehicleInfo || Boolean(errorText) || loading} loading={loading}>
          Halda áfram
        </Button>
        {state.person && <LogOutButton invisible />}
      </ChatSection>
      <PriceFooter />
    </>
  )
}
export default LicencePlate
