import { Brand } from '../api/types'

export const getBrandTypeFromUrl = () => {
  if (window.location.href.toLocaleLowerCase().indexOf('toyota') > -1) {
    return Brand.Toyota
  } else if (window.location.href.toLocaleLowerCase().indexOf('lexus') >= 0) {
    return Brand.Lexus
  } else {
    return Brand.Unknown
  }
}
