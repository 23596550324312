import React, { useCallback, useState, useEffect } from 'react'
import { api, InsuranceProductNumbers, InsuranceProductVariants } from '../api/api.tm'
import { Quote, OtherModels } from '../api/types'
import Button from '../components/Button'
import { InputInfoBox } from '../components/InputInfoBox'
import { Title } from '../components/Title'
import { useAppState, setPhoneNumber, setPerson, setQuote, addVehicleModel } from '../Store'
import { useDispatchErrorNotification } from '../hooks/useDispatchErrorNotification'
import styled from 'styled-components'
import { propBetween } from '../helpers/between'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useParams } from 'react-router-dom'
import { logError } from '../helpers/logError'
import { useTitle } from '../hooks/useTitle'
import { getModelType } from '../helpers/getModelType'
import { ChatSection } from '../components/ChatSection'
interface Props {
  phoneNumber: string
  loading: boolean
  title: string
  pageHeader: string
  error?: string
  handleLogin(phoneNumber: string): Promise<void>
  handleChangePhoneNumber(phoneNumber: string): void
}
const AuthenticateComponent = ({
  phoneNumber,
  loading,
  error,
  title,
  pageHeader,
  handleLogin,
  handleChangePhoneNumber,
}: Props) => {
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])
  return (
    <ChatSection>
      <Title>
        <SmallTitle>{pageHeader}</SmallTitle>
      </Title>
      <InputInfoBox
        handleInputChange={handleChangePhoneNumber}
        showInfo={loading}
        infoText={
          <>
            Auðkenningarbeiðni hefur verið send í símanúmer <span style={{ fontWeight: 500 }}>{phoneNumber}</span>
          </>
        }
        inputValue={phoneNumber}
        inputLabel="Símanúmer"
        isNumericKeyBoard
        error={error}
        onReturn={() => handleLogin(phoneNumber)}
      />
      <Button onClick={() => handleLogin(phoneNumber)} disabled={!phoneNumber} loading={loading}>
        Halda áfram
      </Button>
    </ChatSection>
  )
}

const Authenticate = ({ title, pageHeader }: { title: string; pageHeader: string }) => {
  const { state, dispatch } = useAppState()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const dispatchErrorNotification = useDispatchErrorNotification()
  const { routeTo } = useRouteTransition()
  const { quoteId } = useParams()
  const props: Props = {
    loading,
    error,
    title,
    pageHeader,
    phoneNumber: state.phoneNumber || '',
    handleChangePhoneNumber: useCallback(
      (phoneNumber: string) => {
        dispatch(setPhoneNumber(phoneNumber))
      },
      [dispatch]
    ),
    handleLogin: useCallback(
      async (phoneNumber: string) => {
        try {
          setLoading(true)
          const person = await api.loginRS(phoneNumber)
          dispatch(setPerson(person))
          let quote: Quote | undefined
          if (quoteId) {
            quote = await api.getQuote(quoteId)
          }
          if (quote) {
            if (new Date(quote.editableUntilDate) > new Date()) {
              dispatch(setQuote(quote))
              routeTo(`/${quote.number}/yfirlit`)
            } else {
              routeTo(`/${quote.number}/tilbod-utrunnid`)
            }
          } else {
            quote = await api.getQuoteInProgress()
            if (
              quote &&
              quote.policies.length > 0 &&
              quote.createdByCurrentUser &&
              new Date(quote.editableUntilDate) > new Date() &&
              quote.policies.every(
                (p) =>
                  p.product.number === InsuranceProductNumbers.Car &&
                  (p.product.variant === InsuranceProductVariants.Lexus ||
                    p.product.variant === InsuranceProductVariants.Toyota)
              )
            ) {
              quote.policies.forEach((p) =>
                api.getVehicleInfo(p.subjects[0].reference).then((vi) => {
                  const modelType = getModelType(vi.model)
                  if (modelType !== OtherModels.UnsupportedModels) {
                    dispatch(
                      addVehicleModel({
                        model: modelType,
                        licencePlate: p.subjects[0].reference,
                      })
                    )
                  }
                })
              )
              dispatch(setQuote(quote))
              dispatch(setPhoneNumber(phoneNumber))
              routeTo(`/taka-upp-thradinn/${quote.number}`)
            } else {
              const quote = await api.createQuote(person.kennitala)
              dispatch(setQuote(quote))
              dispatch(setPhoneNumber(phoneNumber))
              routeTo(`/${quote.number}/sla-inn-bilnumer`)
            }
          }
        } catch (err: any) {
          if (err.status === 404) {
            dispatchErrorNotification('Ekkert tilboð fannst á þessu númeri')
            return
          }
          if (err.status !== 504 && err.status !== 401) {
            console.warn('Failed to authenticate', phoneNumber, err)
            dispatchErrorNotification('Það lítur út fyrir að það séu einhver tæknileg vandræði, prófaðu aftur seinna.')
            logError(err)
          }
          let errorMessage = ''

          switch (err.code) {
            case 'INVALID_INPUT':
            case 'NOT_FOUND':
            case 'NOT_FOUND_INTERNAL':
            case 'TRANSACTION_TIMEOUT':
            case 'TIMEOUT_ERROR':
            case 'BUSY':
            case 'CANCEL':
              errorMessage = err.message
              break
            default:
              errorMessage = 'Auðkenning tókst ekki. Vinsamlegast reynið aftur síðar'
          }
          setError(errorMessage)
        } finally {
          setLoading(false)
        }
      },
      [quoteId, dispatch, routeTo, dispatchErrorNotification]
    ),
  }

  return <AuthenticateComponent {...props} />
}
const AuthenticateRS = () => (
  <Authenticate
    pageHeader="Vinsamlega skráðu þig inn með rafrænum skilríkjum til að fá nákvæmt verð á trygginguna. "
    title="Auðkenning"
  />
)
const ReAuthenticateRS = () => (
  <Authenticate
    pageHeader="Svo virðist sem auðkenningin þín sé útrunnin. Vinsamlega skráðu þig aftur inn með rafrænum skilríkjum."
    title="Auðkenning útrunnin"
  />
)

export { AuthenticateRS, ReAuthenticateRS }

export const SmallTitle = styled.span`
  ${propBetween('font-size', 1.5, 2.1875)};
`
