import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

import { takeWhile, tap } from 'rxjs/operators'

import { CancellationFile, SignCancellationStatus } from '../../api/types'
import Button from '../../components/Button'
import { Loading } from '../../components/Loading'
import { colors } from '../../constants'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { useAppState } from '../../Store'
import { useTitle } from '../../hooks/useTitle'
import { useExceptionHandler } from '../../hooks/useExceptionHandler'
import { api } from '../../api/api.tm'
import { listenToMobileEvents } from './CancellationEvents'

interface Props {
  phoneNumber: string
  isSignaturePending: boolean
  cancellations: Array<CancellationFile>
  cancellationIndex: number
  handleSignClick(id: string): Promise<void>
  signatureControlCode?: string
  error?: string
}

const CancellationComponent: React.FunctionComponent<Props> = (props: Props) => {
  const {
    cancellations,
    cancellationIndex,
    isSignaturePending,
    phoneNumber,
    handleSignClick,
    signatureControlCode,
    error,
  } = props

  const cancellation = cancellations[cancellationIndex]
  const pdfUrl = cancellation ? cancellation.url : ''

  return (
    <Root>
      <Title>Rafræn uppsögn</Title>

      <Subtitle>
        Að lokum þarftu að segja upp gömlu tryggingunum þínum svo að nýju tryggingarnar geti tekið gildi. Það eina sem
        þú þarft að gera er að undirrita þetta skjal um uppsögn rafrænt og við sjáum um restina.
      </Subtitle>

      {cancellations.length > 1 && (
        <FileCountText>
          Uppsögn {cancellationIndex + 1} af {cancellations.length}
        </FileCountText>
      )}

      <Content>
        <PdfBox>
          <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
            <iframe src={pdfUrl} width="100%" height="100%" title="pdf-iframe">
              <p>Það lítur út fyrir að vafrinn þinn birti ekki PDF skjöl</p>
            </iframe>
          </object>
        </PdfBox>

        <ButtonBox>
          <Button onClick={() => handleSignClick(cancellation.id)} loading={isSignaturePending} disabled={!pdfUrl}>
            Undirrita með rafrænu skilríki
          </Button>

          {!error && (
            <ButtonText>
              {isSignaturePending
                ? `Ósk um undirritun send í síma ${phoneNumber}… Öryggiskóði: ${
                    signatureControlCode ? `${signatureControlCode} (þetta er ekki pin-númerið)` : '••••'
                  }`
                : `Ósk um undirritun verður send í síma ${phoneNumber}`}
            </ButtonText>
          )}

          {error && <ButtonErrorText>{error}</ButtonErrorText>}
        </ButtonBox>
      </Content>
    </Root>
  )
}

export const Cancellation = () => {
  const { state } = useAppState()
  const { routeTo } = useRouteTransition()
  const [isSignaturePending, setIsSignaturePending] = useState<boolean>(false)
  const [signatureControlCode, setSignatureControlCode] = useState<string | undefined>(undefined)
  const [cancellationIndex, setCancellationIndex] = useState<number>(0)
  const { setTitle } = useTitle()
  const { handleException } = useExceptionHandler()

  useEffect(() => {
    setTitle('Uppsögn fyrri tryggingar')
  })

  if (!state.quote || !state.cancellations || !state.person) {
    return <Loading />
  } else {
    const phoneNumber = state.phoneNumber || state.person.phone.eIdMobile || state.person.phone.mobile || ''
    const cancellations = state.cancellations

    const getSignatureErrorMessage = (msg: string) => {
      switch (msg) {
        case SignCancellationStatus.UserCanceled:
          return 'Hætt var við auðkenningu.'
        case SignCancellationStatus.Timeout:
          return 'Rann út á tíma.'
        default:
          return 'Því miður fór eitthvað úrskeiðis. Vinsamlegast reyndu aftur hér eða í appinu.'
      }
    }

    const handleSignCancellation = async (phoneNumber: string, id: string, shouldContinue: boolean) => {
      setIsSignaturePending(true)
      const signingId = await api.signCancellation(id, phoneNumber)

      const mobileSignEvents$ = listenToMobileEvents(signingId)

      mobileSignEvents$
        .pipe(
          tap((event: any) => {
            if (event.type === 'MobileSignStarted') {
              setSignatureControlCode(event.controlCode)
              return
            }

            if (event.type === 'MobileSignSuccess') {
              setIsSignaturePending(false)
              setSignatureControlCode(undefined)
              return
            }

            if (event.type === 'MobileSignFailed') {
              throw new Error(event.status)
            }

            if (event.type === 'MobileSignComplete' && shouldContinue && state.quote) {
              routeTo(`/${state.quote.number}/takk-fyrir`)
            } else if (event.type === 'MobileSignComplete') {
              setCancellationIndex(cancellationIndex + 1)
            }
          }),
          takeWhile((event) => event && event.type !== 'MobileSignComplete' && event.type !== 'MobileSignFailed')
        )
        .subscribe({
          error: (err: any) => {
            console.warn('Failed to cancel electronically', err)
            handleException({
              error: {
                err,
                message: getSignatureErrorMessage(err.message),
              },
              dispatchErrorNotification: true,
            })
          },
        })
    }

    const handleSignClick = async (id: string) => {
      const shouldContinue = cancellationIndex === cancellations.length - 1
      handleSignCancellation(phoneNumber, id, shouldContinue)
    }

    const props: Props = {
      cancellations,
      cancellationIndex,
      isSignaturePending,
      handleSignClick,
      signatureControlCode,
      error: state.error && state.error.message,
      phoneNumber,
    }
    return <CancellationComponent {...props} />
  }
}

const Root = styled.div`
  max-width: 142.5rem;
  padding: 1.5rem;

  @media screen and (min-width: 900px) {
    padding: 0 4.5rem;
    margin: 0 auto;
    margin-top: 7.5rem;
  }
`

const Title = styled.h1`
  max-width: 300px;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 900;
  text-transform: uppercase;
  color: ${colors.black};

  @media screen and (min-width: 500px) {
    font-size: 4rem;
  }
`

const Subtitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 0 1.5rem 4rem;
  max-width: 700px;

  @media screen and (min-width: 500px) {
    font-size: 1.6875rem;
  }

  @media screen and (min-width: 900px) {
    margin-bottom: 6.5rem;
  }
`

const FileCountText = styled.h2`
  padding: 0 1.5rem;

  @media screen and (min-width: 900px) {
    font-size: 2.5rem;
    line-height: 3rem;

    margin: 0 6.5rem 2.5rem;
  }
`

const Content = styled.div`
  @media screen and (min-width: 900px) {
    display: flex;
    flex-direction: row;
  }
`

const PdfBox = styled.div`
  height: calc((100vw - 2rem) * 1.414 + 2rem); /* A4 paper ratio */
  background-color: ${colors.white};

  margin-bottom: 2rem;
  border: 0.25rem solid ${colors.lightGray};

  @media screen and (min-width: 900px) {
    width: 50vw;
    height: calc((50vw - 2rem) * 1.414 + 2rem); /*  A4 paper ratio */
    margin-bottom: 5.5rem;
  }

  @media screen and (min-width: 1680px) {
    width: 45vw;
    height: calc((45vw - 2rem) * 1.414 + 2rem); /*  A4 paper ratio */
  }
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    margin: 0 2rem;
    width: 45vw;
  }
`

const ButtonText = styled.p`
  font-size: 0.875rem;
  color: ${colors.steelGray};
  text-align: center;
`

const ButtonErrorText = styled.p`
  font-size: 0.875rem;
  color: ${colors.errorRed};
  text-align: center;
`
