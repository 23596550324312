import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { colors } from '../constants'

interface Props {
  align?: 'left' | 'center' | 'right'
}

export const Title = ({ align = 'center', children }: PropsWithChildren<Props>) => <Root align={align}>{children}</Root>

const Root = styled.h1<{ align?: string }>`
  text-align: ${(props) => props.align || 'center'};
  color: ${colors.black};
`
