import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { api } from '../api/api.tm'
import Button from '../components/Button'
import { InputInfoBox } from '../components/InputInfoBox'
import { useAppState, setQuote } from '../Store'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useExceptionHandler } from '../hooks/useExceptionHandler'
import { useTitle } from '../hooks/useTitle'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'
enum LoadingState {
  NotSet,
  Loading,
  Loaded,
}
const PersonDetails = () => {
  const [kennitala, setKennitala] = useState<string>('')
  const [name, setName] = useState<{
    value: string | undefined
    loadingState: LoadingState
  }>({ value: '', loadingState: LoadingState.NotSet })
  const [loading, setLoading] = useState<boolean>(false)
  const { handleException } = useExceptionHandler()
  const { dispatch } = useAppState()

  const { routeTo } = useRouteTransition()
  const { setTitle } = useTitle()

  const goToNextPage = async () => {
    try {
      if (!name.value) {
        return
      }
      setLoading(true)
      const quote = await api.createQuote(kennitala)
      dispatch(setQuote(quote))
      setLoading(false)
      routeTo(`/admin/${quote.number}/okutaeki`)
    } catch (error: any) {
      setLoading(false)
      handleException({
        error,
        dispatchErrorNotification: false,
      })
    }
  }

  const fetchCustomerName = useCallback(async () => {
    try {
      setName({ value: '', loadingState: LoadingState.Loading })
      const res = await api.getCustomerNameByKennitala(kennitala)
      if (res != null) {
        setName({ value: res, loadingState: LoadingState.Loaded })
      }
    } catch (error: any) {
      handleException({
        error,
        dispatchErrorNotification: true,
      })
    }
  }, [kennitala, handleException])

  useEffect(() => {
    if (kennitala.length === 10) {
      fetchCustomerName()
    } else {
      setName({ value: undefined, loadingState: LoadingState.NotSet })
    }
  }, [kennitala, fetchCustomerName])

  useEffect(() => {
    setTitle('Persónupplýsingar')
  })
  return (
    <ChatSection>
      <ChatSectionTitle text="Sláðu inn kennitölu viðskiptavinar" />
      <Root>
        <InputSpan>
          <InputInfoBox
            handleInputChange={setKennitala}
            showInfo={name.loadingState !== LoadingState.NotSet}
            infoText={name.value || 'Hleð inn nafni'}
            onClose={() => {
              setName({ value: undefined, loadingState: LoadingState.NotSet })
            }}
            inputValue={kennitala || ''}
            inputLabel="Kennitala"
            isNumericKeyBoard
            onReturn={goToNextPage}
          />
        </InputSpan>
      </Root>
      <Button
        onClick={goToNextPage}
        disabled={kennitala.length !== 10 || name.loadingState !== LoadingState.Loaded || loading}
        loading={loading}
      >
        Halda áfram
      </Button>
    </ChatSection>
  )
}

export default PersonDetails

const Root = styled('span')`
  display: flex;
  flex-direction: row;
  margin-top: 2.75rem;
`

const InputSpan = styled('span')`
  width: 20rem;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  margin: 0 1.25em;
`
