import React from 'react'
import styled from 'styled-components'

import { breakpointUp } from '../../../helpers/breakpoints'
import { zIndex } from '../../../helpers/z-index'

interface IProps {
  children: any
}

export const CoverageCards = ({ children }: IProps) => <StyledCoverageCards>{children}</StyledCoverageCards>

const StyledCoverageCards = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 3.75rem;
  max-width: 100%;
  padding-bottom: 2rem;
  z-index: ${zIndex.overlay};

  ${breakpointUp('tablet')} {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 5rem -2.5rem 0 0;
    padding-bottom: 5.5rem;
  }
`
