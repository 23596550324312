import styled from 'styled-components'
import React, { PropsWithRef, Ref } from 'react'
import { colors } from '../constants'

interface Props {
  id: string
  label: string
  checked: boolean
  onChange: (checked: boolean) => any
  ref?: Ref<HTMLInputElement>
}

export const Checkbox = ({ ref, checked, id, label, onChange }: PropsWithRef<Props>) => {
  return (
    <Root>
      <CheckboxInput
        ref={ref}
        type="checkbox"
        id={id}
        checked={checked}
        name={id}
        onChange={(event) => onChange(event.target.checked)}
      />
      <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  margin-top: 2.375rem;

  animation: animIn 0.3s ease-out;
`

const CheckboxInput = styled.input`
  -webkit-appearance: none;
  background-color: ${colors.white};
  position: relative;

  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto 0;

  border: 0.125rem solid ${colors.silverGray};
  border-radius: 0.125rem;

  transition: all 0.1s ease-out;

  &:checked {
    border: 0.125rem solid ${(props) => props.theme.primaryColor};

    &:after {
      content: ' ';
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      right: 0.125rem;
      bottom: 0.125rem;
      border-radius: 0.0625rem;

      background-color: ${(props) => props.theme.primaryColor};
    }
  }
`

const CheckboxLabel = styled.label`
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin-left: 1.5rem;
`
