import React, { useCallback, useState, useEffect } from 'react'

import { api, HttpError } from '../api/api.tm'
import { Spacer } from '../components/Spacer'
import Button from '../components/Button'
import Content from '../components/Content'
import { Input } from '../components/Input'
import { Title } from '../components/Title'
import { useAppState, setPerson } from '../Store'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { logError } from '../helpers/logError'
import { useTitle } from '../hooks/useTitle'

interface Props {
  username: string
  password: string
  error: string
  loading: boolean
  title: string
  pageHeader: string
  handleLogin(username: string, password: string): Promise<void>
  handleChangeUsername(username: string): void
  handleChangePassword(password: string): void
}
const AuthenticateComponent = ({
  username,
  password,
  error,
  loading,
  title,
  pageHeader,
  handleLogin,
  handleChangeUsername,
  handleChangePassword,
}: Props) => {
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])
  return (
    <Content>
      <Title>{pageHeader}</Title>
      <Spacer size="2rem" />
      <Input
        onChange={handleChangeUsername}
        disabled={loading}
        value={username}
        label="Notandanafn"
        onReturn={() => username && password && handleLogin(username, password)}
      />
      <Spacer size="2rem" />
      <Input
        type="password"
        onChange={handleChangePassword}
        disabled={loading}
        value={password}
        label="Lykilorð"
        error={error}
        onReturn={() => username && password && handleLogin(username, password)}
      />
      <Spacer size="2rem" />

      <Button onClick={() => handleLogin(username, password)} disabled={!username || !password} loading={loading}>
        Skrá inn
      </Button>
    </Content>
  )
}

const Authenticate = ({ title, pageHeader }: { title: string; pageHeader: string }) => {
  const { dispatch } = useAppState()
  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { routeTo } = useRouteTransition()
  const [error, setError] = useState<string>('')

  const props: Props = {
    username,
    password,
    loading,
    error,
    title,
    pageHeader,
    handleChangePassword: setPassword,
    handleChangeUsername: setUsername,
    handleLogin: useCallback(
      async (user: string, passw: string) => {
        setLoading(true)
        setError('')
        try {
          const person = await api.loginUserPass(user, passw)
          dispatch(setPerson(person))
          routeTo(`/admin/kaupandi`)
        } catch (err: any) {
          if (err instanceof HttpError && err.status === 401) {
            setError('Notendanafn og lykilorð passa ekki saman')
          } else {
            logError(err)
          }
        } finally {
          setLoading(false)
        }
      },
      [dispatch, routeTo]
    ),
  }

  return <AuthenticateComponent {...props} />
}
const AuthenticateUserPass = () => <Authenticate title="Auðkenning" pageHeader="Skráðu þig inn" />
const ReAuthenticateUserPass = () => (
  <Authenticate
    pageHeader="Svo virðist sem auðkenningin þín sé útrunnin. Vinsamlega skráðu þig aftur inn."
    title="Auðkenning útrunnin"
  />
)
export { AuthenticateUserPass, ReAuthenticateUserPass }
