import gql from 'gql-tag'
import { GraphQLClient } from 'graphql-request'

import { isProd } from '../helpers/env'

import {
  Api,
  Person,
  Policy,
  PolicyResult,
  SubjectInput,
  SupportedModels,
  CustomerSettings,
  CheckoutArguments,
  CheckoutResult,
  Cancellation,
  StartSigningCancellationResult,
  SendSignedCancellationResult,
  Quote,
} from './types'
export class HttpError extends Error {
  status: number
  code?: string
  constructor(m: string, status: number, code?: string) {
    super(m)
    this.status = status
    this.code = code
  }
}

// Convenience client against a local api-service. Api-service should be started with `npm run start-http`
// const host = `http://local.it.tmdev.is:8019`

const host = `https://api.tm${isProd ? '' : 'dev'}.is`
const papiHost = `https://papi.tm${isProd ? '' : 'dev'}.is`

const customerClient = new GraphQLClient(`${host}/customer`, {
  credentials: 'include',
})

const policyHubClient = new GraphQLClient(`${host}/policyhub`, {
  credentials: 'include',
})

const pushNotificationClient = new GraphQLClient(`${papiHost}/server-events/graphql`, {
  credentials: 'include',
})

export const serverEventsUrl = `https://papi${isProd ? '' : '.test'}.tm.is/server-events/stream`

const cmClient = new GraphQLClient(`${host}/cm`, {
  credentials: 'include',
})

export enum InsuranceProductNumbers {
  Car = '011',
}

export enum InsuranceProductVariants {
  Toyota = 'OkuToyota',
  Lexus = 'OkuLexus',
}

const defaultValues = {
  selfRisk: '135000',
  kaskoValue: '5000000',
}

const AGENCY_VALUE_TOYOTA = '8070'
const AGENCY = process.env.AGENCY || AGENCY_VALUE_TOYOTA

const toJson = (res: Response) => {
  if (res.status === 200) {
    return res.json()
  } else {
    return res
      .text()
      .catch(() => {
        throw new HttpError('', res.status)
      })
      .then((err: any) => {
        let msg = ''
        let code = ''
        try {
          const errorResponse = JSON.parse(err)
          msg = errorResponse.message
          code = errorResponse.code
        } catch (e) {
          msg = String(err)
        }
        throw new HttpError(msg, res.status, code)
      })
  }
}

const getSiteStatus = async () => {
  return fetch(`${host}/api/v1/closed`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(toJson)
}

const getPolicyOverview = async () => {
  const query = gql`
    {
      policyOverview(includePending: true) {
        number
        subject {
          reference
          description
        }
      }
    }
  `
  const { policyOverview } = await policyHubClient.request<any>(query)
  return policyOverview
}

const getCustomer = async () => {
  const query = gql`
    {
      customer {
        name
        kennitala
        age
        isCustomer
        gender
        settings {
          paperLess
          wantsMarketing
        }
        email {
          personal
        }
        phone {
          mobile
          eIdMobile
        }
      }
    }
  `
  const { customer } = await customerClient.request<any>(query)
  return customer
}

const sendSMS = async (phoneNumber: string, message: string) => {
  const query = gql`
    mutation SendToyotaSMS($phoneNumber: String!, $message: String!) {
      result: SendToyotaSMS(phoneNumber: $phoneNumber, data: $message)
    }
  `

  const { result } = await pushNotificationClient.request<{ result: boolean }>(query, { phoneNumber, message })

  return result
}

const getCustomerNameByKennitala = async (kennitala: string) => {
  const query = gql`
    query ($kennitala: String) {
      customer(kennitala: $kennitala) {
        name
      }
    }
  `
  const { customer } = await customerClient.request<any>(query, { kennitala })
  return customer && customer.name
}

const updateCustomerSettings = async (settings: CustomerSettings): Promise<CustomerSettings> => {
  const query = gql`
    mutation UpdateSettings($settings: SettingsIn!) {
      updateSettings(settings: $settings) {
        settings {
          wantsMarketing
          paperLess
        }
      }
    }
  `

  const { updateSettings } = await customerClient.request<any>(query, {
    settings,
  })
  return updateSettings.settings
}

const policyInfo = gql`
  fragment PolicyInfo on Policy {
    number
    product {
      number
      name
      variant
    }
    policyPeriod {
      start
      end
      price {
        total
      }
    }
    terms {
      name
      url
    }
    subjects {
      __typename
      reference
      description
      ... on VehicleSubject {
        modelYear
      }
      properties {
        ...Prop
      }
      coverage {
        typeId
        name
        covered
        notCovered
        selected
        optional
        properties {
          ...Prop
        }
        entryPriceTotal {
          total
        }
      }
    }
  }
  fragment PropBase on Property {
    __typename
    id
    name
    ... on OptionsProperty {
      selection {
        id
        value
      }
      options {
        id
        value
      }
    }
    ... on EnteredNumberPropertyType {
      value
      unit
      valueOptions
      message {
        type
        value
      }
    }
    ... on StaticNumberPropertyType {
      value
      unit
    }
    ... on StaticTextPropertyType {
      text
    }
  }
  fragment Prop on Property {
    ...PropBase
    ... on PropertyGroupType {
      text
      properties {
        ...PropBase
      }
    }
  }
`

const checkout = async ({ quoteId, method, email, paperLess }: CheckoutArguments) => {
  const mutationString = gql`
    mutation Checkout(
      $quoteNumber: String!
      $numberOfPaymentsPerYear: Int
      $creditCard: CreditCard
      $email: String!
      $paperLess: Boolean!
    ) {
      result: checkout(
        quoteNumber: $quoteNumber
        numberOfPaymentsPerYear: $numberOfPaymentsPerYear
        creditCard: $creditCard
        email: $email
        paperless: $paperLess
      ) {
        success
        messages {
          type
          value
          code
        }
        cancellations {
          id
          signed
        }
        package {
          number
        }
      }
    }
  `
  const res = await policyHubClient.request<{ result: CheckoutResult }>(mutationString, {
    quoteNumber: quoteId,
    numberOfPaymentsPerYear: method.type !== 'useExisting' ? (method.schedule === 'yearly' ? 1 : 12) : undefined,
    email,
    paperLess,
    creditCard:
      method.type === 'creditCard'
        ? {
            number: method.cardNumber,
            validTo: method.validTo,
          }
        : undefined,
  })

  return {
    ...res.result,
    cancellations: res.result.cancellations.map((cancellation) => ({
      ...cancellation,
      url: `${host}/api/v1/file/${cancellation.id}?origin=${window.location.origin}`,
    })),
  }
}

const getQuote = async (quoteId: string) => {
  const query = gql`
    query ($packageNumber: String!) {
      pack: packageByNumber(packageNumber: $packageNumber) {
        number
        kennitala
        status {
          type
        }
        policies {
          ...PolicyInfo
        }
        createdByCurrentUser
        editableUntilDate
      }
    }
    ${policyInfo}
  `
  const res = await policyHubClient.request<any>(query, {
    packageNumber: quoteId,
  })
  return res.pack
}

const addVehicle = async (
  quoteId: string,
  kennitala: string,
  licencePlate: string,
  model: SupportedModels
): Promise<Policy> => {
  const query = gql`
    mutation AddCar(
      $productNumber: String!
      $variant: String!
      $kennitala: String!
      $carNumber: String!
      $packageNumber: String!
      $eiginahaetta: String!
      $verdmaetiKasko: String!
    ) {
      result: createNewQuotePolicy(
        productNumber: $productNumber
        variant: $variant
        kennitala: $kennitala
        subject: {
          reference: $carNumber
          coverage: [
            {
              typeId: "1421B"
              selected: true
              properties: [
                { id: "Eiginahaetta", value: $eiginahaetta }
                { id: "VerdmaetiKasko", value: $verdmaetiKasko }
              ]
            }
          ]
        }
        packageNumber: $packageNumber
      ) {
        success
        messages {
          code
          value
          type
        }
        policy {
          ...PolicyInfo
        }
      }
    }
    ${policyInfo}
  `
  const { result } = await policyHubClient.request<any>(query, {
    productNumber: InsuranceProductNumbers.Car,
    variant: model === SupportedModels.Toyota ? InsuranceProductVariants.Toyota : InsuranceProductVariants.Lexus,
    packageNumber: quoteId,
    carNumber: licencePlate,
    kennitala,
    eiginahaetta: defaultValues.selfRisk,
    verdmaetiKasko: defaultValues.kaskoValue,
  })
  if (!result.success) {
    throw new Error(result.messages[0].code || result.messages[0].value)
  }
  return result.policy
}

const getQuoteInProgress = async () => {
  const res = await policyHubClient.request<{
    quoteInProgress?: Quote
  }>(
    gql`
      query ($agency: String!) {
        quoteInProgress: quoteInProgress(agency: $agency) {
          number
          kennitala
          status {
            type
          }
          policies {
            ...PolicyInfo
          }
          createdByCurrentUser
          editableUntilDate
        }
      }
      ${policyInfo}
    `,
    { agency: AGENCY }
  )

  return res.quoteInProgress
}

const getVehicleInfo = async (plateNumber: string) => {
  const res = await fetch(`${host}/api/v1/vehicle/${plateNumber}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(toJson)
  return res
}

const getCancellations = async (quoteId: string): Promise<Array<Cancellation>> => {
  const query = `query ($packageNumber: String!){
    cancellations: cancellationsFromPackage(packageNumber: $packageNumber) {
      insuranceCompanyName
      policies {
        productName
        productNumber
        subjectDescription
        dueDate
      }
    }
  }`

  const { cancellations } = await policyHubClient.request<any>(query, {
    packageNumber: quoteId,
  })
  return cancellations
}

const signCancellation = async (fileId: string, phoneNumber: string): Promise<string> => {
  const mutation = `mutation ($fileId: Float!, $phoneNumber: String!) {
    result: cancelPolicies(fileId: $fileId, phoneNumber: $phoneNumber)
  }`

  const { result } = await policyHubClient.request<any>(mutation, {
    fileId: Number(fileId),
    phoneNumber,
  })

  return result
}

const startSigningCancellation = async (
  phonenumber: string,
  cancellationId: string
): Promise<StartSigningCancellationResult> => {
  const mutationString = `mutation ($fileId: Float!, $phonenumber: String!){
    result: startMobileSignFile(fileId: $fileId, phonenumber: $phonenumber) {
      status
      controlCode
    }
  }`
  const { result } = await cmClient.request<any>(mutationString, {
    fileId: Number(cancellationId),
    phonenumber,
  })
  if (result.status !== 'Success') {
    throw new Error(result.status)
  }
  return {
    controlCode: result.controlCode,
  }
}

const sendSignedCancellation = async (cancellationId: string): Promise<SendSignedCancellationResult> => {
  const mutationString = `mutation ($fileId: Float!){
    result: sendSignedCancellation(cancellationId: $fileId) {
      status
    }
  }`
  const res = await policyHubClient.request<any>(mutationString, {
    fileId: cancellationId,
  })
  return {
    status: res.result.status,
  }
}

const createQuote = async (kennitala?: string | undefined) => {
  const query = gql`
    mutation ($kennitala: String, $agency: String!) {
      pack: createPolicyPackage(kennitala: $kennitala, agency: $agency) {
        number
        kennitala
        createdByCurrentUser
      }
    }
  `
  const res = await policyHubClient.request<any>(query, {
    kennitala,
    agency: AGENCY,
  })

  return {
    ...res.pack,
    policies: [],
  }
}

let updateQ = Promise.resolve({} as any)

const removePolicy = async (policyNumber: string): Promise<void> => {
  const mutationString = `mutation ($policyNumber: String!, $transactionDescription: String!){
    removePolicy(policyNumber: $policyNumber transactionDescription: $transactionDescription)
  }`
  await policyHubClient.request<any>(mutationString, {
    policyNumber,
    transactionDescription: 'Removing policy',
  })
}

const update = (policyNumber: string, subject: SubjectInput): Promise<PolicyResult> => {
  const mutationString = gql`
    mutation ($policyNumber: String!, $subject: EditSubjectIn!) {
      result: changeQuotePolicy(policyNumber: $policyNumber, subject: $subject) {
        success
        messages {
          code
          value
          type
        }
        policy {
          ...PolicyInfo
        }
      }
    }
    ${policyInfo}
  `
  updateQ = updateQ.then(() =>
    policyHubClient
      .request<any>(mutationString, {
        policyNumber,
        subject,
      })
      .then((res) => res.result)
  )
  return updateQ
}
const updatePolicy = (policyNumber: string, subject: SubjectInput): Promise<Policy> => {
  return update(policyNumber, subject).then((res) => {
    if (!res.success) {
      throw new Error('Tókst ekki að uppfæra skírteini')
    }
    return res.policy
  })
}

const updateContactInfo = async (kennitala: string, phoneNumber: string, email: string): Promise<Person> => {
  const mutationString = gql`
    mutation ($kennitala: String!, $email: String!, $phoneNumber: String!) {
      updateContactInfo(kennitala: $kennitala, email: { personal: $email }, phone: { mobile: $phoneNumber }) {
        kennitala
        name
      }
    }
  `

  return await customerClient.request<any>(mutationString, {
    kennitala,
    email,
    phoneNumber,
  })
}
export const api: Api = {
  loginUserPass: (username: string, password: string) => {
    return fetch(`${host}/api/v1/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) return res.json()
        throw new HttpError(res.statusText, res.status)
      })
      .then(getCustomer)
  },
  loginRS: async (phoneNumber) => {
    return fetch(`${host}/api/v1/login-rs`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        clientType: 'web',
      }),
    })
      .then(toJson)
      .then(getCustomer)
  },
  logOut: async () => {
    await fetch(`${host}/api/v1/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
  },
  checkout,
  getCustomer,
  getSiteStatus,
  getPolicyOverview,
  getCustomerNameByKennitala,
  updateCustomerSettings,
  getQuote,
  getQuoteInProgress,
  addVehicle,
  getVehicleInfo,
  getCancellations,
  startSigningCancellation,
  signCancellation,
  sendSignedCancellation,
  createQuote,
  updatePolicy,
  removePolicy,
  updateContactInfo,
  sendSMS,
}
