import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { serverEventsUrl } from '../../api/api.tm'

export const listenToMobileEvents = (signingId: string) => {
  const events$ = new Observable((observer) => {
    const eventSource = new EventSource(serverEventsUrl, {
      withCredentials: true,
    })
    eventSource.onmessage = (event: MessageEvent) => {
      observer.next(JSON.parse(event.data))
    }
    eventSource.onerror = (error) => observer.error(error)
    return () => {
      eventSource.close()
    }
  }) as Observable<any>

  return events$.pipe(
    filter((event) => event && event.type && event.type.startsWith('MobileSign') && event.signingId === signingId)
  )
}
