import React from 'react'
export const GoogleLogo = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.390202 0.36334C0.119101 0.711531 -0.0192266 1.15912 0.00353797 1.61448V21.3825C-0.0244548 21.8386 0.114514 22.2882 0.390202 22.6336L0.44779 22.7051L10.6409 11.6325V11.3644L0.44779 0.300781L0.390202 0.36334Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M14.0136 15.3256L10.6406 11.6347V11.3666L14.0136 7.67578L14.0877 7.7294L18.1271 10.2138C19.2788 10.9198 19.2788 12.0816 18.1271 12.7965L14.1041 15.2809L14.0136 15.3256Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M14.1131 15.2713L10.6413 11.5L0.390625 22.6351C0.623095 22.8599 0.920654 22.9892 1.2317 23.0007C1.54275 23.0121 1.84767 22.9049 2.09359 22.6977L14.1131 15.2713Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M14.1131 7.72814L2.09359 0.310679C1.84927 0.10059 1.54459 -0.00920263 1.23312 0.000604467C0.921646 0.0104116 0.62333 0.139192 0.390625 0.3643L10.6413 11.4994L14.1131 7.72814Z"
      fill="url(#paint3_linear)"
    />
    <path
      opacity="0.2"
      d="M14.0143 15.1914L2.09359 22.5642C1.85661 22.7585 1.5678 22.8636 1.2709 22.8636C0.974003 22.8636 0.685193 22.7585 0.448213 22.5642L0.390625 22.6267L0.448213 22.6982C0.685193 22.8925 0.974003 22.9976 1.2709 22.9976C1.5678 22.9976 1.85661 22.8925 2.09359 22.6982L14.1131 15.2718L14.0143 15.1914Z"
      fill="black"
    />
    <path
      opacity="0.12"
      d="M0.394047 22.5101C0.129794 22.1556 0.000292903 21.7048 0.0320635 21.25V21.3841C0.00407072 21.8402 0.143038 22.2898 0.418726 22.6352L0.476316 22.5726L0.394047 22.5101Z"
      fill="black"
    />
    <path
      opacity="0.12"
      d="M18.1291 12.6598L14.0156 15.1889L14.0897 15.2693L18.1291 12.7849C18.3675 12.6754 18.5743 12.4981 18.7278 12.2716C18.8813 12.0451 18.9757 11.7779 19.0011 11.498C18.9497 11.7524 18.844 11.99 18.6925 12.1918C18.541 12.3936 18.348 12.5539 18.1291 12.6598Z"
      fill="black"
    />
    <path
      opacity="0.25"
      d="M2.09353 0.43707L18.1277 10.3389C18.3467 10.4449 18.5397 10.6052 18.6911 10.807C18.8426 11.0088 18.9483 11.2464 18.9998 11.5007C18.9743 11.2208 18.8799 10.9536 18.7265 10.7271C18.573 10.5007 18.3662 10.3233 18.1277 10.2138L2.09353 0.311955C0.949998 -0.402981 0.00390625 0.186842 0.00390625 1.61671V1.75076C0.0285869 0.320892 0.949998 -0.26893 2.09353 0.43707Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="9.73594"
        y1="1.40893"
        x2="-5.20864"
        y2="15.1665"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset="0.01" stopColor="#00A1FF" />
        <stop offset="0.26" stopColor="#00BEFF" />
        <stop offset="0.51" stopColor="#00D2FF" />
        <stop offset="0.76" stopColor="#00DFFF" />
        <stop offset="1" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="19.6326"
        y1="11.5007"
        x2="-0.268212"
        y2="11.5007"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset="0.41" stopColor="#FFBD00" />
        <stop offset="0.78" stopColor="#FFA500" />
        <stop offset="1" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="12.2291"
        y1="13.5554"
        x2="-8.04064"
        y2="32.207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset="1" stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="-2.19261"
        y1="-6.21311"
        x2="6.85607"
        y2="2.11685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset="0.07" stopColor="#2DA771" />
        <stop offset="0.48" stopColor="#15CF74" />
        <stop offset="0.8" stopColor="#06E775" />
        <stop offset="1" stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
)
