import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { isProd, isDev } from './helpers/env'

if (isProd || isDev) {
  Sentry.init({
    dsn: 'https://52cb8e108f8749d6a27c293d554a7b8e@sentry.io/1824074',
    environment: isProd ? 'Production' : 'Development',
  })
}
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
