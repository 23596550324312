import React, { useState } from 'react'
import { Radio } from '../../components/Radio'
import { RadioContainer } from './components'

export type PaymentMethodValue = 'invoice' | 'card'
interface Props {
  method: PaymentMethodValue
  setMethod: (method: PaymentMethodValue) => void
  onSelectionChanged: () => void
}

export const PaymentMethod = ({ method, setMethod, onSelectionChanged }: Props) => {
  return (
    <RadioContainer>
      <Radio
        id="invoice"
        groupName="method"
        checked={method === 'invoice'}
        onChange={() => {
          setMethod('invoice')
          onSelectionChanged()
        }}
        label="Netbanki"
      />

      <Radio
        id="card"
        groupName="method"
        checked={method === 'card'}
        onChange={() => {
          setMethod('card')
          onSelectionChanged()
        }}
        label="Kort"
      />
    </RadioContainer>
  )
}

export const usePaymentMethodProps = (onSelectionChanged: () => void): Props => {
  const [method, setMethod] = useState<PaymentMethodValue>('invoice')

  return { method, setMethod, onSelectionChanged }
}
