import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import Button from '../components/Button'
import Link from '../components/Link'
import { colors } from '../constants'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useTitle } from '../hooks/useTitle'
import { ChatSection } from '../components/ChatSection'
import { propBetween } from '../helpers/between'

const Agreement = () => {
  const { routeTo } = useRouteTransition()
  const { quoteId } = useParams()
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Samþykki')
  }, [setTitle])
  return (
    <ChatSection>
      <Title>
        Við metum <Link url="https://www.tm.is/personuvernd" text="persónuvernd" /> mikils og viljum að þér sé ljóst
        hvaða upplýsingar við geymum og hvernig þær eru notaðar.
      </Title>

      <Subtext>
        Ég hef lesið og samþykki{' '}
        <SubtextLink role="link" onClick={() => routeTo(`/skilmalar/${quoteId || ''}`)}>
          skilmálana.
        </SubtextLink>
      </Subtext>
      <Button onClick={() => routeTo(`/audkenna/${quoteId || ''}`)}>Halda áfram</Button>
    </ChatSection>
  )
}

const Title = styled.span`
  ${propBetween('font-size', 1.5, 2.1875)};
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
`

const Subtext = styled.div`
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 400;
  color: ${colors.black};
  text-align: center;
  ${breakpointDown('phone')} {
    margin-bottom: 3.5rem;
  }
`

const SubtextLink = styled.button`
  border: none;
  font-weight: 500;
  font-size: 1.125rem;
  color: ${colors.black};
  transition: all 0.2s ease-out;
  background: linear-gradient(to right, ${colors.silverGray} 50%, transparent 50%);
  background-size: 200% 34%;
  background-repeat: no-repeat;
  text-decoration: none;
  background-position: right bottom;
  cursor: pointer;
  border-bottom: 0.0625rem solid ${colors.black};
  padding: 0;

  &:hover {
    color: ${colors.black};
    background-position: left bottom;
  }

  &:focus {
    outline: none;
    background-size: 200% 100%;
    background-position: left bottom;
  }

  ${breakpointUp('tablet')} {
    margin-top: 1.875rem;
    margin-bottom: 4.625rem;
  }
`
export default Agreement
