import React from 'react'
import { Paragraph } from './components'

interface Props {
  multiplePolicies: boolean
}

export const UseExistingPaymentMethod = ({ multiplePolicies }: Props) => {
  return (
    <Paragraph>
      Greiðslum fyrir {multiplePolicies ? 'þessum tryggingum' : 'þessa tryggingu'} verður bætt við núverandi
      greiðslufyrirkomulag þitt hjá TM.
    </Paragraph>
  )
}
