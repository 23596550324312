export const isProd =
  window.location.host === 'toyota.tm.is' ||
  window.location.host === 'www.toyota.tm.is' ||
  window.location.host === 'lexus.tm.is' ||
  window.location.host === 'www.lexus.tm.is' ||
  window.location.host === 'toyota.live.oryggi.tm.is' ||
  window.location.host === 'lexus.live.oryggi.tm.is'

export const isDev =
  window.location.host.indexOf('toyota.it.tmdev.is') >= 0 || window.location.host.indexOf('lexus.it.tmdev.is') >= 0
