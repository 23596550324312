import React from 'react'
import styled from 'styled-components'

import { colors } from '../constants'
import { formatKronur } from '../helpers/formatKronur'
import { breakpointDown, breakpointUp } from '../helpers/breakpoints'
import { FooterButton } from './FooterButton'
import { zIndex } from '../helpers/z-index'
import { Policy } from '../api/types'
import { propBetween } from '../helpers/between'
import { useAppState } from '../Store'

interface Props {
  totalAmount: number
  scrollBarWidth?: number
  onClick?: () => any
  showCustomerViews?: boolean
  buttonText?: string
  buttonLoading?: boolean
}
const FooterComponent = ({
  totalAmount,
  scrollBarWidth,
  showCustomerViews,
  onClick,
  buttonText,
  buttonLoading,
}: Props) => {
  return (
    <Root scrollBarWidth={scrollBarWidth}>
      <Total showCustomerViews={showCustomerViews}>
        <TotalText>Samtals</TotalText>
        <TotalPrice>
          <TotalAmount>{formatKronur(totalAmount)}</TotalAmount>
          <TotalCurrency>kr. </TotalCurrency>
          <Period>/ á ári</Period>
        </TotalPrice>
      </Total>
      <FooterButton loading={Boolean(buttonLoading)} visible={showCustomerViews} onClick={onClick}>
        {buttonText || 'Áfram'}
      </FooterButton>
    </Root>
  )
}

interface ExternalProps {
  onClick?: () => any
  buttonText?: string
  showCustomerViews?: boolean
  buttonLoading?: boolean
  price?: number
  handleScrollBarWidth?: boolean
}
export const Footer = ({
  onClick,
  buttonText,
  showCustomerViews,
  buttonLoading,
  price,
  handleScrollBarWidth,
}: ExternalProps) => {
  const { state } = useAppState()

  const scrollBarWidth = handleScrollBarWidth ? window.innerWidth - document.documentElement.clientWidth : 0

  const totalAmount =
    price !== undefined
      ? price
      : ((state.quote && state.quote.policies) || [])
          .map((pol: Policy) => pol.policyPeriod.price.total)
          .reduce((sum: number, price: any) => sum + (price || 0), 0)

  return (
    <FooterComponent
      totalAmount={totalAmount}
      showCustomerViews={showCustomerViews}
      buttonText={buttonText}
      onClick={onClick}
      buttonLoading={buttonLoading}
      scrollBarWidth={scrollBarWidth}
    />
  )
}

const Root = styled.div<{ scrollBarWidth?: number }>`
  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  z-index: ${zIndex.fixed}; /*To display on top of coverage card*/
  background-color: ${colors.white};
  width: calc(100vw - ${(props: any) => (props.scrollBarWidth ? (props.scrollBarWidth / 16).toString() : '0')}rem);
  background-color: ${colors.white};

  transition: height 0.3s ease-out;
  box-shadow: 0 -0.5rem 5.625rem rgba(0, 0, 0, 0.08);

  ${breakpointDown('phone')} {
    width: 100%;
    flex-direction: column;
  }
`

const Total = styled.div<{ showCustomerViews?: boolean }>`
  display: flex;
  flex-direction: column;

  margin-right: 5.5rem;
  margin-left: ${(props: any) => (props.showCustomerViews ? '5rem' : 'auto')};
  ${breakpointUp('phone')} {
    padding-bottom: 1rem;
  }

  ${breakpointDown('phone')} {
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    margin: 1.375rem 1.5rem;
  }
`
const TotalText = styled.div`
  display: flex;

  margin-top: 1.375rem;

  color: ${colors.steelGray};

  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  line-height: 1.25rem;

  ${breakpointDown('phone')} {
    margin-top: 0;

    font-size: 0.875rem;
  }
`

const TotalPrice = styled.div`
  display: block;
`

const TotalAmount = styled.span`
  display: inline;

  padding-right: 0.5rem;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 1.6875, 2.5)};
`

const TotalCurrency = styled.span`
  display: inline;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
`

const Period = styled.span`
  display: inline;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
`

export default Footer
