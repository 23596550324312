import styled from 'styled-components'
import { colors } from '../../constants'
import React, { PropsWithChildren } from 'react'
import { breakpointUp } from '../../helpers/breakpoints'
import { propBetween } from '../../helpers/between'

export const Heading = styled.h2`
  ${propBetween('font-size', 2.1875, 2.5)};
  line-height: 120%;

  color: ${colors.black};
  margin: 3.25rem 0;

  ${breakpointUp('tablet')} {
    section:first-child > & {
      margin-top: 0;
    }
  }
`

export const Paragraph = styled.p`
  ${propBetween('font-size', 1.125, 1.6875)};
  line-height: 150%;

  color: ${colors.black};

  margin-bottom: 3.5rem;
`

interface SectionProps {
  label: string
}

export const Section = ({ children, label }: PropsWithChildren<SectionProps>) => {
  return (
    <section>
      <Heading>{label}</Heading>
      {children}
    </section>
  )
}

export const SectionDivider = styled.div`
  display: flex;
  height: 0.0625rem;
  width: 100%;
  background: ${colors.silverGray};
  margin: 3rem 0;
`

export const RadioContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;

  ${breakpointUp('phone')} {
    > div {
      flex-basis: 15.875rem;
    }
  }
`
