import React, { useState } from 'react'
import Button from '../components/Button'
import { ButtonGroup } from '../components/ButtonGroup'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'
import { Spacer } from '../components/Spacer'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { useParams } from 'react-router-dom'
import { api } from '../api/api.tm'
import { useAppState, setQuote } from '../Store'
import { Loading } from '../components/Loading'
import { LogOutButton } from '../components/LogOutButton'
export const ContinueOrStartAgain = () => {
  const { routeTo } = useRouteTransition()
  const { quoteId } = useParams()
  const [loadingContinue, setLoadingContinue] = useState<boolean>(false)
  const [loadingStartOver, setLoadingStartOver] = useState<boolean>(false)
  const { dispatch } = useAppState()
  const { state } = useAppState()
  if (!state.quote) {
    return <Loading />
  }
  return (
    <ChatSection>
      <ChatSectionTitle text="Má bjóða þér að halda áfram með sömu tryggingu og síðast eða viltu byrja upp á nýtt?" />
      <Spacer size="2.75rem" />
      <ButtonGroup>
        <Button
          onClick={async () => {
            if (state.quote) {
              setLoadingStartOver(true)
              const q = await api.createQuote(state.quote.kennitala)
              dispatch(setQuote(q))
              routeTo(`/${q.number}/sla-inn-bilnumer`)
            }
          }}
          loading={loadingStartOver}
          disabled={loadingContinue}
          inverted
        >
          Byrja aftur
        </Button>
        <Button
          onClick={() => {
            setLoadingContinue(true)
            routeTo(`/${quoteId}/yfirlit`)
          }}
          loading={loadingContinue}
          disabled={loadingContinue}
          inverted
          focused
        >
          Halda áfram
        </Button>
      </ButtonGroup>
      {state.person && <LogOutButton invisible />}
    </ChatSection>
  )
}
