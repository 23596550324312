import React, { useState, useEffect } from 'react'
import Button from '../components/Button'
import { PriceFooter } from '../components/PriceFooter'
import { useAppState, setPhoneNumber as setStatePhoneNumber } from '../Store'
import { Loading } from '../components/Loading'
import { useNavigate, useParams } from 'react-router'
import { Spacer } from '../components/Spacer'
import { api } from '../api/api.tm'
import { isProd } from '../helpers/env'
import { useExceptionHandler } from '../hooks/useExceptionHandler'
import { useTitle } from '../hooks/useTitle'
import styled from 'styled-components'
import { Input } from '../components/Input'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'

interface Props {
  phoneNumber: string
  quoteNumber: string
}

const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

const SendOfferComponent = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState('')
  const { state, dispatch } = useAppState()
  const { quoteId } = useParams()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { handleException } = useExceptionHandler()
  const navigate = useNavigate()
  const handleSendOffer = async () => {
    try {
      if (phoneNumber.length !== 7 || !validateEmail(email)) {
        return
      }
      setLoading(true)
      setError('')
      dispatch(setStatePhoneNumber(phoneNumber))
      await api.updateContactInfo((state.quote && state.quote.kennitala) || '', phoneNumber, email)
      const success = await api.sendSMS(
        phoneNumber,
        `Opnaðu til að skoða upplýsingar um ökutækjatryggingu fyrir nýja bílinn þinn.\nhttp${isProd ? 's' : ''}://${
          window.location.hostname
        }/samthykki/${quoteId}`
      )
      setLoading(false)
      if (success) {
        navigate(`/admin/${props.quoteNumber}/vel-gert`)
      } else {
        setError('Ekki tókst að senda SMS')
      }
    } catch (error: any) {
      setLoading(false)
      handleException({
        dispatchErrorNotification: true,
        error,
      })
    }
  }

  return (
    <>
      <ChatSection>
        <ChatSectionTitle text="Senda vefslóð með nánari upplýsingum á símanúmer kaupanda" />
        <Spacer size="2.75rem" />
        <InputWrapper>
          <InputSpan>
            <Input onChange={setPhoneNumber} value={phoneNumber} label="Símanúmer" error={error} />
          </InputSpan>
          <InputSpan>
            <Input onChange={setEmail} value={email} label="Netfang" onReturn={handleSendOffer} />
          </InputSpan>
        </InputWrapper>
        <Button
          onClick={handleSendOffer}
          disabled={phoneNumber.length !== 7 || !validateEmail(email)}
          loading={loading}
        >
          Senda slóð
        </Button>
      </ChatSection>
      <PriceFooter />
    </>
  )
}

const SendOffer = () => {
  const { state } = useAppState()
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle('Senda tilboð')
  })
  if (!state.quote) {
    return <Loading />
  }

  return <SendOfferComponent phoneNumber={state.phoneNumber || ''} quoteNumber={state.quote.number} />
}

export default SendOffer

const InputWrapper = styled.span`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
`

const InputSpan = styled.span`
  width: 20rem;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  margin: 0 1.25em;
`
