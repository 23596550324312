import React from 'react'

import { colors } from '../constants'
export const Close = ({ size = '14' }) => (
  <svg width={size} height={size} viewBox="0 0 14 14">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={colors.black}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <path d="M12 0L0 12M0 0L12 12" />
    </g>
  </svg>
)
