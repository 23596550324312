import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { breakpointUp } from '../helpers/breakpoints'

interface Props {
  children?: React.ReactNode
}

export const ChatSection: React.FC<Props> = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setIsMounted(true), 10)
  }, [])

  return <Root isMounted={isMounted}>{children}</Root>
}

const Root = styled.div<{ isMounted: boolean }>`
  padding: 42px 32px 0px;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  position: relative;
  opacity: 0.01;
  transform: translateY(80%);
  will-change: opacity, transform;
  ${(props) =>
    props.isMounted &&
    `
    opacity: 1;
    /* Comes in from the bottom */
    transform: translateY(0%);
    /* Starts coming in fast, then ends really smoothly */
    transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0, 0, 0, 1);
  `}
  & > h1 {
    width: 100%;
  }
  ${breakpointUp('phone')} {
    padding-top: 0;
    padding-right: 16vw;
    padding-bottom: 104px;
    padding-left: 16vw;
    min-height: calc(100vh - 104px);
    align-items: center;

    justify-content: center;
  }
`
