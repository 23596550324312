import { formatKronur, parseKronur } from './formatKronur'

export const numberInputChangeHandler = (
  oldValue: string,
  newValue: string,
  target: HTMLInputElement | HTMLTextAreaElement
) => {
  const cursorStart = target.selectionStart || 0
  const newAmount = formatKronur(parseKronur(newValue))

  const isDeleting = oldValue.length > newAmount.length
  const oldNumberOfDots = (oldValue.match(/\./g) || []).length
  const newNumberOfDots = (newAmount.match(/\./g) || []).length

  // We need to set the indicator manually because of the formatting
  const newCursorStart = cursorStart + (isDeleting ? 0 : newNumberOfDots - oldNumberOfDots)

  return [parseKronur(newAmount), newCursorStart]
}
