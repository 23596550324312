import { logError } from '../helpers/logError'
import { useRouteTransition } from './useRouteTransition'
import { useDispatchErrorNotification } from './useDispatchErrorNotification'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { useCallback } from 'react'
export interface exceptionParams {
  error: any
  dispatchErrorNotification: boolean
}
export const useExceptionHandler = () => {
  const { routeTo } = useRouteTransition()
  const dispatchErrorNotification = useDispatchErrorNotification()
  const { pathname } = useLocation()
  const isAdmin = matchPath('/admin/*', pathname)?.pathnameBase === '/admin'
  const { quoteId } = useParams()
  const handleException = useCallback(
    (params: exceptionParams) => {
      if (
        params.error &&
        (params.error.status === 401 || (params.error.response && params.error.response.status === 401))
      ) {
        routeTo(isAdmin ? '/admin/audkenna-aftur' : `/audkenna-aftur/${quoteId}`)
      } else {
        if (params.dispatchErrorNotification) {
          dispatchErrorNotification('Það lítur út fyrir að það séu einhver tæknileg vandræði, prófaðu aftur seinna.')
        }
        logError(params.error)
      }
    },
    [quoteId, isAdmin, routeTo, dispatchErrorNotification]
  )
  return { handleException }
}
