import React from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'

import { colors } from '../constants'
export const Loading = () => {
  return (
    <StyledLoading>
      <Spinner size={32} color={colors.steelGray} width={2} visible />
    </StyledLoading>
  )
}

const StyledLoading = styled.div`
  margin-top: 19rem;
  display: flex;
  justify-content: center;
`
