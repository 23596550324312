import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '../../../constants'
import { PageHeader } from '../../../components/PageHeader'
import { breakpointUp, breakpointDown } from '../../../helpers/breakpoints'
import { propBetween } from '../../../helpers/between'
import { SupportedModels } from '../../../api/types'

interface Props {
  reference: string
  text: string
  title: any
  modelType: SupportedModels
}

export const DetailsHeader = ({ reference, text, title, modelType }: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsMounted(true), 0)
    return () => {
      clearTimeout(timeoutId)
    }
  })
  return (
    <DetailsHeaderContainer>
      <PageHeader modelType={modelType}>
        <ContentContainer>
          <Content isMounted={isMounted}>
            <Title isMounted={isMounted}>{title}</Title>
            <Text isMounted={isMounted}>{text}</Text>
            {reference && <Reference isMounted={isMounted}>{reference}</Reference>}
          </Content>
        </ContentContainer>
      </PageHeader>
    </DetailsHeaderContainer>
  )
}

const DetailsHeaderContainer = styled.div`
  margin-left: -6.5rem;
  margin-bottom: 10vh;
  ${breakpointDown('tablet')} {
    margin-left: -1.5rem;
    /* This makes sure the header image is fullwidth, without causing horisontal scroll*/
    width: calc(100% + 3rem);
  }
  ${breakpointUp('tablet')} {
    margin-bottom: 16vh;
    /* This makes sure the header image is fullwidth, without causing horisontal scroll*/
    width: calc(100% + 13rem);
  }
`
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: flex-end;

  ${breakpointUp('tablet')} {
    justify-content: flex-end;
    padding-right: 16.25rem;
    padding-left: 7.5rem;
  }
`
const Content = styled.div<{ isMounted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1rem;
  background-color: ${colors.paleGray};
  transform: ${(props) => (props.isMounted ? 'translateY(7.5rem);' : 'translateY(20vh)')};
  transition: transform, opacity 0.3s cubic-bezier(0, 0, 0, 1);
  opacity: ${(props) => (props.isMounted ? '1' : '0.01')};
  max-width: 33.125rem;
  padding: 3.5rem 2rem;

  ${breakpointUp('tablet')} {
    padding: 4.5rem 4rem;
  }
  ${breakpointDown('phone')} {
    max-width: 100%;
    width: 100%;
  }
`
const Title = styled.h1<{ isMounted: boolean }>`
  ${propBetween('font-size', 2.5, 3.75)};
  ${propBetween('margin-bottom', 2, 3.5)};
  line-height: 1.2;
  font-weight: 700;
  padding-right: 1rem;
  margin-top: 0;
  transform: ${(props) => (props.isMounted ? 'translateY(0);' : 'translateY(10vh)')};
  transition: transform 0.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  opacity: ${(props) => (props.isMounted ? '1' : '0.01')};
  /* IE11 needs to be told explicitly that children of flex parents shouldn't overflow them */
  width: 100%;
`
const Text = styled.p<{ isMounted: boolean }>`
  ${propBetween('font-size', 1.125, 1.6875)};
  line-height: 1.5;
  transform: ${(props) => (props.isMounted ? 'translateY(0);' : 'translateY(10vh)')};
  opacity: ${(props) => (props.isMounted ? '1' : '0.01')};
  transition: opacity, transform 0.5s cubic-bezier(0, 0, 0, 1) 0.2s;
  margin: 0;
`
const Reference = styled.p<{ isMounted: boolean }>`
  ${propBetween('font-size', 1.6875, 2.1875)};
  ${propBetween('line-height', 2.1875, 2.8125)};
  ${propBetween('margin-top', 5.0625, 7.3125)};
  font-weight: 700;
  margin-bottom: 0;
  transform: ${(props) => (props.isMounted ? 'translateY(0);' : 'translateY(10vh)')};
  transition: transform 0.5s cubic-bezier(0, 0, 0, 1) 0.3s;
  opacity: ${(props) => (props.isMounted ? '1' : '0.01')};
`
