import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../constants'
import { breakpointUp } from '../../../helpers/breakpoints'
import { zIndex } from '../../../helpers/z-index'

interface IProps {
  title: string
  description: string
  children?: any
}

export const CoverageCard: React.FC<IProps> = ({ children, description, title }) => (
  <Root>
    <CardTitle>{title}</CardTitle>
    <CardDescription>{description}</CardDescription>
    {children}
  </Root>
)

const Root = styled.article`
  box-shadow: 0 1.125rem 4.875rem -0.75rem rgba(0, 0, 0, 0.18);
  width: 100%;
  max-width: 24rem;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  margin-bottom: 1.5rem;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 80%;
    left: 5rem;
    right: -0.75rem;
    bottom: -0.75rem;
    background-color: ${colors.lightGray};
    height: 11rem;
    transform: translateX(0.75rem);
    z-index: ${zIndex.underlay};
    max-width: 384px;
  }

  ${breakpointUp('tablet')} {
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    &::after {
      transform: translateX(5rem);
      height: 11rem;
      width: 384px;
      left: 0;
      right: 2.5rem;
      bottom: 2.25rem;
    }
  }
`

const CardTitle = styled.h1`
  font-size: 2.1875rem;
  line-height: 129%;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CardDescription = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
  margin-bottom: 5.5rem;
`
