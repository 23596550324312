export const colors = {
  black: '#000',
  white: '#FFF',
  gray: '#a9a9a9',
  toyotaRed: '#CA0813',
  redAccent: '#b00710',
  errorRed: '#F00000',
  steelGray: '#757575',
  paleGray: '#EEEEEE',
  silverGray: '#E8E8E8',
  platinumGray: '#EFEFEF',
  lightGray: '#F7F7F7',
  lexusBlack: '#000000',
  blackAccent: '#292929',
  charcoalBlack: '#212121',
}

export enum CoverageIds {
  KaskoCoverage = '1421B',
  WindShieldCoverage = '1421A',
  MandatoryCoverage = 'Skyldutrygging',
}
export enum PropertyIds {
  SelfRisk = 'Eiginahaetta',
  CoverageAmount = 'VerdmaetiKasko',
}
