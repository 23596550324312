import React, { useEffect } from 'react'

import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import Button from '../../components/Button'
import { useParams } from 'react-router'
import { Spacer } from '../../components/Spacer'
import { ChatSection } from '../../components/ChatSection'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { useTitle } from '../../hooks/useTitle'
import { getBrandTypeFromUrl } from '../../helpers/getBrandTypeFromUrl'
import { useAppState } from '../../Store'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  goToSummary: () => void
  multiplePolicies: boolean
}

const DetailsReminderComponent: React.FC<Props> = ({ goToSummary, multiplePolicies }: Props) => {
  return (
    <>
      <ChatSection>
        <ChatSectionTitle
          text={`${
            multiplePolicies ? 'Þá eru tryggingarnar komnar' : 'Þá er tryggingin komin'
          }! Við mælum samt með að þú skoðir ${
            multiplePolicies ? 'þær' : 'hana'
          } nánar hér á eftir til að breyta eða bæta við ef þörf er á.`}
        />
        <Spacer size="2.75rem" />
        <Button onClick={goToSummary}>Skoða {multiplePolicies ? 'tryggingar' : 'tryggingu'}</Button>
        <LogOutButton invisible />
      </ChatSection>
      <PriceFooter />
    </>
  )
}

export const DetailsReminder = () => {
  const { quoteId } = useParams()
  const { routeTo } = useRouteTransition()
  const { setTitle } = useTitle()
  const { state } = useAppState()

  useEffect(() => {
    setTitle(`${getBrandTypeFromUrl()} tryggingar`)
  }, [setTitle])

  const goToSummary = () => routeTo(`/${quoteId}/yfirlit`)

  return DetailsReminderComponent({
    goToSummary,
    multiplePolicies: (state.quote && state.quote.policies.length > 1) || false,
  })
}
