import React, { useEffect } from 'react'

import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import Button from '../../components/Button'
import { Spacer } from '../../components/Spacer'
import { useParams } from 'react-router'
import { ChatSection } from '../../components/ChatSection'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { useTitle } from '../../hooks/useTitle'
import { useAppState } from '../../Store'
import { Brand } from '../../api/types'

interface Props {
  brandType: Brand
  goToNextPage: () => void
}

const WelcomeComponent: React.FC<Props> = ({ brandType, goToNextPage }: Props) => {
  return (
    <>
      <ChatSection>
        <ChatSectionTitle
          text={`Að tryggja ${brandType} bílinn þinn er einfalt og þægilegt ferli sem tekur aðeins örfáar mínútur.`}
        />
        <Spacer size="2.75rem" />
        <Button onClick={goToNextPage}>Byrja ferli</Button>
      </ChatSection>
    </>
  )
}

export const Welcome = () => {
  const { quoteId } = useParams()
  const { routeTo } = useRouteTransition()
  const { setTitle } = useTitle()
  const { state } = useAppState()

  useEffect(() => {
    setTitle('Upphaf')
  }, [setTitle])

  const goToNextPage = () => routeTo(`/samthykki/${quoteId ? quoteId : ''}`)

  return WelcomeComponent({ brandType: state.brandType, goToNextPage })
}
