import React, { useState } from 'react'
import styled from 'styled-components'
import Spinner from 'react-spinner-material'
import { colors } from '../constants'
import { breakpointUp } from '../helpers/breakpoints'
import { zIndex } from '../helpers/z-index'

interface IOption {
  value: any
  title: any
}

interface IProps {
  options: Array<IOption>
  label: string
  onChange: (value: string) => any
  selected?: IOption
  disabled?: boolean
}

export const Select = ({ disabled, onChange, options, selected, label }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <Root>
      {!isLoading && (
        <SelectHTML
          aria-label={label}
          disabled={disabled}
          onChange={async (event: React.FormEvent<HTMLSelectElement>) => {
            setIsLoading(true)
            await onChange(event.currentTarget.value)
            setIsLoading(false)
          }}
          value={selected && selected.value}
        >
          {options.map(({ value, title }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </SelectHTML>
      )}
      <Overlay disabled={disabled}>
        {isLoading ? (
          <StyledLoader $loading={isLoading}>
            <Spinner size={32} color={colors.gray} width={3} visible />
          </StyledLoader>
        ) : (
          <OverlayText disabled={disabled}>{selected ? selected.title : 'Velja'}</OverlayText>
        )}
      </Overlay>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%; /* For IE11 support */

  ${breakpointUp('tablet')} {
    width: auto;
    min-width: 19.875rem;
  }
`

const SelectHTML = styled.select<{ disabled?: boolean }>`
  background-color: transparent;
  position: absolute;
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  font-size: 1rem;
  height: 100%; /* IE11 support */
`

const Overlay = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? colors.lightGray : colors.white)};
  border: 0.25rem solid ${(props) => (props.disabled ? colors.silverGray : colors.gray)};
  pointer-events: none;
  opacity: 0.99;
  z-index: ${zIndex.overlay};
  transition: all 0.5s;
  outline: none;
  height: 4rem;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1.5rem;
    display: ${(props) => (props.disabled ? 'none' : 'block')};
    height: 0.4375rem;
    width: 0.625rem;
    border: 0.3125rem solid transparent;
    background-color: transparent;
    border-top: 0.4375rem solid ${(props) => (props.disabled ? colors.silverGray : colors.black)};
    margin-top: -0.1875rem;
  }
`

const OverlayText = styled.div<{ disabled?: boolean }>`
  padding: 0.75rem 3rem 0.75rem 1.5rem;

  color: ${colors.steelGray};

  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.5;

  transition: all 0.5s;

  &.hover {
    color: ${(props) => (props.disabled ? colors.gray : colors.black)};
  }

  ${breakpointUp('desktopL')} {
    font-size: 1.6875rem;
  }
`

const StyledLoader = styled.span<{ $loading?: boolean }>`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$loading ? '1' : '0')};
  pointer-events: none;
  transform: ${(props) => (props.$loading ? 'scale(1)' : 'scale(0.8)')};
  transition-duration: 0.2s;
  transition-delay: ${(props) => (props.$loading ? '0.2s' : '0s')};
  z-index: ${zIndex.overlay};
`
