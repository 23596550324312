import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Button from '../../components/Button'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { AllModels, SupportedModels } from '../../api/types'
import { ChatSection } from '../../components/ChatSection'
import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { Spacer } from '../../components/Spacer'
import { useAppState } from '../../Store'

const formatInsuranceCompanies = (insuranceCompanies: string[]) => {
  const formattedString = insuranceCompanies
    .filter((company, index) => insuranceCompanies.indexOf(company) === index && company !== 'TM')
    .join(', ')
    .replace(/, ([^,]*)$/, ' og $1')

  return formattedString
}

const getCopy = (insuranceCompanies: string[], plateNumber?: string, vehicleModel?: SupportedModels): string => {
  const formattedInsuranceCompanies = formatInsuranceCompanies(insuranceCompanies)
  if (insuranceCompanies.indexOf('TM') > -1) {
    if (!insuranceCompanies.every((company) => company === 'TM')) {
      if (insuranceCompanies.filter((company) => company !== 'TM').length > 1) {
        return `Við kaupin verður núverandi ökutækjatryggingum þínum hjá TM sjálfkrafa sagt upp en fyrir þá bíla sem tryggðir eru hjá ${formattedInsuranceCompanies} þarftu síðan að undirrita uppsögn rafrænt til að tryggingin taki gildi.`
      } else {
        return `Við kaupin verður núverandi ökutækjatryggingum þínum hjá TM sjálfkrafa sagt upp en fyrir þann bíl sem er tryggður hjá ${formattedInsuranceCompanies} þarftu síðan að undirrita uppsögn rafrænt til að tryggingin taki gildi.`
      }
    } else {
      if (plateNumber && vehicleModel) {
        return `Núverandi ökutækjatryggingu þinni hjá TM fyrir ${plateNumber} verður sjálfkrafa sagt upp og ${vehicleModel} tryggingin virkjuð þegar þú gengur frá kaupunum.`
      } else {
        return 'Núverandi ökutækjatryggingum þínum hjá TM verður sjálfkrafa sagt upp og nýju tryggingarnar virkjaðar þegar þú gengur frá kaupunum.'
      }
    }
  } else {
    return `Við sjáum að þú ert þegar með ökutækjatryggingu hjá ${formattedInsuranceCompanies}. Til að þessi trygging taki gildi þarftu að undirrita uppsögn rafrænt eftir kaupin.`
  }
}

const CarAlreadyInsured: React.FC = () => {
  const { routeTo } = useRouteTransition()
  const { state } = useAppState()
  const { quoteId, insuranceCompanies, plateNumber } = useParams()
  const [vehicleModel, setVehicleModel] = useState<AllModels | undefined>(undefined)
  let companies = [] as string[]
  if (insuranceCompanies) {
    companies = insuranceCompanies.split('-')
  }

  const modelType = state.vehicleModels.find((car) => car.licencePlate === plateNumber)

  useEffect(() => {
    modelType && setVehicleModel(modelType.model)
  }, [modelType])

  return (
    <ChatSection>
      <ChatSectionTitle text={getCopy(companies, plateNumber, vehicleModel as SupportedModels)} />
      <Spacer size="2.75rem" />
      <Button onClick={() => routeTo(`/${quoteId ? quoteId : ''}/kaupa`)}>Gott mál</Button>
    </ChatSection>
  )
}
export default CarAlreadyInsured
