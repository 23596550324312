import React, { useEffect } from 'react'

import Content from '../components/Content'
import { Title } from '../components/Title'
import { Footer } from '../components/Footer'
import { useAppState } from '../Store'
import { useTitle } from '../hooks/useTitle'

interface Props {
  phoneNumber?: string
}
const FinishedComponent = ({ phoneNumber }: Props) => {
  return (
    <Content>
      <Title>
        {phoneNumber
          ? `Komið! Vefslóð með upplýsingum um tryggingu var send á símanúmerið ${phoneNumber.substr(
              0,
              3
            )} ${phoneNumber.substr(3)}.`
          : 'Komið! Vefslóð með upplýsingum um tryggingu var send í smáskilaboðum.'}
      </Title>
      <Footer />
    </Content>
  )
}

const Finished = () => {
  const { state } = useAppState()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Allt komið!')
  })
  return FinishedComponent({ phoneNumber: state.phoneNumber })
}
export default Finished
