import React, { useState, useEffect } from 'react'
import Button from '../../components/Button'
import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import { Spacer } from '../../components/Spacer'
import { useNavigate, useParams } from 'react-router'
import { CoverageIds, PropertyIds } from '../../constants'
import { updatePolicy, useAppState } from '../../Store'
import { formatKronur } from '../../helpers/formatKronur'
import { api } from '../../api/api.tm'
import { EnteredNumberProperty } from '../../api/types'
import { Loading } from '../../components/Loading'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'

interface Props {
  entryPrice: number
  handleAddKasko: () => void
  goToNextPage: () => void
}

const CarKaskoComponent: React.FC<Props> = ({ entryPrice, handleAddKasko, goToNextPage }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const addKasko = () => {
    setLoading(true)
    handleAddKasko()
    goToNextPage()
    setLoading(false)
  }

  return (
    <>
      <ChatSection>
        <ChatSectionTitle text="Ef bíllinn er metinn á meira en 1.000.000 kr., þá mælum við með því að bæta við kaskótryggingu sem tryggir þig gegn þjófnaði eð skemmdum á bílnum" />
        <Spacer size="2.75rem" />
        <Button onClick={addKasko} loading={loading}>
          Bæta við fyrir {formatKronur(entryPrice)} kr./mán
        </Button>
      </ChatSection>
      <PriceFooter />
    </>
  )
}
export const CarKasko = () => {
  const { state, dispatch } = useAppState()
  const navigate = useNavigate()
  const { quoteId, policyId } = useParams()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Kaskó')
  }, [setTitle])

  if (state.quote && state.quote.policies.length > 0) {
    const policy = state.quote.policies.find((p) => p.number === policyId)
    const kasko = policy && policy.subjects[0].coverage.find((a) => a.typeId === CoverageIds.KaskoCoverage)

    if (kasko == null) {
      throw new Error('Something really wrong')
    }

    const selfRiskProp = kasko.properties.find((a): a is EnteredNumberProperty => a.id === PropertyIds.SelfRisk)

    if (selfRiskProp == null) {
      throw new Error('Something really wrong')
    }

    const props: Props = {
      entryPrice: kasko.entryPriceTotal.total || 0,
      handleAddKasko: async () => {
        const coverage = [
          {
            typeId: CoverageIds.KaskoCoverage,
            selected: true,
            properties: [
              {
                id: PropertyIds.SelfRisk,
                value: String(selfRiskProp.value || 135000),
              },
              {
                id: PropertyIds.CoverageAmount,
                value: String(3000000),
              },
            ],
          },
        ]
        const updatedPolicy = await api.updatePolicy(policyId || '', {
          coverage,
        })
        dispatch(updatePolicy(updatedPolicy))
      },
      goToNextPage: () => {
        if (quoteId) {
          navigate(`/${quoteId}/kasko-eiginahaetta/${policyId}`)
          window.scrollTo(0, 0)
        }
      },
    }

    return CarKaskoComponent({ ...props })
  } else {
    return <Loading />
  }
}
