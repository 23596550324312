import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../constants'
import { formatKronur } from '../../../helpers/formatKronur'
interface IProps {
  amount: number
}

export const Price = ({ amount }: IProps) => {
  return (
    <StyledPrice>
      <Amount>{`${formatKronur(amount)} `}</Amount>
      <Period>kr. / á ári</Period>
    </StyledPrice>
  )
}

const StyledPrice = styled.div`
  transition: opacity 0.2s;
`
const Period = styled.span`
  font-size: 1.125rem;
  color: ${colors.black};
  font-weight: 500;
  transition: color 0.2s;
  display: inline;
`
const Amount = styled.p`
  font-size: 2.5rem;
  line-height: 1;
  color: ${colors.black};
  font-weight: 900;
  transition: color 0.2s;
  display: inline;
`
