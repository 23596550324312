import React from 'react'
import styled from 'styled-components'

interface Props {
  children: any
  vertical?: boolean
  inverted?: boolean
}

export const ButtonGroup: React.FC<Props> = ({ children, vertical, inverted }) => (
  <Root vertical={vertical || false} inverted={inverted}>
    {React.Children.map(children, (child) => child)}
  </Root>
)

const Root = styled.div<{ vertical: boolean; inverted?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${(props) => props.vertical && 'flex-direction: column'}
  ${(props) => props.vertical && 'align-items: center'}
`
