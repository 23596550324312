import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CardImage } from './CardImage'
import { breakpointUp } from '../../helpers/breakpoints'
import Button from '../../components/Button'
import { Price } from '../Details/components/Price'
import { Spacer } from '../../components/Spacer'
import { colors } from '../../constants'
import { splitInsuranceNameWithHyphen } from '../../helpers/splitNameWithHyphen'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { AllModels, SupportedModels } from '../../api/types'
import { useAppState } from '../../Store'

interface Props {
  policyId: string
  reverse?: boolean
}

export const SummaryCard: React.FC<Props> = ({ policyId, reverse }: Props) => {
  const { state } = useAppState()
  const { routeTo } = useRouteTransition()
  const [model, setModel] = useState<AllModels | undefined>(undefined)

  const quoteId = state.quote && state.quote.number
  const policy = state.quote && state.quote.policies.find((p) => p.number === policyId)
  const subject = policy && policy.subjects[0]
  const coverages = (subject && subject.coverage) || []
  const goToDetailsPage = () => routeTo(`/${quoteId}/yfirlit/${policyId}`)

  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsMounted(true), 0)
    return () => {
      clearTimeout(timeoutId)
    }
  })

  useEffect(() => {
    if (subject && !model) {
      const vehicleModel = state.vehicleModels.find((vi) => vi.licencePlate === subject.reference)
      if (vehicleModel) {
        setModel(vehicleModel.model)
      }
    }
  }, [subject, state.vehicleModels, model])

  return (
    <Article reverse={reverse}>
      <CardImage model={model as SupportedModels} reverse={reverse} />

      <Card isMounted={isMounted} reverse={reverse}>
        <Title>{policy && splitInsuranceNameWithHyphen(policy.product.name)}</Title>
        <Subtitle>{`${subject && subject.description}, ${subject && subject.reference}`}</Subtitle>
        <span>
          {coverages && coverages.length > 0 && <CoveragesTitle>Viðbætur</CoveragesTitle>}

          <CoverageList>
            {coverages.map(({ name, selected }) => (
              <CoverageListItem disabled={!selected} key={name}>
                {name}
              </CoverageListItem>
            ))}
          </CoverageList>
        </span>
        {Boolean(policy && policy.policyPeriod.price.total) && (
          <Price amount={(policy && policy.policyPeriod.price.total) || 0} />
        )}
        <Spacer size="2.75em" />
        <Button onClick={goToDetailsPage}>Nánar</Button>
      </Card>
    </Article>
  )
}

const Article = styled.article<{ reverse?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
  max-width: 90rem;
  ${breakpointUp('tablet')} {
    height: 100%;
    margin: 15.625rem 1.5rem;
    flex-direction: row;
    align-items: stretch;
    ${(props) => props.reverse && 'flex-direction: row-reverse;'}
  }
`

const Card = styled.div<{ reverse?: boolean; isMounted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  margin: -35vh 1rem 0;
  padding: 3.5rem 2.25rem;
  transform: translateY(6.25rem);
  box-shadow: 0 1.875rem 4.875rem rgba(0, 0, 0, 0.16);
  ${(props) => props.isMounted && 'transition: all 0.5s ease-out 0.3s; transform: translateY(0);'}
  ${breakpointUp('tablet')} {
    margin: 0;
    padding: 5.5rem 6.5rem 5.5rem 9rem;
    justify-content: center;
    box-shadow: none;
    ${(props) => props.reverse && 'padding-right: 9rem; padding-left: 6.5rem;'}
  }
`

const Title = styled.h3`
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${colors.charcoalBlack};
  padding: 0;
  margin: 0;
  transition: background-size 0.4s;
  text-decoration: underline;
  ${breakpointUp('tablet')} {
    font-size: 4rem;
  }
`

const Subtitle = styled.p`
  margin: 2rem 0;
  font-weight: 500;
`

const CoveragesTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0;
`

const CoverageList = styled.ul`
  list-style: none;
  margin-bottom: 2.5rem;
  padding-left: 0;
`
const CoverageListItem = styled.ul<{ disabled: boolean }>`
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 0;
  ${(props) => props.disabled && 'text-decoration: line-through;'}
`
