import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../constants'
import { propBetween } from '../../../helpers/between'
import { breakpointDown } from '../../../helpers/breakpoints'
import Link from '../../../components/Link'

interface Props {
  usingSelfService: boolean
  contactEmail: string
}

export const Information = ({ usingSelfService, contactEmail }: Props) => (
  <Root>
    <Heading>Upplýsingar</Heading>
    {usingSelfService ? (
      <>
        <Paragraph>
          TM hf., Katrínartúni 2, 105 Reykjavík er vátryggingafélag Toyota og Lexus trygginga og greiðir Toyota á
          Íslandi þóknun vegna vátryggingarsamningsins sem nemur tilteknu hlutfalli af iðgjaldi vátryggingarinnar.
        </Paragraph>
        <Paragraph>
          Upplýsingar um meðferð kvartana er að finna á{' '}
          <Link url="https://www.tm.is/kvartanir/" text="https://www.tm.is/kvartanir/" />
        </Paragraph>
      </>
    ) : (
      <>
        <Paragraph>
          TM hf. greiðir dreifingaraðila þóknun vegna vátryggingarsamningsins sem nemur tilteknu hlutfalli af iðgjaldi
          vátryggingarinnar.
        </Paragraph>
        <Paragraph>
          Eftirtaldir aðilar eru skráðir aðilar sem dreifa vátryggingum sem aukaafurð hjá FME, sjá www.fme.is:
        </Paragraph>
        <List>
          <ListItem>
            <strong>Toyota á Íslandi ehf.</strong>, Kauptúni 6, 210 Garðabæ
          </ListItem>
          <ListItem>
            <strong>TK bílar ehf.</strong>, Kauptúni 6, 210 Garðabæ
          </ListItem>
          <ListItem>
            <strong>Æco bílar ehf.</strong>, Njarðarbraut 19, 260 Reykjanesbæ
          </ListItem>
          <ListItem>
            <strong>Bílasala Suðurlands ehf.</strong>, Fossnesi 14, 800 Selfossi
          </ListItem>
          <ListItem>
            <strong>Stórholt ehf.</strong>, Baldursnesi 1, 603 Akureyri
          </ListItem>
        </List>
        <Paragraph>
          Koma má kvörtunum eða ábendingum á framfæri með því að senda tölvupóst á netfangið{' '}
          {<Link url={`mailto: ${contactEmail}`} text={contactEmail} />}
        </Paragraph>
      </>
    )}
  </Root>
)

const Root = styled.article`
  ${propBetween('margin-top', 4.5, 5)}
  padding-bottom: 5rem;
  border-bottom: 0.0625rem solid ${colors.silverGray};
  ${breakpointDown('phone')} {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

const Heading = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
`

const Paragraph = styled.p`
  font-size: 1.125rem;

  &:first-of-type {
    ${propBetween('margin-bottom', 3, 1.75)}
  }
`

const List = styled.ol``
const ListItem = styled.li`
  padding-top: 0.625rem;
`
