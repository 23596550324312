import React, { useEffect } from 'react'
import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import Button from '../../components/Button'
import { ButtonGroup } from '../../components/ButtonGroup'
import { useParams, useNavigate } from 'react-router'
import { Spacer } from '../../components/Spacer'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  handleYesClick: () => void
  handleNoClick: () => void
}

const AddAnotherCarComponent: React.FC<Props> = ({ handleYesClick, handleNoClick }: Props) => {
  return (
    <>
      <ChatSection>
        <ChatSectionTitle text={'Áður en við höldum áfram, má bjóða þér að tryggja annan bíl?'} />
        <Spacer size="2.75rem" />
        <ButtonGroup>
          <Button onClick={handleYesClick} inverted>
            Já
          </Button>
          <Button onClick={handleNoClick} inverted focused>
            Nei
          </Button>
        </ButtonGroup>
        <LogOutButton invisible />
      </ChatSection>
      <PriceFooter />
    </>
  )
}

export const AddAnotherCar = () => {
  const { quoteId } = useParams()
  const navigate = useNavigate()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Tryggja annan bíl?')
  }, [setTitle])

  const props = {
    handleYesClick: () => {
      navigate(`/${quoteId}/sla-inn-bilnumer`)
    },
    handleNoClick: () => {
      navigate(`/${quoteId}/minna-a-tryggingar`)
    },
  }
  return AddAnotherCarComponent({ ...props })
}
