import React, { useEffect } from 'react'

import { ChatSectionTitle } from '../../components/ChatSectionTitle'
import { PriceFooter } from '../../components/PriceFooter'
import Button from '../../components/Button'
import { useNavigate, useParams } from 'react-router'
import { Loading } from '../../components/Loading'
import { Spacer } from '../../components/Spacer'
import { ChatSection } from '../../components/ChatSection'
import { useTitle } from '../../hooks/useTitle'
import { useAppState } from '../../Store'
import { LogOutButton } from '../../components/LogOutButton'

interface Props {
  goToNextPage: () => void
}

const CarAddedComponent: React.FC<Props> = ({ goToNextPage }: Props) => {
  return (
    <>
      <ChatSection>
        <ChatSectionTitle text="Þá er skyldutryggingin komin, hún bætir tjón sem aðrir verða fyrir af völdum bílsins og slys á ökumanni og eiganda." />
        <Spacer size="2.75rem" />
        <Button onClick={goToNextPage}>Áfram</Button>
        <LogOutButton invisible />
      </ChatSection>
      <PriceFooter />
    </>
  )
}

export const CarAdded = () => {
  const { state } = useAppState()
  const navigate = useNavigate()
  const { quoteId, policyId } = useParams()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Bíl bætt við')
  }, [setTitle])

  if (state.quote && state.quote.policies.length > 0) {
    const policy = state.quote.policies.find((p) => p.number === policyId)

    const props = {
      goToNextPage: () => {
        if (quoteId) {
          navigate(`/${quoteId}/kasko-upphaed/${policy && policy.number}`)
          window.scrollTo(0, 0)
        }
      },
    }

    return CarAddedComponent({ ...props })
  } else {
    return <Loading />
  }
}
