import React, { useState } from 'react'
import styled from 'styled-components'

import { colors } from '../constants'
import { formatKronur } from '../helpers/formatKronur'
import { breakpointDown, breakpointUp, breakpoints } from '../helpers/breakpoints'
import { zIndex } from '../helpers/z-index'
import { Policy } from '../api/types'
import { propBetween } from '../helpers/between'
import { useAppState } from '../Store'
import { Transition } from 'react-transition-group'

interface Props {
  togglePriceFooter: () => void
  expanded: boolean
  policies: Array<Policy>
  totalAmount: number
}
const PriceFooterComponent = ({ expanded, togglePriceFooter, policies, totalAmount }: Props) => {
  return (
    <Root>
      <Backdrop onClick={togglePriceFooter} visible={expanded} />
      <FooterContent expanded={expanded}>
        <FooterItems expanded={expanded}>
          {policies.map((policy: Policy, index: number) => (
            <Transition in={expanded} timeout={100} key={index}>
              {(state) => {
                return (
                  <FooterItemContainer state={state} key={index}>
                    <FooterItemInner>
                      <FooterItemLabel>{`${policy.subjects[0].description}`}</FooterItemLabel>
                      <FooterItemPrice>{`${formatKronur(policy.policyPeriod.price.total || 0)} kr.`}</FooterItemPrice>
                    </FooterItemInner>
                  </FooterItemContainer>
                )
              }}
            </Transition>
          ))}
        </FooterItems>
        {policies.length > 0 && <ExpandButton onClick={togglePriceFooter} expanded={expanded} />}
        <Total>
          <TotalInner>
            <TotalText>Samtals</TotalText>
            <TotalPrice>
              <TotalAmount>{formatKronur(totalAmount)}</TotalAmount>
              <TotalCurrency>kr. </TotalCurrency>
              <Period>/ á ári</Period>
            </TotalPrice>
          </TotalInner>
        </Total>
      </FooterContent>
    </Root>
  )
}

export const PriceFooter = () => {
  const { state } = useAppState()
  const [priceFooterExpanded, setPriceFooterExpanded] = useState(false)

  const policies = (state.quote && state.quote.policies) || []

  const totalAmount = ((state.quote && state.quote.policies) || [])
    .map((pol: Policy) => pol.policyPeriod.price.total)
    .reduce((sum: number, price: any) => sum + (price || 0), 0)

  return (
    <PriceFooterComponent
      expanded={priceFooterExpanded}
      togglePriceFooter={() => setPriceFooterExpanded(!priceFooterExpanded)}
      policies={policies}
      totalAmount={totalAmount}
    />
  )
}

const Root = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: ${zIndex.fixed};
  transition: all 0.3s ease-out;
  ${breakpointUp('tablet')} {
    display: flex;
    justify-content: center;
    box-shadow: 0 -0.5rem 5.625rem rgba(0, 0, 0, 0.08);
  }
`

const Backdrop = styled.div<{ visible?: boolean }>`
  ${breakpointDown('tablet')} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s, transform 0.2s step-end;
    opacity: 0.01;
    z-index: -2;
    ${(props) =>
      props.visible &&
      `
    transform: translateY(-100%);
    transition: opacity 0.2s, transform 0.2s step-start;
    opacity: 1;`}
  }
`

const ExpandButton = styled.button<{ expanded: boolean }>`
  ${breakpointDown('tablet')} {
    position: relative;
    width: 3.75rem;
    height: 5rem;
    border: 0;
    background: transparent;
    padding: 0;
    border-radius: 0;
    border-right: 0.0625rem solid ${colors.silverGray};
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      margin-top: -0.125rem;
      margin-left: -0.3125rem;
      display: block;
      border: 0.3125rem solid transparent;
      border-top-width: 0;
      border-bottom: 0.4375rem solid ${colors.charcoalBlack};
      transition: border-color 0.2s;
      ${(props) => props.expanded && `transform: rotateZ(180deg);`}
    }
  }
  ${breakpointUp('tablet')} {
    display: none;
  }
`

const FooterContent = styled.div<{ expanded: boolean }>`
  display: flex;
  justify-content: space-between;
  min-height: 5rem;
  background: ${colors.white};
  ${breakpointDown('tablet')} {
    width: 100%;
    justify-content: space-between;
    border-top: 0.0625rem solid transparent;
    transition: border-color 0.2s;
    ${(props) => props.expanded && `border-top-color: ${colors.silverGray};`}
  }
  ${breakpointUp('tablet')} {
    height: 6.5rem;
    margin: 0 2rem;
    width: 100%;
  }
`

const FooterItems = styled.div<{ expanded?: boolean }>`
  ${breakpointDown('tablet')} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100vw;
    background: ${colors.white};
    transition: transform 0.2s ease-in;
    box-shadow: 0 -0.5rem 5rem rgba(0, 0, 0, 0.08);
    ${(props) =>
      props.expanded &&
      `
      transform: translateY(-100%);
      transition: transform 0.2s ease-out;`}
  }
  ${breakpointUp('tablet')} {
    margin-left: -3.125rem;
    display: flex;
    z-index: 1;
    flex: 1;
  }
`

const FooterItemContainer = styled.div<{ state: string }>`
  ${breakpointDown('tablet')} {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${(props) =>
      props.state === 'entering' &&
      ` opacity: 0.01;
      transform: translateY(20%);`}
    ${(props) =>
      props.state === 'entered' &&
      ` opacity: 1;
      transition: transform 0.3s ease-out;
      transform: translateY(0);`}
  ${(props) =>
      props.state === 'exiting' &&
      ` opacity: 1;
      transform: translateY(0);`}
  ${(props) =>
      props.state === 'exited' &&
      ` opacity: 0.01;
      transition: transform 0.3s ease-in;
      transform: translateY(20%);`}
  }

  ${breakpointUp('tablet')} {
    max-width: 17.75rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-right: 0.0625rem solid ${colors.silverGray};
    margin-left: 1rem;
  }
`

const FooterItemInner = styled.div`
  ${breakpointDown('tablet')} {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  ${breakpointUp('tablet')} {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding-top: 0.25rem;
  }
`

const FooterItemLabel = styled.p`
  color: ${colors.steelGray};
  ${propBetween('font-size', 0.875, 0.875, breakpoints.tablet, breakpoints.desktop)};
  line-height: 1.3;
  margin: 0;
  font-weight: 500;
`
const FooterItemPrice = styled.p`
  ${propBetween('font-size', 1.25, 1.75)};
  line-height: 1.3;
  color: ${colors.charcoalBlack};
  font-weight: 700;
  transition: color 0.2s;
  margin: 0;
`

const Total = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;

  padding: 0 1.5rem;
`

const TotalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.25rem;
  ${breakpointDown('phone')} {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
`

const TotalText = styled.div`
  display: flex;

  color: ${colors.steelGray};

  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  line-height: 1.25rem;

  ${breakpointDown('phone')} {
    font-size: 0.875rem;
  }
`

const TotalPrice = styled.div`
  display: block;
`

const TotalAmount = styled.span`
  display: inline;

  padding-right: 0.5rem;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 1.6875, 2.5)};
`

const TotalCurrency = styled.span`
  display: inline;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 0.84375, 1.25)};
`

const Period = styled.span`
  display: inline;

  color: ${colors.black};

  font-style: normal;
  font-weight: bold;
  ${propBetween('font-size', 0.84375, 1.25)};
`

export default PriceFooter
