import React from 'react'

import Button from '../components/Button'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'

export const LoggedOut = () => {
  const { routeTo } = useRouteTransition()
  return (
    <ChatSection>
      <ChatSectionTitle text={'Takk fyrir. Ef þú kemur aftur innan 60 daga geturðu tekið upp þráðinn að nýju.'} />
      <Button onClick={() => routeTo('/audkenna')}>Byrja aftur</Button>
    </ChatSection>
  )
}
