import React from 'react'
import styled from 'styled-components'

import { colors } from '../constants'
import { breakpointDown, breakpointUp } from '../helpers/breakpoints'
import { AppStoreButton } from './AppStoreButton'
import { propBetween } from '../helpers/between'

export const ThankYouPageFooter = () => {
  return (
    <Root>
      <FooterWrapper>
        <FooterItemContainer>
          <FooterItemLabel>Sæktu TM Appið á App Store eða Google Play</FooterItemLabel>
        </FooterItemContainer>
        <AppStoreButton appStore="apple" />
        <AppStoreButton appStore="google" />
      </FooterWrapper>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: ${colors.lexusBlack};

  transition: height 0.3s ease-out;

  margin-top: 7.1825rem;
  ${breakpointDown('phone')} {
    width: 100%;
    flex-direction: column;
    background-color: ${colors.white};
  }
  ${breakpointUp('phone')} {
    box-shadow: 0 -0.5rem 5.625rem rgba(0, 0, 0, 0.08);
  }
`

const FooterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${breakpointDown('phone')} {
    flex-flow: column;
  }
`

const FooterItemContainer = styled.div`
  background-color: ${colors.lexusBlack};

  display: flex;
  align-items: center;
  flex-direction: column;
  ${breakpointUp('tablet')} {
    max-width: 17.75rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
  }
  ${breakpointDown('phone')} {
    margin: 1.5rem 1rem;
    background-color: ${colors.white};
    color: ${colors.charcoalBlack};
    justify-content: center;
  }
  ${breakpointUp('phone')} {
    padding: 1.5rem 2rem;
  }
`

const FooterItemLabel = styled.p`
  color: ${colors.white};
  ${propBetween('font-size', 2.1875, 1)}
  ${propBetween('line-height', 2.8125, 1.3)}
  margin: 0;
  font-weight: 500;
  ${breakpointDown('phone')} {
    color: ${colors.charcoalBlack};
    font-weight: bold;
  }
`
