import React, { useEffect } from 'react'

import Content from '../components/Content'
import { Title } from '../components/Title'
import { useTitle } from '../hooks/useTitle'
import { openingAgainTime } from './SelfService/Closed'

interface Props {
  title: string
}
const AdminClosedComponent = ({ title }: Props) => {
  const { setTitle } = useTitle()
  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])
  return (
    <Content>
      <Title>
        {`Vegna uppfærslu hjá samstarfsaðila er tímabundið ekki hægt að selja tryggingar. Opnað verður aftur eftir ${openingAgainTime}.`}
      </Title>
    </Content>
  )
}

const AdminClosed = ({ title }: { title: string }) => {
  const props: Props = {
    title,
  }

  return <AdminClosedComponent {...props} />
}
const AdminClosedUserPass = () => <AdminClosed title="Auðkenning" />
export { AdminClosedUserPass }
