import { Quote, Person } from '../../api/types'
import { Loading } from '../../components/Loading'
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { CheckoutTable } from './CheckoutTable'
import { breakpointUp, breakpointDown, breakpoints } from '../../helpers/breakpoints'
import { colors } from '../../constants'
import { Section, SectionDivider, Heading } from './components'
import { ContactInfo, useContactInfoProps, isEmail } from './ContactInfo'
import { PaymentSchedule, usePaymentScheduleProps } from './PaymentSchedule'
import { PaymentMethod, usePaymentMethodProps } from './PaymentType'
import { CreditCard, useCreditCardProps } from './CreditCard'
import { api } from '../../api/api.tm'
import Button from '../../components/Button'
import { propBetween } from '../../helpers/between'
import { UseExistingPaymentMethod } from './UseExistingPaymentMethod'
import { useRouteTransition } from '../../hooks/useRouteTransition'
import { setCancellations, useAppState } from '../../Store'
import { useExceptionHandler } from '../../hooks/useExceptionHandler'
import { useTitle } from '../../hooks/useTitle'
import { useDispatchErrorNotification } from '../../hooks/useDispatchErrorNotification'
import { LogOutButton } from '../../components/LogOutButton'
interface Props {
  quote: Quote
  person: Person
}

const CheckoutComponent = ({ quote, person }: Props) => {
  const { dispatch } = useAppState()
  const contactInfoProps = useContactInfoProps()
  const { routeTo } = useRouteTransition()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const { handleException } = useExceptionHandler()
  const { setTitle } = useTitle()
  const dispatchErrorNotification = useDispatchErrorNotification()
  const paymentMethodProps = usePaymentMethodProps(() => {
    setErrorText(undefined)
  })
  const paymentScheduleProps = usePaymentScheduleProps()
  const creditCardProps = useCreditCardProps(() => {
    setErrorText(undefined)
  })

  useEffect(() => {
    setTitle('Kaupa')
  })

  const completePurchase = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await api.checkout({
        quoteId: quote.number,
        email: contactInfoProps.email,
        paperLess: contactInfoProps.paperLess,
        wantsMarketing: contactInfoProps.wantsMarketing,
        method: person.isCustomer
          ? { type: 'useExisting' }
          : paymentMethodProps.method === 'card'
          ? {
              type: 'creditCard' as const,
              schedule: paymentScheduleProps.schedule,
              cardNumber: creditCardProps.cardInfo.cardNumber.replace(/\s/g, ''),
              cvc: creditCardProps.cardInfo.cvc,
              validTo: getValidTo(creditCardProps.cardInfo.expiryDate),
            }
          : {
              type: 'invoice' as const,
              schedule: paymentScheduleProps.schedule,
            },
      })

      if (!result.success) {
        if (result.messages[0]) {
          if (result.messages[0].code === 'cardNotAuthorized') {
            setErrorText('Því miður virðist ekki vera næg innistæða á kortinu.')
          } else if (result.messages[0].code === 'cardServiceConnectionFailure') {
            dispatchErrorNotification('Því miður næst ekki samband við kortaþjónustu.')
          } else if (result.messages[0].code === 'invalidCreditCardNumber') {
            setErrorText('Kortanúmer er ekki gilt.')
          } else {
            dispatchErrorNotification('Það lítur út fyrir að það séu einhver tæknileg vandræði, prófaðu aftur seinna.')
          }
          setIsLoading(false)
        } else {
          setIsLoading(false)
          throw new Error(result.messages[0])
        }
      } else {
        setIsLoading(false)

        if (result.cancellations.length > 0) {
          dispatch(setCancellations(result.cancellations))
          routeTo(`/${quote.number}/uppsogn`)
        } else {
          routeTo(`/${quote.number}/takk-fyrir`)
        }
      }
    } catch (error: any) {
      handleException({
        error,
        dispatchErrorNotification: false,
      })
      setIsLoading(false)
    }
  }, [
    person.isCustomer,
    quote.number,
    contactInfoProps.email,
    contactInfoProps.paperLess,
    contactInfoProps.wantsMarketing,
    paymentMethodProps.method,
    paymentScheduleProps.schedule,
    creditCardProps.cardInfo.cardNumber,
    creditCardProps.cardInfo.cvc,
    creditCardProps.cardInfo.expiryDate,
    routeTo,
    dispatch,
    handleException,
    dispatchErrorNotification,
  ])
  return (
    <Root>
      <PageHeading>Greiðslusíða</PageHeading>

      <CheckoutGrid>
        <CheckOutInfo>
          <Section label="Netfang">
            <ContactInfo {...contactInfoProps} />
          </Section>
          <SectionDivider />
          {person.isCustomer ? (
            <>
              <Section label="">
                <UseExistingPaymentMethod multiplePolicies={quote.policies.length > 1} />
              </Section>
            </>
          ) : (
            <>
              <Section label="Greiðslutíðni">
                <PaymentSchedule {...paymentScheduleProps} />
              </Section>
              <SectionDivider />

              <Section label="Greiðslumáti">
                <PaymentMethod {...paymentMethodProps} />
              </Section>
              <SectionDivider />
            </>
          )}
          <Section label="">
            <PaymentMethodInfo>
              {!person.isCustomer &&
                paymentMethodProps.method === 'invoice' &&
                `Greiðsluseðill mun birtast í netbankanum þínum við útgáfu trygginganna`}
            </PaymentMethodInfo>
            {!person.isCustomer && paymentMethodProps.method === 'card' && (
              <>
                <Heading>Kortaupplýsingar</Heading>
                <CreditCard {...creditCardProps} />
              </>
            )}
            <MobileButtonContainer>
              <Button onClick={completePurchase} loading={isLoading} disabled={isLoading}>
                GREIÐA
              </Button>
              {errorText && <ErrorText>{errorText}</ErrorText>}
            </MobileButtonContainer>
          </Section>
          <SectionDivider />
        </CheckOutInfo>
        <div>
          <CheckoutTable
            policies={quote.policies}
            canBuy={
              isEmail(contactInfoProps.email) && paymentMethodProps.method === 'card'
                ? !creditCardProps.paymentInputProps.meta.error
                : true
            }
            loading={isLoading}
            errorText={errorText}
            onBuy={completePurchase}
          />
          <LogOutButtonWrapper>
            <LogOutButton invisible />
          </LogOutButtonWrapper>
        </div>
      </CheckoutGrid>
    </Root>
  )
}

const ErrorText = styled.div`
  color: ${colors.errorRed};
  margin-top: 1rem;
  text-align: center;
`
export const Checkout = () => {
  const { state } = useAppState()

  if (!state.quote || !state.person) {
    return <Loading />
  }
  return <CheckoutComponent quote={state.quote} person={state.person} />
}

const MobileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 3.5rem;

  ${breakpointUp('tablet')} {
    display: none;
  }
`

const PaymentMethodInfo = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
`

const PageHeading = styled.h1`
  text-transform: uppercase;
  ${propBetween('font-size', 2.5, 3.75)}
  line-height: 120%;

  margin: 0;
  ${propBetween('margin-top', 2, 7.5)};
  ${propBetween('margin-bottom', 2, 7.5)};

  color: ${colors.black};

  ${breakpointDown('phone')} {
    margin: 2rem 0;
  }

  ${breakpointUp('desktopL')} {
    margin: 7.5rem 0;
  }
`

const Root = styled.div`
  padding: 0;

  ${propBetween('padding-left', 1.5, 6.5)};
  ${propBetween('padding-right', 1.5, 6.5)};

  margin: 2rem auto 0;

  max-width: 90rem;

  ${breakpointUp('tablet')} {
    margin-bottom: 8.5rem;
  }
`

const CheckoutGrid = styled.div`
  display: grid;
  ${propBetween('grid-column-gap', 2.5, 6.5, breakpoints.tablet / 16)}

  ${breakpointUp('tablet')} {
    grid-template-columns: 1fr 25rem;
    grid-template-areas: 'right left';
    & > div:last-child {
      grid-area: left;
      position: sticky;
      top: 1rem;
    }
  }

  ${breakpointUp('desktop')} {
    grid-template-columns: 1fr 30.625rem;
  }

  ${breakpointDown('tablet')} {
    grid-template-columns: 1fr;
  }
`

const CheckOutInfo = styled.div`
  ${breakpointDown('phone')} {
    order: 2;
  }
`

const getValidTo = (expiryDate: string) => {
  const [month, year] = expiryDate.split('/').map(Number)
  return { month, year }
}

const LogOutButtonWrapper = styled.div`
  position: relative;
  margin-top: 15rem;
  margin-left: 3rem;
`
