import { useEffect } from 'react'
import { Brand } from '../api/types'
import { useAppState } from '../Store'
export const DynamicFavicon = () => {
  const { state } = useAppState()
  useEffect(() => {
    if (state.brandType !== Brand.Unknown) {
      const favicon = document.getElementById('favicon') as any
      if (favicon) {
        favicon.href = `/${state.brandType.toLocaleLowerCase()}-favicon.png`
      }
    }
  }, [state.brandType])
  return null
}
