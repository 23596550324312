import React from 'react'
import styled from 'styled-components'
import { breakpointDown } from '../helpers/breakpoints'

interface Props {
  children: any
  marginTop?: number
}
const Content = ({ children, marginTop = 10 }: Props) => {
  return (
    <Root marginTop={marginTop}>
      <MaxWidth>{children}</MaxWidth>
    </Root>
  )
}

export default Content

const Root = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${(props) => props.marginTop}rem;

  ${breakpointDown('phone')} {
    margin-top: ${(props) => (props.marginTop >= 1 ? (props.marginTop / 2).toString() : '1')}rem;
  }
`

const MaxWidth = styled.div`
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;

  ${breakpointDown('phone')} {
    padding: 0 1.5rem;
  }
`
