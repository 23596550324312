import React from 'react'
import styled from 'styled-components'
import { breakpointUp, breakpointDown } from '../helpers/breakpoints'
import { useAppState } from '../Store'
import { Brand } from '../api/types'
import { ToyotaLogo } from '../icons/ToyotaLogo'
import { LexusLogo } from '../icons/LexusLogo'

export const HeaderLogo = () => {
  const { state } = useAppState()
  return (
    <Logo>
      {state.brandType === Brand.Lexus && <LexusLogo />}
      {state.brandType === Brand.Toyota && <ToyotaLogo />}
    </Logo>
  )
}

const Logo = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  cursor: pointer;
  ${breakpointUp('phone')} {
    position: absolute;
    top: 0;
    left: 0;
    > svg {
      height: 2.8rem;
      width: auto;
    }
    padding: 1.1rem 1.5rem;
  }

  ${breakpointDown('phone')} {
    padding: 1.1rem 0;
    > svg {
      width: 100%;
    }
    ${breakpointDown('phone')} {
      > svg {
        width: 80%;
      }
    }
  }
`
