import React from 'react'

import { AppleLogo } from '../assets/icons/AppleLogo'
import { GoogleLogo } from '../assets/icons/GoogleLogo'

import styled from 'styled-components'
import { colors } from '../constants'
import { breakpointDown } from '../helpers/breakpoints'

interface IProps {
  appStore: 'apple' | 'google'
}

export const AppStoreButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { appStore } = props

  return appStore === 'apple' ? (
    <Button href="https://itunes.apple.com/is/app/tm-hugsum-%C3%AD-framt%C3%AD%C3%B0/id1333246430" role="button">
      <AppleLogo />
      <OuterButtonWrapper>
        <span>
          <Text>Sækja frá</Text>
          <PlayStore>App Store</PlayStore>
        </span>
      </OuterButtonWrapper>
    </Button>
  ) : (
    <Button href="https://play.google.com/store/apps/details?id=is.tm.application">
      <GoogleLogo />
      <OuterButtonWrapper>
        <span>
          <Text>Sækja frá</Text>
          <PlayStore>Play Store</PlayStore>
        </span>
      </OuterButtonWrapper>
    </Button>
  )
}

const Button = styled.a`
  display: grid;
  grid-template-columns: 1.1875rem calc(100% - 2.375rem);
  align-items: center;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  min-width: 14.375rem;
  border: 0.25rem solid;
  background-color: ${colors.black};
  border-color: transparent;

  ${breakpointDown('phone')} {
    margin: 1rem 1.5rem;
  }
`

const OuterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
`

const Text = styled.div`
  color: white;
  font-size: 0.625rem;
  text-transform: uppercase;
`

const PlayStore = styled.div`
  margin: 0;
  color: white;
  font-size: 1.125rem;
  font-weight: 500;
`
