import React from 'react'
import { api } from '../api'
import Button from '../components/Button'
import { useRouteTransition } from '../hooks/useRouteTransition'
import { ChatSection } from '../components/ChatSection'
import { ChatSectionTitle } from '../components/ChatSectionTitle'
import { useAppState, setQuote } from '../Store'

export const OfferExpired = () => {
  const { routeTo } = useRouteTransition()
  const { state, dispatch } = useAppState()
  return (
    <ChatSection>
      <ChatSectionTitle text={'Það lítur út fyrir að tilboðið þitt sé útrunnið. Vinsamlegast byrjið aftur.'} />
      <Button
        onClick={async () => {
          if (state.person) {
            const quote = await api.createQuote(state.person.kennitala)
            dispatch(setQuote(quote))
            routeTo(`/${quote.number}/sla-inn-bilnumer`)
          } else {
            routeTo('/audkenna')
          }
        }}
      >
        Byrja aftur
      </Button>
    </ChatSection>
  )
}
