import React, { useState } from 'react'
import { ToggleCoverageButton } from './Details/components/ToggleCoverageButton'
import { Coverage } from './Details/components/Coverage'
import { Select } from '../components/Select'
import { formatKronur } from '../helpers/formatKronur'
import styled from 'styled-components'
export const AllComponents = () => {
  const [selectValue, setSelectValue] = useState('1000000')
  const optionToSelect = (value: number) => ({
    value,
    title: `${formatKronur(value)} kr.`,
  })
  async function sleep(stallTime = 300000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime))
  }
  return (
    <div style={{ backgroundColor: 'red' }}>
      <ToggleCoverageButton
        onToggle={async () => {
          await sleep()
        }}
        selected={true}
      ></ToggleCoverageButton>
      <Coverage
        title="Kaskó&shy;trygging"
        description="Kaskótrygging bætir tjón á bílnum þínum ef þú lendir í árekstri og getur því sparað þér heilmikinn kostnað. Kaskó bætir einnig skemmdir sem verða á bílnum við innbrot, útafakstur, bruna og fleira. Að auki færðu bílaleigubíl ef bíllinn er ekki ökufær vegna tjóns."
        price={1000}
        borderBottom
        selected={true}
        onToggleSelected={async () => {
          await sleep()
        }}
      ></Coverage>
      <SelectBox>
        <Select
          label="Upphæð bóta"
          disabled={false}
          options={[1000000, 2000000, 3000000, 4000000].map((i) => {
            return optionToSelect(i)
          })}
          onChange={async (value) => {
            await sleep()
            setSelectValue(value)
          }}
          selected={optionToSelect(Number(selectValue))}
        />
      </SelectBox>
    </div>
  )
}

const SelectBox = styled.div`
  margin: 0 2rem 4rem;
  max-width: 15.625rem;
`
